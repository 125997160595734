import React, { useState, useEffect } from 'react';
import Tracking from '../../components/tracking';
import Modal from '../../components/Modal';
import ApiService from '../../components/ApiService';
import View from '../../views/view';
import CustomToast from '../../components/CustomToast';
import Chat from '../../components/chat';

const TrackingPage = () => {
    const work = '/progressTracking/project/work'
    const newTask = '/progressTracking/project/new'

    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [openView, setOpenView] = useState(false); // Массив для хранения изображений
    const [user, setUser] = useState(null);
    const [projectIdInput, setProjectIdInput] = useState(null);
    useEffect(() => {
        // Функция, выполняемая при появлении кнопки
        const fetchData = async () => {
            if (selectedRow) {
                const response = await ApiService.fetchDataWithToken('/application', { taskId: selectedRow });
                setUser(response.createByUserId)
                setProjectIdInput(response?.projectId || null);
                setOpenView(true)
            }
        };
        fetchData();
    }, [selectedRow]);

    const detailsConfig = [
        { key: 'id', label: "ИД" },
        { key: 'name', label: "Имя" },
        { key: 'description', label: "Описание" },
        { key: 'status', label: "Статус" },
        { key: 'company', label: 'Компания' },
        { key: 'createbyuser', label: "Ответственный" },
        { key: 'category', label: "Тип" },
        { key: 'priority', label: "Приоритет" },
        { key: 'adres', label: "Адрес филиала" },
        { key: 'number', label: "Номер ПК" },
        { key: 'anyDesk', label: "Деск" },
        { key: 'deadline', label: "Крайний срок", date: true },
    ];
    const acceptHandler = async () => {
        try {
            const response = await ApiService.fetchDataWithToken('/user/byToken')
            const body = {
                id: selectedRow,
                userId: response.id
            }
            await ApiService.postDataWithToken('/application/accept', body);
            setUser(response.id)
            refreshTaskTable()
        } catch (error) {
        }
    };
    // Функция, вызывающая перерисовку компонента
    const refreshTaskTable = () => {
        setListener(prevKey => prevKey + 1);
    };

    useEffect(() => {
        document.title = 'Трекинг';
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);

    return (
        <div className='container'>
            <div className='tracking-container'>
                <Tracking apiUrl={newTask} header={'Не принятые'} selectedRow={selectedRow} setSelectedRow={setSelectedRow} setModalOpen={setOpenView} listner={listener} />
            </div>
            <div className='tracking-container'>
                <Tracking apiUrl={work} header={'В работе'} selectedRow={selectedRow} setSelectedRow={setSelectedRow} setModalOpen={setOpenView} listner={listener} />
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={openView} setActive={setOpenView}>
                <View
                    listener={listener}
                    apiUrl={"/application/table"}
                    selectedId={selectedRow}
                    detailsConfig={detailsConfig}
                    refreshTaskTable={refreshTaskTable}
                >
                    {!user && <div style={{ margin: '5px' }}><button onClick={() => { acceptHandler() }}>Принять</button></div>}
                    {selectedRow && projectIdInput && <Chat taskId={selectedRow} listener={listener} visibleInput={false} params={{ projectId: projectIdInput }}></Chat>}
                </View>
            </Modal>
        </div>
    );
};

export default TrackingPage;
