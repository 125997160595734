import React, { useState, useEffect, useMemo } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import Grid from '../../components/tables/Grid';
import Modal from '../../components/Modal'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import YearMonthSelector from '../../components/YearMonthSelector';

const Check = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { id } = useParams();



    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);

    const columns = [
        { key: 'name', title: 'Имя' },
        { key: 'timeInTariff', title: 'Время в тарифе   ' },
        { key: 'timeOutOfTariff', title: 'Время вне тарифа' },
        { key: 'totalCost', title: 'Итоговая стоимость' },
    ];
    const detailsConfig = [
        { key: 'number', label: 'Номер' },
        { key: 'model', label: 'Модель' },
    ];

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // Месяц начинается с 0

    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);

    const MounthStart = useMemo(() => {
        const MounthStart = new Date(selectedYear, selectedMonth, 1);
        MounthStart.setHours(0, 0, 0, 0);
        return MounthStart;
    }, [selectedYear, selectedMonth]);

    const MounthEnd = useMemo(() => {
        const MounthEnd = new Date(selectedYear, selectedMonth + 1, 0);
        MounthEnd.setHours(23, 59, 59, 999);
        return MounthEnd;
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        document.title = 'Оргтехника';
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);

    useEffect(() => {
        if (id) setSelectedRow(id);
    }, [id]);

    useEffect(() => {
        setApiUrl(`/firm/check?startDate=${MounthStart}&endDate=${MounthEnd}`)
    }, [MounthStart, MounthEnd]);
    const [apiUrl, setApiUrl] = useState(`/firm/check`);
    const setSelectRow = (id) => {
        setSelectedRow(id);
        navigate(`/bookkeeping/${id}`);
    };

    return (
        <div>
            <div style={{ margin: '10px' }}>
                <YearMonthSelector
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    onYearChange={setSelectedYear}
                    onMonthChange={setSelectedMonth}
                />
            </div>
            <div className="grid-view-container">
                <Grid
                    listener={listener}
                    apiUrl={apiUrl}
                    columns={columns}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectRow}
                />
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
        </div>
    );
};

export default Check