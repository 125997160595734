import React, { useState, useEffect } from 'react';
import CustomToast from '../../components/CustomToast';
import ApiService from '../../components/ApiService';
import AutocompleteInput from '../../components/autoCompliteInputs/AndCreate';
import AutocompleteInputFromObject from '../../components/autoCompliteInputs/fromObject';
import Input from '../../components/input';
import FileList from '../file/fileList';
import CheckboxGroup from '../CheckboxGroup';
const OrgAudit = ({ setRefresh, config }) => {
    const division = config.division;
    const company = config.company;


    const [serverNumber, setServerNumber] = useState('');
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [modal, setModal] = useState('');
    const [serverData, setServerData] = useState({
        id: null,
        value: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await ApiService.setToastHandlers(setToastMessage, setToastType);
                const response = await ApiService.fetchDataWithToken(`/firm/${company.id}`)
                resetAllStates();
                setDisabled(false);
            } catch (error) {
            }
        };
        fetchData();
    }, []);


    const checkAtLeastOneSelected = (object, groupName) => {
        const noneChecked = Object.values(object).every(item => !item.checked);
        if (noneChecked) {
            setToastType('error');
            setToastMessage(`Выберите хотя бы 1 пункт в ${groupName}`);
            setDisabled(false);
            return false;
        }
        return true;
    };

    const resetAllStates = () => {
        setServerData({
            id: null,
            value: "",
        });
        setModal('')
        setServerNumber('');
    };


    const handleButtonClick = async () => {
        setDisabled(true);

        const formData = new FormData();
        formData.append('number', serverNumber);
        formData.append('id', serverData.id);
        formData.append('model', modal);
        formData.append('companyId', company.id);
        formData.append('divisionId', division.id);

        try {
            await ApiService.formDataWithToken(`/org/update`, formData);
            setToastType('success');
            setToastMessage('Успешно');
            resetAllStates();
            setServerNumber(null)
            setDisabled(false);
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
            setDisabled(false);
        }
    };


    return (
        <div>
            <div className="App">
                <header className="App-header">
                </header>
                <div class="header">
                    <p>Клиент {company.value}, филиал {division.value}</p>
                </div>
                <div className="headerTitle">Сервисный опрос клиента</div>
                <div className='form-container'>
                    <div className="headerContanier">Первичная информация</div>
                    <p style={{ fontWeight: 'bold' }}>Номер ОРГ</p>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInput apiUrl={`/firm/${company.id}/division/${division.id}/org`} name="number" description={'Если поле горит красным орг создастья'} placeholder="Выбрать/Цифры" setData={setServerData} data={serverData} setName={setServerNumber} validation={{ required: true }} />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Input data={modal} setData={setModal} placeholder={'Модель'} />
                    </div>
                </div>



                <div className='form-container'>
                    <button
                        className="button"
                        disabled={disabled}
                        isLoading={false}
                        onClick={() => {
                            setRefresh(null)
                        }}
                    >
                        Перейти к общим сведеньям
                    </button>
                    <button
                        style={{ marginLeft: '10px' }}
                        className="button"
                        disabled={disabled}
                        isLoading={false}
                        onClick={() => {
                            handleButtonClick()
                        }}
                    >
                        Сохранить
                    </button>
                </div>
                <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            </div>
        </div>
    );
};
export default OrgAudit;