import React, { useEffect, useState } from 'react';
import ApiService from '../ApiService';

const Control = () => {
    const [data, setData] = useState(null); // Измените начальное значение на null

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.fetchDataWithToken('/kpi/controlToWeb');
                setData(response); // Предполагая, что данные приходят в этом формате
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="average-time-task">
            <h2>Задачи на контроль</h2>
            <div className="table-wrapper">
                {data ? (
                    <table className="table" style={{ boxShadow: '0 0px 8px rgba(0, 0, 0, 0.3)', borderRadius: '8px' }}>
                        <thead>
                            <tr style={{ borderRadius: '8px' }}>
                                <th style={{ width: '25%' }}>Название проекта</th>
                                <th>Задачи на контроль</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.projects.map(project => (
                                <tr key={project.projectId}>
                                    <td>{project.name}</td>
                                    <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                        {Array.isArray(project.controlTasks) ? (
                                            project.controlTasks.map((task, index) => (
                                                <span key={task.id}>
                                                    <a href={task.link} target="_blank" rel="noopener noreferrer">
                                                        {task.id}
                                                    </a>
                                                    {index < project.controlTasks.length - 1 && ', '}
                                                </span>
                                            ))
                                        ) : (
                                            project.controlTasks
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Нет данных для отображения.</p>
                )}
            </div>
        </div>
    );
};

export default Control;
