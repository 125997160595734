import React, { useState, useEffect, useRef } from 'react';
import ApiServise from './ApiService';
import pdf from '../assets/images/icons/PDF.png';
import dock from '../assets/images/icons/DOC.png';
import xls from '../assets/images/icons/XLS.png';
import pptx from '../assets/images/icons/PPTX.png';
import def from '../assets/images/icons/__SATORI.png';
import CustomToast from './CustomToast';
import defaultAvatar from '../assets/images/avatar.png';
import axios from 'axios';
import InputMention from './inputMention';

const apiKey = process.env.REACT_APP_API_KEY;

const Chat = ({ taskId, listener, params, visibleInput = true }) => {
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [messages, setMessages] = useState([]);
  const [firstFiles, setFirstFiles] = useState([]);
  const [recipient, setRecipient] = useState([]);
  const [userItems, setUserItems] = useState([]);
  const [commentInput, setCommentInput] = useState('');
  const [listeners, setListeners] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [avatar, setAvatar] = useState({});
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentInput, setEditCommentInput] = useState('');
  const [loading, setLoading] = useState(false);
  const editRef = useRef();
  const chatContainerRef = useRef(null);

  const refreshTaskTable = () => {
    setListeners((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true); // Устанавливаем загрузку
      try {
        const response = await ApiServise.postDataWithToken('/application/getComments', { id: taskId });
        setMessages(response.comments);

        // Fetch avatars
        const uniqueUserIds = new Set(response.comments.map(comment => comment.userId));
        for (let userId of uniqueUserIds) {
          const body = {
            type: 'logo',
            userId: userId,
          };
          const token = localStorage.getItem('token');
          const avatarResponse = await axios.post(apiKey + '/user/download', body, {
            responseType: 'blob',
            headers: { Authorization: `Bearer ${token}` },
          });

          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result;
            setAvatar((prevAvatars) => ({
              ...prevAvatars,
              [userId]: base64data,
            }));
          };
          reader.readAsDataURL(avatarResponse.data);
        }

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Сбрасываем загрузку
      }
    };

    fetchMessages();
  }, [taskId, listener, listeners]);


  useEffect(() => {
    if (chatContainerRef.current) {
      setTimeout(() => {
        if (chatContainerRef.current) { // Проверяем еще раз на наличие элемента
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      }, 100); // Небольшая задержка (100мс), чтобы убедиться, что DOM полностью отрисован
    }
  }, [messages, loading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await ApiServise.fetchDataWithToken('/user/getList');
        setUserItems(user);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    ApiServise.setToastHandlers(setToastMessage, setToastType);
  }, []);

  const handleCommentSubmit = async () => {
    try {
      setCommentInput('');
      setRecipient([]);
      setFirstFiles([]);
      const user = await ApiServise.fetchDataWithToken('/user/byToken', {});

      const newComment = {
        id: 'temp-id', // Временный ID
        userId: user.id,
        fio: `${user.firstName} ${user.lastName}`,
        commenttext: commentInput,
        color: false,
        createdAt: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, newComment]);
      const formData = new FormData();
      formData.append('id', taskId);
      formData.append('userId', user.id);
      firstFiles.forEach((file) => {
        formData.append('files', file);
      });

      let response;
      if (recipient.length > 0) {
        const notificationPromises = recipient.map(async (recipient) => {
          const recipientUser = await ApiServise.fetchDataWithToken('/user/byId', { userId: recipient.id });
          const body = {
            message: commentInput,
            authotId: user.id,
            recipientId: recipientUser.id,
            taskId: taskId,
          };
          response = await ApiServise.postDataWithToken('/notification/add', body);
        });
        // Ждем выполнения всех запросов
        await Promise.all(notificationPromises);
        formData.append('comment', commentInput);
        response = await ApiServise.postDataWithToken('/application/commentTask', formData);
      } else {
        formData.append('comment', commentInput);
        response = await ApiServise.postDataWithToken('/application/commentTask', formData);
      }

      setToastType('success');
      refreshTaskTable();
      setDisabled(false);
      setToastMessage(response.message);
    } catch (e) {
      console.log(e)
      refreshTaskTable();
      setDisabled(false);
    }
  };

  const handleEditComment = (commentId, text) => {
    setEditingCommentId(commentId);
    setEditCommentInput(text);
  };

  const handleSaveEdit = async (commentId) => {
    try {
      const body = {
        commentId: commentId,
        newCommentText: editCommentInput,
      };
      await ApiServise.postDataWithToken('/application/updateComment', body);
      setEditingCommentId(null);
      setEditCommentInput('');
      refreshTaskTable();
      setToastType('success');
      setToastMessage('Комментарий обновлен');
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickOutside = (event) => {
    if (editRef.current && !editRef.current.contains(event.target)) {
      setEditingCommentId(null);
      setEditCommentInput('');
    }
  };

  useEffect(() => {
    if (editingCommentId !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingCommentId]);

  const linkifyText = (text) => {
    if (typeof text !== 'string') {
      return text; // If not a string, just return the original value
    }

    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    // First, replace URLs with <a> tags
    let modifiedText = text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);

    // Then, replace newline characters with <br/> tags
    modifiedText = modifiedText.replace(/\n/g, '<br/>');

    return modifiedText;
  };



  const getFileIcon = (filename) => {
    if (!filename) {
      return def;
    }

    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return pdf;
      case 'doc':
      case 'docx':
        return dock;
      case 'xls':
      case 'xlsx':
        return xls;
      case 'pptx':
        return pptx;
      default:
        return def;
    }
  };

  const handleFileClick = async (fileId) => {
    await downloadFile(fileId);
  };

  const downloadFile = async (fileId) => {
    try {
      const response = await ApiServise.downloadFile(`/application/download/${fileId}`);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `file_${fileId}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Ошибка скачивания файла:', error);
    }
  };

  const formatDateTime = (value) => {
    const date = new Date(value);
    if (date.getTime() === 0) {
      return 'не указано';
    } else {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    }
  };

  return (
    <div className="chat-container">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }} className="loading-spinner">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="chat" ref={chatContainerRef}>
          <h4>Комментарии</h4>
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.color ? 'highlighted' : ''}`}>
              <img src={avatar[message.userId] || defaultAvatar} alt="User Avatar" className="chat-avatar" />
              <div className={`chat-content ${editingCommentId === message.id ? 'editing' : ''}`} ref={editingCommentId === message.id ? editRef : null}>
                {editingCommentId === message.id ? (
                  <textarea
                    className="textarea"
                    value={editCommentInput}
                    onChange={(e) => setEditCommentInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSaveEdit(message.id);
                      }
                    }}
                    autoFocus
                  />
                ) : (
                  <>
                    <p>
                      <strong>{message.fio}:</strong> <span dangerouslySetInnerHTML={{ __html: linkifyText(message.commenttext) }}></span>
                    </p>
                    <svg className="edit-icon" viewBox="0 0 24 24" width="24" height="24" onClick={() => handleEditComment(message.id, message.commenttext)}>
                      <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11 20H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                  </>
                )}
                {message.originalnames && message.originalnames.length > 0 && message.originalnames[0] != null && (
                  <div>
                    {message.originalnames.map((filename, fileIndex) => {
                      const isImage = ['jpg', 'jpeg', 'png'].includes(filename.split('.').pop().toLowerCase());
                      return isImage ? (
                        <div className="file-images">
                          <div key={fileIndex} className="image-container">
                            <img
                              src={message.fileData[fileIndex]} // Это base64 изображение
                              alt="file-preview"
                              className="file-image"
                              onClick={() => handleFileClick(message.filesId[fileIndex])} // Скачивание при клике
                            />
                          </div>
                        </div>
                      ) : (
                        <ul className="file-icons">
                          <li key={fileIndex} className="file-item">
                            <img
                              src={getFileIcon(filename)} // Отображаем иконку
                              alt="file-icon"
                              className="file-icon"
                              onClick={() => handleFileClick(message.filesId[fileIndex])} // Скачивание при клике
                            />
                            <p className="file-name" onClick={() => handleFileClick(message.filesId[fileIndex])}>{filename}</p>
                          </li>
                        </ul>);
                    })}
                  </div>
                )}
                <p className="message-date">{formatDateTime(message.createdAt)}</p>
                <p className="message-category">{message.categoryName}</p>
              </div>
            </div>
          ))}
        </div>
      )}
      {visibleInput && (
        <div className="comment-input">
          <InputMention setFile={setFirstFiles} handleSubmit={handleCommentSubmit} items={userItems} onSelectItem={setRecipient} placeHolder={'Введите ваш комментарий...'} setInput={setCommentInput} listener={taskId} listeners={listeners} />
        </div>
      )}
      <CustomToast type={toastType} message={toastMessage} setMessage={setToastMessage} setType={setToastType} />
    </div>
  );

};

export default Chat;
