import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const CustomToast = ({ message, type, setMessage, setType }) => {
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (message && type) {
      setNotification({ message, type });

      // Удаление уведомления через 2 секунды
      const timer = setTimeout(() => {
        closeNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [message, type]);

  const closeNotification = () => {
    setNotification(null);
    setMessage(null); // Сброс сообщения в родительском компоненте
    setType(null); // Сброс типа в родительском компоненте
  };

  if (!notification) return null;

  return ReactDOM.createPortal(
    <div className={`notification ${notification.type} animate-in-out`}>
      <div className="notification-content">
        {notification.message}
      </div>
    </div>,
    document.getElementById('portal-root') // Рендерим в портал
  );
};

export default CustomToast;
