import React, { useState, useEffect } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import { useNavigate } from 'react-router-dom';
import YearSelector from '../../components/YearSelector';

const VacationSchedule = () => {
    const [employees, setEmployees] = useState([]);
    const [vacations, setVacations] = useState({});
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'График отпусков';
        const fetchEmployees = async () => {
            try {
                const response = await ApiService.fetchDataWithToken('/user/getList');
                setEmployees(response);
            } catch (error) {
                console.error("Error fetching employees:", error);
            }
        };
        fetchEmployees();
    }, []);

    const fetchVacations = async () => {
        try {
            const response = await ApiService.fetchDataWithToken('/sheet/holiday', {
                startDate: `${selectedYear}-01-01`,
                endDate: `${selectedYear}-12-31`
            });

            const fetchedVacations = {};

            response.holiday.forEach(record => {
                const { userId, date } = record;
                const vacationDate = new Date(date);

                if (!fetchedVacations[userId]) {
                    fetchedVacations[userId] = [];
                }

                // Get the last vacation entry for the user
                const lastVacation = fetchedVacations[userId].slice(-1)[0];

                if (lastVacation) {
                    const endDate = new Date(lastVacation.endDate);
                    const nextDate = new Date(endDate);
                    nextDate.setDate(nextDate.getDate() + 1);

                    // Compare dates ignoring time
                    if (
                        vacationDate.getFullYear() === nextDate.getFullYear() &&
                        vacationDate.getMonth() === nextDate.getMonth() &&
                        vacationDate.getDate() === nextDate.getDate()
                    ) {
                        // Extend the last vacation entry
                        lastVacation.endDate = date;
                        lastVacation.days++;
                    } else {
                        // Start a new vacation entry
                        fetchedVacations[userId].push({ startDate: date, endDate: date, days: 1 });
                    }
                } else {
                    // First vacation entry for the user
                    fetchedVacations[userId].push({ startDate: date, endDate: date, days: 1 });
                }
            });

            setVacations(fetchedVacations);
        } catch (error) {
            console.error("Error fetching vacations:", error);
        }
    };

    useEffect(() => {

        fetchVacations();
    }, [selectedYear]);



    const handleAddVacation = (employeeId) => {
        setVacations({
            ...vacations,
            [employeeId]: [
                ...(vacations[employeeId] || []),
                { startDate: '', endDate: '', days: 0 }
            ]
        });
    };

    const handleDeleteVacation = async (employeeId, index) => {
        try {
            const updatedVacations = { ...vacations };
            const employeeVacations = updatedVacations[employeeId];

            // Get the vacation entry to delete
            const vacationToDelete = employeeVacations[index];

            // Prepare data to send to server for all days in the vacation period
            const vacationData = [];
            const startDate = new Date(vacationToDelete.startDate);
            const endDate = new Date(vacationToDelete.endDate);
            const currentDate = new Date(startDate);

            while (currentDate <= endDate) {
                vacationData.push({
                    userId: employeeId,
                    date: currentDate.toISOString().split('T')[0],
                    status: '' // Assuming you clear the status for all days in the vacation period
                });
                currentDate.setDate(currentDate.getDate() + 1);
            }

            // Send data to server to clear statuses for all days in the vacation period
            await ApiService.postDataWithToken('/sheet/attendance', { attendanceData: vacationData });
            fetchVacations()
            setToastMessage('Отпуск удален успешно');
            setToastType('success');
        } catch (error) {
            console.error('Ошибка при удалении отпуска:', error);
            setToastMessage('Не удалось удалить отпуск');
            setToastType('error');
        }
    };





    const handleVacationChange = (employeeId, index, field, value) => {
        const updatedEmployeeVacations = (vacations[employeeId] || []).map((vacation, i) => {
            if (i === index) {
                const updatedVacation = { ...vacation, [field]: value };

                // Check if the chosen start date already exists for the employee
                if (field === 'startDate') {
                    const existingVacation = vacations[employeeId].find(
                        v => v.startDate === updatedVacation.startDate && v.endDate === vacation.endDate
                    );
                    if (existingVacation) {
                        setToastMessage('Эта дата начала уже выбрана для данного сотрудника');
                        setToastType('warning');
                        return vacation; // Do not update if start date already exists
                    }
                }

                // Check if end date is not before start date
                if (field === 'endDate') {
                    const startDate = new Date(vacation.startDate);
                    const endDate = new Date(updatedVacation.endDate);
                    if (endDate < startDate) {
                        setToastMessage('Дата окончания отпуска не может быть раньше даты начала');
                        setToastType('error');
                        return vacation; // Do not update if end date is before start date
                    }
                }

                // Check if start date is not after end date
                if (field === 'startDate') {
                    const startDate = new Date(updatedVacation.startDate);
                    const endDate = new Date(vacation.endDate);
                    if (startDate > endDate) {
                        setToastMessage('Дата начала отпуска не может быть позже даты окончания');
                        setToastType('error');
                        return vacation; // Do not update if start date is after end date
                    }
                }

                // Calculate days if both start and end dates are set
                if (field === 'startDate' || field === 'endDate') {
                    const start = new Date(updatedVacation.startDate);
                    const end = new Date(updatedVacation.endDate);
                    if (start && end) {
                        const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;
                        updatedVacation.days = days > 0 ? days : 0;
                    }
                }

                return updatedVacation;
            }
            return vacation;
        });

        setVacations({
            ...vacations,
            [employeeId]: updatedEmployeeVacations
        });
    };



    const handleSaveVacations = async () => {
        const allVacationDays = [];
        for (const [employeeId, employeeVacations] of Object.entries(vacations)) {
            employeeVacations.forEach(vacation => {
                const { startDate, endDate } = vacation;
                let currentDate = new Date(startDate);
                while (currentDate <= new Date(endDate)) {
                    allVacationDays.push({ userId: employeeId, date: currentDate.toISOString().split('T')[0], status: 'О' });
                    currentDate.setDate(currentDate.getDate() + 1);
                }
            });
        }

        try {
            await ApiService.postDataWithToken('/sheet/attendance', { attendanceData: allVacationDays });
            setToastMessage('Vacations saved successfully');
            setToastType('success');
            fetchVacations();
        } catch (error) {
            console.error('Error saving vacations:', error);
            setToastMessage('Failed to save vacations');
            setToastType('error');
        }
    };

    return (
        <div className={'vacation-schedule'}>
            <YearSelector
                selectedYear={selectedYear}
                onYearChange={setSelectedYear}
            />
            <table>
                <thead>
                    <tr>
                        <th>Сотрудники</th>
                        <th>Дата начала</th>
                        <th>Количество дней</th>
                        <th>Дата окончания</th>
                        <th>действия</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map(employee => (
                        <React.Fragment key={employee.id}>
                            <tr>
                                <td>{employee.lastName} {employee.firstName}</td>
                                <td colSpan="4">
                                    {vacations[employee.id] && vacations[employee.id].length > 0 ? (
                                        <table>
                                            <tbody>
                                                {vacations[employee.id].map((vacation, index) => (
                                                    <tr key={`${employee.id}-${index}`}>
                                                        <td>
                                                            <input
                                                                type="date"
                                                                min={`${selectedYear}-01-01`}
                                                                max={`${selectedYear}-12-31`}
                                                                value={vacation.startDate}
                                                                onChange={(e) => handleVacationChange(employee.id, index, 'startDate', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                value={vacation.days}
                                                                readOnly
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="date"
                                                                min={`${selectedYear}-01-01`}
                                                                max={`${selectedYear}-12-31`}
                                                                value={vacation.endDate}
                                                                onChange={(e) => handleVacationChange(employee.id, index, 'endDate', e.target.value)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <button onClick={() => handleDeleteVacation(employee.id, index)}>Удалить</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p>Нет отпусков</p>
                                    )}
                                    <button className={'add-vacation-button'} onClick={() => handleAddVacation(employee.id)}>+ Добавить отпуск</button>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>

            </table>
            <button style={{ margin: '5px' }} onClick={handleSaveVacations}>Сохранить все отпуска</button>
            <button style={{ margin: '5px' }} onClick={() => { navigate(`/sheet/attendance/`) }}>
                ГЗ Месяца
            </button>
            <CustomToast
                message={toastMessage}
                type={toastType}
                setMessage={setToastMessage}
                setType={setToastType}
            />
        </div>
    );
};

export default VacationSchedule;
