import React, { useState, useEffect } from 'react';

const FromObject = ({ options, name, placeholder, setData, data, validation, description }) => {
    const [inputValue, setInputValue] = useState(data || '');
    const [filteredOptions, setFilteredOptions] = useState(options); // Изначально устанавливаем все опции
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        if (value.trim() === '') {
            setData('');
            setFilteredOptions(options); // Показываем все опции, если строка поиска пуста
        } else {
            setData(value); // Изменено на строку value вместо объекта
            const filtered = options.filter(option =>
                option[name].toLowerCase().includes(value.toLowerCase())
            );
            setFilteredOptions(filtered);
        }

    };
    useEffect(() => {
        setInputValue(data)
    }, [data]);

    const handleOptionClick = (option) => {
        setData(option[name]);
        setInputValue(option[name]);
        setIsInputFocused(false); // Скрываем опции после выбора
        setFilteredOptions(options); // Сбрасываем фильтрацию
    };

    const handleInputFocus = () => {
        setIsInputFocused(true);
        setFilteredOptions(options); // Показываем все опции при фокусировке
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsInputFocused(false);
        }, 300);
    };

    // Определение стиля для поля ввода
    const inputStyle = {
        borderColor: validation && !data ? 'red' : 'rgb(177, 228, 65)',
        outline: validation && !data ? 'none' : '',
        boxShadow: validation && !data && isInputFocused ? '0 0 5px red' : 'none'
    };

    return (
        <div>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder={placeholder}
                style={inputStyle}
            />
            <div style={{ position: "relative" }}>
                <div className={`options-list ${isInputFocused ? 'has-border' : ''}`} style={{ cursor: 'pointer' }}>
                    {isInputFocused && filteredOptions.map((option, index) => (
                        <div className="options-list-item" key={index} onClick={() => handleOptionClick(option)}>
                            {option[name]}
                        </div>
                    ))}
                </div>
            </div>
            <p style={{
                color: 'rgb(211, 0, 0)',
                fontSize: '12px',
                height: '15px',
                margin: '5px',
                visibility: validation && !data ? 'visible' : 'hidden',
            }}
            >
                *{description}
            </p>
        </div>
    );
};

export default FromObject;
