import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import YearMonthSelector from '../../components/YearMonthSelector';

const Attendance = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [userItems, setUserItems] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();

    const monthStart = useMemo(() => {
        const monthStart = new Date(selectedYear, selectedMonth, 0);
        monthStart.setHours(0, 0, 0, 0);
        return monthStart;
    }, [selectedYear, selectedMonth]);

    const monthEnd = useMemo(() => {
        const monthEnd = new Date(selectedYear, selectedMonth + 1, 0);
        monthEnd.setHours(23, 59, 59, 999);
        return monthEnd;
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        document.title = 'График работы';
        const fetchData = async () => {
            try {
                const user = await ApiService.fetchDataWithToken('/user/getList');
                setUserItems(user);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);

    useEffect(() => {
        const fetchAttendance = async () => {
            try {
                const response = await ApiService.fetchDataWithToken('/sheet/attendance', { startDate: monthStart, endDate: monthEnd });
                setAttendanceData(response.attendanceRecord);
            } catch (error) {
                console.error("Error fetching attendance data:", error);
            }
        };
        fetchAttendance();
    }, [monthStart, monthEnd]);

    const handleAttendanceChange = (userId, date, status) => {
        const updatedAttendanceData = attendanceData.map(record => {
            if ((record.userId === userId && record.date === date)) {
                return { ...record, status };
            }
            return record;
        });
        if (!updatedAttendanceData.some(record => record.userId === userId && record.date === date)) {
            updatedAttendanceData.push({ userId, date, status });
        }
        setAttendanceData(updatedAttendanceData);
    };

    const saveAttendanceData = async () => {
        try {
            await ApiService.postDataWithToken('/sheet/attendance', { attendanceData: attendanceData });
            setToastMessage('All attendance records updated successfully');
            setToastType('success');
            const updatedAttendanceData = await ApiService.fetchDataWithToken('/sheet/attendance', { startDate: monthStart, endDate: monthEnd });
            setAttendanceData(updatedAttendanceData.attendanceRecord);
        } catch (error) {
            console.error('Error saving attendance data:', error);
            setToastMessage('Failed to save attendance data');
            setToastType('error');
        }
    };

    const getWeekDay = (date) => {

        const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
        return days[date.getDay()];
    };
    console.log(getWeekDay(new Date('2024-08-01'))); // например, 1 августа 2024 года

    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();

    const handleInputChange = (userId, date, e) => {
        const value = e.target.value;
        console.log(date)
        if (/^\d*$/.test(value) || ['Б', 'У', 'Д', 'О', 'П'].includes(value)) {
            if (value > 24) {
                setInputValue(24);
                handleAttendanceChange(userId, date, 24);
            } else {
                setInputValue(value);
                handleAttendanceChange(userId, date, value);
            }
        }
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'О':
                return 'status-vacation';
            case 'Б':
                return 'status-sick';
            case 'У':
                return 'status-business-trip';
            case 'П':
                return 'status-off';
            case 'Д':
                return 'status-present';
            default:
                return '';
        }
    };

    return (
        <div>

            <div className='attendance'>
                <div style={{ margin: '10px' }}>
                    Текущая дата: {currentDate.toLocaleString()}
                </div>
                <div style={{ margin: '10px' }}>
                    <YearMonthSelector
                        selectedYear={selectedYear}
                        selectedMonth={selectedMonth}
                        onYearChange={setSelectedYear}
                        onMonthChange={setSelectedMonth}
                    />
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Сотрудники</th>
                            {Array.from({ length: daysInMonth }).map((_, i) => (
                                <th key={i}>{i + 1}</th>
                            ))}
                        </tr>
                        <tr>
                            <th></th>
                            {Array.from({ length: daysInMonth }).map((_, i) => {
                                const date = new Date(selectedYear, selectedMonth, i + 1);
                                const isWeekend = date.getDay() === 0 || date.getDay() === 6;
                                return (
                                    <th key={i} className={isWeekend ? 'weekend' : ''}>
                                        {getWeekDay(date)}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {userItems.map(user => (
                            <tr key={user.id}>
                                <td>{user.lastName} {user.firstName}</td>
                                {Array.from({ length: daysInMonth }).map((_, i) => {
                                    const day = i + 1;
                                    const date = new Date(Date.UTC(selectedYear, selectedMonth, day));
                                    const isWeekend = date.getUTCDay() === 0 || date.getUTCDay() === 6;
                                    const dateString = date.toISOString().split('T')[0];
                                    const attendanceRecord = attendanceData.find(record => record.userId === user.id && record.date === dateString);
                                    const status = attendanceRecord ? attendanceRecord.status : '';

                                    return (
                                        <td key={i} className={isWeekend ? 'weekend' : ''}>
                                            <div style={{ position: 'relative' }}>
                                                <input
                                                    type="text"
                                                    className={`input ${getStatusClass(status)}`}
                                                    value={status}
                                                    onChange={e => handleInputChange(user.id, dateString, e)}
                                                    onFocus={() => setDropdownVisible(true)}
                                                    onBlur={() => setTimeout(() => setDropdownVisible(false), 200)}
                                                    pattern="^([1-9]|1[0-9]|2[0-4]|Б|У|Д|О|П)$"
                                                    placeholder="0"
                                                    title="Введите часы от 1 до 24 или буквы"
                                                />
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>


                </table>
                <button style={{ margin: '5px' }} className='button' onClick={saveAttendanceData}>Сохранить изменения</button>
                <button style={{ margin: '5px' }} onClick={() => { navigate(`/sheet/holiday/`) }}>
                    ГЗ Отпуска
                </button>
            </div>
            <div className="legend">
                <p><span className="status-vacation">О</span> - Отпуск</p>
                <p><span className="status-sick">Б</span> - Больничный</p>
                <p><span className="status-business-trip">У</span> - Удаленка</p>
                <p><span className="status-off">П</span> - Опоздание</p>
                <p><span className="status-present">Д</span> - Дежурство</p>
            </div>
            <CustomToast
                message={toastMessage}
                type={toastType}
                setMessage={setToastMessage}
                setType={setToastType}
            />
        </div>
    );
};

export default Attendance;
