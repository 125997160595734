import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import Grid from '../../components/tables/Grid';
import View from '../../views/view';
import FileUpload from '../../components/file/file';
import Modal from '../../components/Modal'
import AutocompleteInput from '../../components/autoCompliteInputs/FithoutCreate';
import AutocompleteInputFromObject from '../../components/autoCompliteInputs/fromObject';

const Company = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { id } = useParams();

    const apiUrl = '/firm';

    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [tipIdInput, setTipIdInput] = useState('');
    const [tipIdName, setTipName] = useState('');
    const [tariffId, setTariffId] = useState(null);
    const [tariffName, setTariffName] = useState('');
    const [isCompanyCategoryOpen, setIsCompanyCategoryOpen] = useState(false);
    const [isCompanyCategoryAdd, setIsCompanyCategoryAdd] = useState(false);
    const [companyCategoryData, setCompanyCategoryData] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [categoryLessonCost, setCategoryLessonCost] = useState(0);
    const [categoryExtracurricularCost, setCategoryExtracurricularCost] = useState(0);
    const [companyData, setCompanyData] = useState({
        id: '',
        name: '',
        smallName: '',
        phone: '',
        mail: '',
        countPc: null,
        countORG: null,
        countDivision: null,
        companyTypeId: null,
        numberHours: 0,
    });

    const [preventionPC, setPreventionPC] = useState('');
    const [preventionServer, setPreventionServer] = useState('');
    const [сleaning, setCleaning] = useState('');

    const preventionPCOption = [
        { value: "Раз в месяц" },
        { value: "Раз в квартал" },
        { value: "Раз в пол года" },
        { value: "Раз в год" },
        { value: "Попутно с 1 выездом" },
        { value: "По необходимости" },
    ];

    const preventionServerOption = [
        { value: "Раз в месяц" },
        { value: "Четыре раза в месяц" },
        { value: "Два раза в месяц" },
        { value: "Два раза в три месяца" },
        { value: "По необходимости" },
    ];

    const сleaningOption = [
        { value: "Раз в месяц" },
        { value: "Раз в квартал" },
        { value: "Раз в пол года" },
        { value: "Раз в год" },
        { value: "По необходимости" },
    ];

    const columns = [
        { key: 'id', title: 'ИД' },
        { key: 'name', title: 'Название' },
        { key: 'smallName', title: 'Краткое Название' },
        { key: 'phone', title: 'Телефон' },
        { key: 'mail', title: 'Email' },
    ];
    const detailsConfig = [
        { key: 'name', label: 'Название' },
        { key: 'mail', label: 'Email' },
        { key: 'phone', label: 'Телефон' },
        { key: 'smallName', label: 'Краткое название' },
        { key: 'companyTypes', label: 'Тип' },
        { key: 'countPc', label: 'ПК по договору' },
        { key: 'countServer', label: 'Серверов по договору' },
        { key: 'countORG', label: 'Оргтехники по договору' },
        { key: 'countDivision', label: 'Филиалов по договору' },
        { key: 'tariffName', label: 'Тариф' },
        { key: 'numberHours', label: 'Часов по договору' },
        { key: 'timeLeftFormatted', label: 'Оставшееся время' },
    ];

    useEffect(() => {
        document.title = 'Клиенты';
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);


    useEffect(() => {
        if (id) setSelectedRow(id);
    }, [id]);

    const setSelectRow = (id) => {
        setSelectedRow(id);
        let currentPath = location.pathname;
        if (!currentPath.endsWith('/') && !currentPath.match(/\/\d+\/?$/)) {
            currentPath += '/';
        }
        // Получаем текущий путь и разделяем его по слешам
        let pathSegments = currentPath.split('/');
        // Убедимся, что у нас есть достаточно сегментов для изменения
        if (pathSegments.length > 1) {
            // Заменяем последний сегмент на новый id
            pathSegments[pathSegments.length - 1] = id;

            // Объединяем сегменты обратно в строку и заменяем текущий путь в истории
            navigate(pathSegments.join('/'), { replace: true });
        }
    };

    const openUpdate = async () => {
        const response = await ApiService.fetchDataWithToken(`/firm/${selectedRow}`)
        setCompanyData({
            id: response[0].id,
            name: response[0].name || "",
            smallName: response[0].smallName || "",
            phone: response[0].phone || "",
            mail: response[0].mail || "",
            countPc: response[0].countPc || '',
            countServer: response[0].countServer || '',
            countORG: response[0].countORG || '',
            countDivision: response[0].countDivision || '',
            tgLink: response[0].tgLink || "",
            companyTypeId: response[0].companyTypeId || "",
            preventionPC: response[0].preventionPC || "",
            preventionServer: response[0].preventionServer || "",
            сleaning: response[0].сleaning || "",
            numberHours: response[0].numberHours || 0,
        })
        console.log(response)
        setTariffId(response[0].tariffId || null)
        setTariffName(response[0].tariffName || '')
        setPreventionPC(response[0].preventionPC || "")
        setPreventionServer(response[0].preventionServer || "")
        setCleaning(response[0].сleaning || "")
        setTipIdInput(response[0].companyTypesId || "")
        setTipName(response[0].companyTypes || "")
        setIsUpdateModalOpen(true)
    }

    const openCreate = async () => {
        setCompanyData({
            name: "",
            smallName: "",
            phone: "",
            mail: "",
            countPc: '',
            countServer: '',
            countORG: '',
            countDivision: '',
            tgLink: "",
            companyTypeId: "",
            preventionPC: "",
            preventionServer: "",
            numberHours: 0,
            сleaning: "",
        })
        setPreventionPC("")
        setPreventionServer("")
        setCleaning("")
        setTariffId(null)
        setTariffName("")
        setTipIdInput("")
        setTipName("")
        setIsCreateModalOpen(true)
    }

    const openCompanyCategory = async () => {
        try {
            const response = await ApiService.fetchDataWithToken(`/companyCategoryCost/${selectedRow}`);
            setCompanyCategoryData(response); // Устанавливаем данные о не входящих в тариф услугах
            setIsCompanyCategoryOpen(true); // Открываем модальное окно
        } catch (error) {
        }
    };

    const handleAddService = () => {
        setCategoryLessonCost(0);
        setCategoryExtracurricularCost(0);
        setIsCompanyCategoryAdd(true)
    };

    const onRemoveService = async (index) => {
        try {
            const body = {
                id: index,
            }
            console.log(body)
            await ApiService.postDataWithToken(`/companyCategoryCost/delete`, body);
            openCompanyCategory()
        } catch (error) {

        }
    };

    const handleAddCateroryCost = async () => {
        try {
            const body = {
                categoryId: categoryId,
                companyId: selectedRow,
                lessonCost: categoryLessonCost,
                extracurricularCost: categoryExtracurricularCost
            }
            await ApiService.postDataWithToken(`/companyCategoryCost/create`, body);
            setCategoryId(null);
            setCategoryLessonCost(0);
            setCategoryExtracurricularCost(0);
            setIsCompanyCategoryAdd(false)
            openCompanyCategory()
        } catch (error) {

        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCompanyData(prevData => ({ ...prevData, [name]: value }));
    };

    // Функция, вызывающая перерисовку компонента
    const refreshTaskTable = () => {
        setListener(prevKey => prevKey + 1);
    };

    const updateCompany = async () => {
        try {
            const updatedCompanyData = {
                ...companyData, // Копируем все текущие данные
                companyTypeId: tipIdInput, // Обновляем только companytypesid
                preventionPC: preventionPC, // Обновляем только companytypesid
                tariffId: tariffId,
                preventionServer: preventionServer, // Обновляем только companytypesid
                сleaning: сleaning, // Обновляем только companytypesid
            };
            console.log(updatedCompanyData)
            await ApiService.postDataWithToken('/firm/updateFull', updatedCompanyData);
            setToastType('success');
            setToastMessage('Заявка успешно изменена');
            refreshTaskTable();
            setIsUpdateModalOpen(false);
        } catch (error) {
            console.error('Ошибка при обновлении данных компании:', error);
            setToastMessage('Ошибка при отправке данных и файла');
            setToastType('error');
        }
    }

    const createCompany = async () => {
        try {
            const createCompanyData = {
                ...companyData, // Копируем все текущие данные
                companyTypeId: tipIdInput, // Обновляем только companytypesid
                preventionPC: preventionPC, // Обновляем только companytypesid
                tariffId: tariffId,
                preventionServer: preventionServer, // Обновляем только companytypesid
                сleaning: сleaning, // Обновляем только companytypesid
            };
            console.log(createCompanyData)
            await ApiService.postDataWithToken('/firm/add', createCompanyData);
            setToastType('success');
            setToastMessage('Компания успешно создана');
            refreshTaskTable();
            setIsCreateModalOpen(false);
        } catch (error) {
            console.error('Ошибка при обновлении данных компании:', error);
            setToastMessage('Ошибка при отправке данных и файла');
            setToastType('error');
        }
    }

    const deleteCompany = async () => {
        try {
            await ApiService.postDataWithToken('/firm/delete', { id: selectedRow });
            setToastType('success');
            setToastMessage('Заявка успешно удалена');
            refreshTaskTable();
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error('Ошибка при обновлении данных компании:', error);
            setToastMessage('Ошибка при отправке данных и файла');
            setToastType('error');
        }
    }

    const handleFileSubmit = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            await ApiService.postDataWithToken(`/firm/uploadFile/${selectedRow}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setToastMessage('Данные и файл успешно обновлены');
            setToastType('success');
            refreshTaskTable()
            setIsUpdateModalOpen(false)
        } catch (error) {
            setToastMessage('Ошибка при отправке данных и файла');
            setToastType('error');
        }
    };

    return (
        <div>
            <div className="grid-view-container">
                <Grid
                    listener={listener}
                    apiUrl={apiUrl}
                    columns={columns}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectRow}
                />
                <View listener={listener} selectedId={selectedRow} apiUrl="/firm" detailsConfig={detailsConfig}>
                    <button
                        className="task-table-button"
                        onClick={() => { openUpdate() }}
                    >
                        Редактировать
                    </button>
                    <button
                        className="task-table-button"
                        onClick={() => { openCreate() }}
                        alwaysVisible
                    >
                        Создать
                    </button>
                    <button
                        className="task-table-button"
                        onClick={() => { openCompanyCategory() }}
                    >
                        Добавить услугу не входящую в тариф
                    </button>
                    <FileUpload onFileSelectSuccess={handleFileSubmit} onFileSelectError={(error) => setToastMessage(error)} />

                </View>
            </div>
            <div style={{ marginTop: "10px" }}>
                <button style={{ marginLeft: '10px' }} disabled={!selectedRow} onClick={() => { navigate(`${location.pathname}/division/`) }}>
                    Филиалы
                </button>
                <button style={{ marginLeft: '10px' }} disabled={!selectedRow} onClick={() => { navigate(`${location.pathname}/client/`) }}>
                    Представители
                </button>
                <button style={{ marginLeft: '10px' }} disabled={!selectedRow} onClick={() => { navigate(`${location.pathname}/pc/`) }}>
                    ПК
                </button>
                <button style={{ marginLeft: '10px' }} disabled={!selectedRow} onClick={() => { navigate(`${location.pathname}/cashbox/`) }}>
                    Кассы
                </button>
                <button style={{ marginLeft: '10px' }} disabled={!selectedRow} onClick={() => { navigate(`${location.pathname}/server/`) }}>
                    Сервера
                </button>
                <button style={{ marginLeft: '10px' }} disabled={!selectedRow} onClick={() => { navigate(`${location.pathname}/org/`) }}>
                    Орг
                </button>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isCompanyCategoryOpen} setActive={setIsCompanyCategoryOpen}>
                <div className="service-category">
                    <p>Услуги не входящие в тариф:</p>
                    <div className="service-category-header">
                        <div className="service-category-header-item">Услуга</div>
                        <div className="service-category-header-item">Урочная</div>
                        <div className="service-category-header-item">Внеурочная</div>
                        <div></div> {/* Пустой элемент для кнопок */}
                    </div>
                    {companyCategoryData.map((service, index) => (
                        <div key={index} className="service-category-item">
                            <div className="service-category-content">
                                {service.categoryName}
                            </div>
                            <div className="service-category-content">
                                {service.lessonCost}
                            </div>
                            <div className="service-category-content">
                                {service.extracurricularCost}
                            </div>
                            <div className="service-category-actions">
                                {/* Контейнер для кнопок */}
                                <div className="service-category-buttons">
                                    {/* Иконка удаления */}
                                    <button
                                        onClick={() => onRemoveService(service.id)}
                                        className="icon-button delete-button"
                                        title="Удалить услугу"
                                    >
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <button onClick={() => handleAddService()} className="icon-button add-service-button">
                    <i className="fas fa-plus-circle"></i>
                </button>
            </Modal>
            <Modal active={isCompanyCategoryAdd} setActive={setIsCompanyCategoryAdd}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Выберите услугу и укажите стоимость:</p>
                <p style={{ fontWeight: 'bold' }}>Услуга</p>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInput
                        apiUrl={`/tip/all?page=1&itemsPerPage=1000`}
                        name='name'
                        placeholder="Выбрать"
                        setId={setCategoryId}
                        chekValue={categoryId}
                        validation={false}
                    />
                </div>
                <p style={{ fontWeight: 'bold' }}>Стоимость</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="categoryCost" min="0" value={categoryLessonCost} onChange={(e) => setCategoryLessonCost(e.target.value)} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Стоимость</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="categoryCost" min="0" value={categoryExtracurricularCost} onChange={(e) => setCategoryExtracurricularCost(e.target.value)} placeholder="0" />
                </div>
                <button onClick={() => handleAddCateroryCost()}>Отправить</button>
            </Modal>
            <Modal active={isUpdateModalOpen} setActive={setIsUpdateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Редактор клиента {selectedRow}</p>
                <p style={{ fontWeight: 'bold' }}>Название</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="name" value={companyData.name} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Краткое Название</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="smallName" value={companyData.smallName} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Телефон</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="phone" value={companyData.phone} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Email</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="email" name="mail" value={companyData.mail} onChange={handleInputChange} placeholder="Email" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Тариф</p>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInput
                        apiUrl={`/tariff`}
                        name='name'
                        placeholder="Выбрать"
                        setId={setTariffId}
                        setName={setTariffName}
                        chekValue={tariffId}
                        validation={false}
                    />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во часов по договору</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="numberHours" min="0" value={companyData.numberHours} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во филиалов по договору</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countDivision" min="0" value={companyData.countDivision} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во ПК по договору</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countPc" min="0" value={companyData.countPc} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во серверов по договору</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countServer" min="0" value={companyData.countServer} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во оргтехники по договору</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countORG" min="0" value={companyData.countORG} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Ссылка на чат</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="tgLink" value={companyData.tgLink} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Профилактика ПК</p>
                <AutocompleteInputFromObject options={preventionPCOption} name="value" placeholder="Выбрать" setData={setPreventionPC} data={preventionPC} validation={false} />
                <p style={{ fontWeight: 'bold' }}>Профилактика Серверов</p>
                <AutocompleteInputFromObject options={preventionServerOption} name="value" placeholder="Выбрать" setData={setPreventionServer} data={preventionServer} validation={false} />
                <p style={{ fontWeight: 'bold' }}>Чистка</p>
                <AutocompleteInputFromObject options={сleaningOption} name="value" placeholder="Выбрать" setData={setCleaning} data={сleaning} validation={false} />
                <p style={{ fontWeight: 'bold' }}>Тип клиента</p>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInput
                        apiUrl={`/companyType`}
                        name='name'
                        placeholder="Выбрать"
                        setId={setTipIdInput}
                        setName={setTipName}
                        chekValue={tipIdInput}
                        validation={false}
                    />
                </div>
                <button
                    className="button"
                    onClick={updateCompany}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isCreateModalOpen} setActive={setIsCreateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Добавление клиента </p>
                <p style={{ fontWeight: 'bold' }}>Название</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="name" value={companyData.name} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Краткое Название</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="smallName" value={companyData.smallName} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Телефон</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="phone" value={companyData.phone} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Email</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="email" name="mail" value={companyData.mail} onChange={handleInputChange} placeholder="Email" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Тариф</p>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInput
                        apiUrl={`/tariff`}
                        name='name'
                        placeholder="Тариф"
                        setId={setTariffId}
                        setName={setTariffName}
                        chekValue={tariffId}
                        validation={false}
                    />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во часов по договору</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="numberHours" min="0" value={companyData.numberHours} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во филиалов по договору</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countDivision" min="0" value={companyData.countDivision} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во ПК по договору</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countPc" min="0" value={companyData.countPc} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во серверов по договору</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countServer" min="0" value={companyData.countServer} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во оргтехники по договору</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countORG" min="0" value={companyData.countORG} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Ссылка на чат</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="tgLink" value={companyData.tgLink} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Профилактика ПК</p>
                <AutocompleteInputFromObject options={preventionPCOption} name="value" placeholder="Выбрать" setData={setPreventionPC} data={preventionPC} validation={false} />
                <p style={{ fontWeight: 'bold' }}>Профилактика Серверов</p>
                <AutocompleteInputFromObject options={preventionServerOption} name="value" placeholder="Выбрать" setData={setPreventionServer} data={preventionServer} validation={false} />
                <p style={{ fontWeight: 'bold' }}>Чистка</p>
                <AutocompleteInputFromObject options={сleaningOption} name="value" placeholder="Выбрать" setData={setCleaning} data={сleaning} validation={false} />
                <p style={{ fontWeight: 'bold' }}>Тип клиента</p>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInput
                        apiUrl={`/companyType`}
                        name='name'
                        placeholder="Выбрать"
                        setId={setTipIdInput}
                        setName={setTipName}
                        chekValue={tipIdInput}
                        validation={false}
                    />
                </div>
                <button
                    className="button"
                    onClick={createCompany}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isDeleteModalOpen} setActive={setIsDeleteModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}> Вы уверены что хотите удалить клиента {selectedRow}</p>
                <button
                    className="button"
                    onClick={deleteCompany}
                >
                    Да, я уверен(а)
                </button>
            </Modal>
        </div>
    );
};

export default Company;