import React, { useEffect, useState } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';

const ProjectCategoryLinker = () => {
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [projects, setProjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [links, setLinks] = useState([]);
    const [draggedCategoryId, setDraggedCategoryId] = useState(null);
    const [draggedElement, setDraggedElement] = useState(null);

    const fetchData = async () => {
        try {
            const project = await ApiService.fetchDataWithToken('/project');
            const tip = await ApiService.fetchDataWithToken('/tip/all', { itemsPerPage: 1000 });
            const projectCategories = await ApiService.fetchDataWithToken('/project/projectCategories');
            setProjects(project);
            setCategories(tip.items);
            setLinks(projectCategories);
        } catch (error) {
            // Обработка ошибок
        }
    };

    useEffect(() => {
        document.title = 'Связь проектов и категорий';
        fetchData();
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);

    const handleRemoveLink = async (projectId, categoryId) => {
        try {
            await ApiService.postDataWithToken(`/project/projectCategoriesDel`, { projectId, categoryId });
            fetchData();
            setToastMessage("Связь успешно удалена");
            setToastType("success");
        } catch (error) {
            // Обработка ошибок
        }
    };

    const handleDragStart = (e, categoryId) => {
        setDraggedCategoryId(categoryId);
        const category = categories.find(c => c.id === categoryId);
        if (category) {
            const elem = document.createElement('div');
            elem.className = 'dragged-element';
            elem.innerText = category.name;
            document.body.appendChild(elem);
            setDraggedElement(elem);
        }
    };

    const handleDrag = (e) => {
        if (draggedElement) {
            const offsetY = e.clientY + window.scrollY;
            draggedElement.style.left = `${e.clientX + 10}px`;
            draggedElement.style.top = `${offsetY + 10}px`;

            // Автоматическая прокрутка при достижении края видимой области
            if (e.clientY < 100) {
                window.scrollBy(0, -10);
            } else if (window.innerHeight - e.clientY < 100) {
                window.scrollBy(0, 10);
            }
        }
    };

    const handleDrop = async (e, projectId) => {
        e.preventDefault();
        if (draggedCategoryId) {
            try {
                await ApiService.postDataWithToken('/project/projectCategories', { projectId, categoryId: draggedCategoryId });
                fetchData();
            } catch (error) {
                // Обработка ошибок
            }
        }
        cleanupDrag();
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };

    const cleanupDrag = () => {
        setDraggedCategoryId(null);
        if (draggedElement) {
            document.body.removeChild(draggedElement);
            setDraggedElement(null);
        }
    };

    // Handlers for touch events (mobile devices)
    const handleTouchStart = (e, categoryId) => {
        setDraggedCategoryId(categoryId);
        const category = categories.find(c => c.id === categoryId);
        if (category) {
            const elem = document.createElement('div');
            elem.className = 'dragged-element';
            elem.innerText = category.name;
            document.body.appendChild(elem);
            setDraggedElement(elem);
        }
    };

    const handleTouchMove = (e) => {
        e.preventDefault();
        const touch = e.touches[0];
        const offsetY = touch.clientY + window.scrollY;
        if (draggedElement) {
            draggedElement.style.left = `${touch.clientX + 10}px`;
            draggedElement.style.top = `${offsetY + 10}px`;

            // Автоматическая прокрутка при достижении края видимой области
            if (touch.clientY < 100) {
                window.scrollBy(0, -10);
            } else if (window.innerHeight - touch.clientY < 100) {
                window.scrollBy(0, 10);
            }
        }
    };

    const handleTouchEnd = async (e) => {
        if (!draggedCategoryId) return;

        const touch = e.changedTouches[0];
        const element = document.elementFromPoint(touch.clientX, touch.clientY);

        if (element && element.classList.contains('project')) {
            const projectId = element.getAttribute('data-project-id');
            if (projectId) {
                try {
                    await ApiService.postDataWithToken('/project/projectCategories', { projectId, categoryId: draggedCategoryId });
                    fetchData();
                } catch (error) {
                    // Обработка ошибок
                }
            }
        }
        cleanupDrag();
    };

    return (
        <div className="link-container">
            <h1>Связь между проектами и типами</h1>
            <div className="link-content">
                <div className="link-block">
                    <div className="project-def">
                        <h2>Типы заявки</h2>
                        {categories.map(category => (
                            <div
                                key={category.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, category.id)}
                                onDrag={(e) => handleDrag(e)}
                                onTouchStart={(e) => handleTouchStart(e, category.id)}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                                className="category"
                            >
                                {category.name}
                            </div>
                        ))}
                    </div>
                    <div className='project-wrapper'>
                        {projects.map((project) => (
                            <div
                                key={project.id}
                                data-project-id={project.id}
                                onDrop={(e) => handleDrop(e, project.id)}
                                onDragOver={(e) => allowDrop(e)}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                                className="project"
                            >
                                <h3>{project.name}</h3>
                                {links
                                    .filter(link => link.projectId === project.id)
                                    .map((link) => {
                                        const category = categories.find(cat => cat.id === link.categoryId);
                                        return (
                                            <div key={link.categoryId} className="category-new">
                                                {category && category.name}
                                                <button
                                                    onClick={(e) => handleRemoveLink(project.id, link.categoryId)}
                                                    style={{
                                                        padding: '2px 2px',
                                                        color: 'white',
                                                        background: 'none',
                                                        border: 'none',
                                                        cursor: 'pointer'
                                                    }}>
                                                    ❌
                                                </button>
                                            </div>
                                        );
                                    })}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
        </div>
    );
};

export default ProjectCategoryLinker;
