import React, { useState, useEffect, useMemo } from 'react';
import ApiService from '../../components/ApiService';
import YearMonthSelector from '../../components/YearMonthSelector';
import './kpi.scss'

const CompanyTime = () => {
    const [tableMounth, setTableMounth] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [currentDate, setCurrentDate] = useState(new Date());
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // Месяц начинается с 0
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);

    const MounthStart = useMemo(() => {
        const MounthStart = new Date(selectedYear, selectedMonth, 1);
        MounthStart.setHours(0, 0, 0, 0);
        return MounthStart;
    }, [selectedYear, selectedMonth]);

    const MounthEnd = useMemo(() => {
        const MounthEnd = new Date(selectedYear, selectedMonth + 1, 0);
        MounthEnd.setHours(23, 59, 59, 999);
        return MounthEnd;
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        document.title = 'Статистика';
        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        const reloadPage = setTimeout(() => {
            window.location.reload();
        }, 10 * 60 * 1000);

        return () => {
            clearInterval(intervalId);
            clearTimeout(reloadPage);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [mounthResponse] = await Promise.all([
                    ApiService.fetchDataWithToken('/kpi/companyTime', { startDate: MounthStart, endDate: MounthEnd }),
                ]);
                if (mounthResponse.companies) {
                    setTableMounth(mounthResponse.companies);
                    console.log(mounthResponse.companies);
                }
            } catch (error) {
            }
        };
        fetchData();

    }, [MounthStart, MounthEnd]);


    const handleSort = (column) => {
        console.log('dfdf')
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            console.log('ddddd')
        } else {
            setSortColumn(column);
            setSortDirection('asc');
            console.log('aaaaa')
        }
    };
    const sortedData = tableMounth.sort((a, b) => {
        if (sortColumn === 'Сумма') {
            const aValue = Object.keys(a).reduce((acc, key) => {
                if (key !== 'userid') {
                    return acc + (parseFloat(a[key]) || 0);
                }
                return acc;
            }, 0);
            const bValue = Object.keys(b).reduce((acc, key) => {
                if (key !== 'userid') {
                    return acc + (parseFloat(b[key]) || 0);
                }
                return acc;
            }, 0);
            if (!isNaN(aValue) && !isNaN(bValue)) {
                return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
            } else {
                return 0;
            }
        } else {
            const aValue = parseFloat(a[sortColumn]);
            const bValue = parseFloat(b[sortColumn]);
            if (!isNaN(aValue) && !isNaN(bValue)) {
                return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
            } else {
                return 0;
            }
        }
    });



    return (
        <div>
            <div style={{ margin: '10px' }}>
                Текущая дата: {currentDate.toLocaleString()}
            </div>
            <div style={{ margin: '10px' }}>
                <YearMonthSelector
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    onYearChange={setSelectedYear}
                    onMonthChange={setSelectedMonth}
                />
            </div>
            <div className="kpi-tasks-table-container">
                <div className='table-wrapper'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="kpi-table-header">Компания</th>
                                <th className={sortColumn === 'Общая сумма' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Общая сумма')}>Сумма</th>
                                <th className={sortColumn === '1С (обновление настройка отчетов в' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('1С (обновление настройка отчетов в')}>1С (обновление настройка отчетов выгрузки)</th>
                                <th className={sortColumn === 'АТС' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('АТС')}>АТС</th>
                                <th className={sortColumn === 'Видео' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Видео')}>Видео</th>
                                <th className={sortColumn === 'Выезд' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Выезд')}>Выезд</th>
                                <th className={sortColumn === 'Диагностика ПК и Оборудования' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Диагностика ПК и Оборудования')}>Диагностика ПК и Оборудования</th>
                                <th className={sortColumn === 'Доступы VPN 1С Сервер Почта' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Доступы VPN 1С Сервер Почта')}>Доступы VPN 1С Сервер Почта</th>
                                <th className={sortColumn === 'Другое' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Другое')}>Другое</th>
                                <th className={sortColumn === 'Интернет и сетевое оборудование' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Интернет и сетевое оборудование')}>Интернет и сетевое оборудование</th>
                                <th className={sortColumn === 'Монтаж' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Монтаж')}>Монтаж</th>
                                <th className={sortColumn === 'Оргтехника' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Оргтехника')}>Оргтехника</th>
                                <th className={sortColumn === 'Отдел Продаж' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Отдел Продаж')}>Отдел Продаж</th>
                                <th className={sortColumn === 'Подключение новых устройств (роут' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Подключение новых устройств (роут')}>Подключение новых устройств (роутеры/оргтехника/сервера/пк)</th>
                                <th className={sortColumn === 'Проектные работы' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Проектные работы')}>Проектные работы</th>
                                <th className={sortColumn === 'Работа с ПО БАЗОВОЕ' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Работа с ПО БАЗОВОЕ')}>Работа с ПО БАЗОВОЕ</th>
                                <th className={sortColumn === 'Работа с ПО ПРОФ' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Работа с ПО ПРОФ')}>Работа с ПО ПРОФ</th>
                                <th className={sortColumn === 'Торговое оборудование' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Торговое оборудование')}>Торговое оборудование</th>
                                <th className={sortColumn === 'Установка ОС БАЗ' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Установка ОС БАЗ')}>Установка ОС БАЗ</th>
                                <th className={sortColumn === 'Установка ОС ПРОФ' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('Установка ОС ПРОФ')}>Установка ОС ПРОФ</th>
                                <th className={sortColumn === 'ЭЦП ЭДО ГосПорталы' ? 'kpi-table-header selected-column' : 'kpi-table-header'} onClick={() => handleSort('ЭЦП ЭДО ГосПорталы')}>ЭЦП ЭДО ГосПорталы</th>

                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((item, index) => (
                                <tr key={index}>
                                    <td className="kpi-table-cell">{item.companyname}</td>
                                    <td className="kpi-table-cell">{item['Общая сумма']}</td>
                                    <td className="kpi-table-cell">{item['1С (обновление настройка отчетов в']}</td>
                                    <td className="kpi-table-cell">{item['АТС']}</td>
                                    <td className="kpi-table-cell">{item['Видео']}</td>
                                    <td className="kpi-table-cell">{item['Выезд']}</td>
                                    <td className="kpi-table-cell">{item['Диагностика ПК и Оборудования']}</td>
                                    <td className="kpi-table-cell">{item['Доступы VPN 1С Сервер Почта']}</td>
                                    <td className="kpi-table-cell">{item['Другое']}</td>
                                    <td className="kpi-table-cell">{item['Интернет и сетевое оборудование']}</td>
                                    <td className="kpi-table-cell">{item['Монтаж']}</td>
                                    <td className="kpi-table-cell">{item['Оргтехника']}</td>
                                    <td className="kpi-table-cell">{item['Отдел Продаж']}</td>
                                    <td className="kpi-table-cell">{item['Подключение новых устройств (роут']}</td>
                                    <td className="kpi-table-cell">{item['Проектные работы']}</td>
                                    <td className="kpi-table-cell">{item['Работа с ПО БАЗОВОЕ']}</td>
                                    <td className="kpi-table-cell">{item['Работа с ПО ПРОФ']}</td>
                                    <td className="kpi-table-cell">{item['Торговое оборудование']}</td>
                                    <td className="kpi-table-cell">{item['Установка ОС БАЗ']}</td>
                                    <td className="kpi-table-cell">{item['Установка ОС ПРОФ']}</td>
                                    <td className="kpi-table-cell">{item['ЭЦП ЭДО ГосПорталы']}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CompanyTime;