
import React, { useState, useEffect } from 'react';
import ApiService from '../components/ApiService';
import CustomToast from '../components/CustomToast';
import Grid from '../components/tables/Grid';
import View from '../views/view';
import Modal from '../components/Modal'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Tariff = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { id } = useParams();

    const apiUrl = `/tariff`;

    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [tariffData, setTariffData] = useState({
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        extracurricularCost: '',
        lessonCost: '',
        rounding: '',
        dayOff: false,
    });

    const columns = [
        { key: 'id', title: 'ИД' },
        { key: 'name', title: 'Наименование' },
        { key: 'extracurricularCost', title: 'Стоимость во внеурочное время' },
        { key: 'lessonCost', title: 'Стоимость в урочное время' },
    ];
    const detailsConfig = [
        { key: 'id', label: 'ИД' },
        { key: 'name', label: 'Наименование' },
        { key: 'startTime', label: 'Начало времени' },
        { key: 'endTime', label: 'Конец времени' },
        { key: 'extracurricularCost', label: 'Стоимость во внеурочное время' },
        { key: 'lessonCost', label: 'Стоимость в урочное время' },
        { key: 'rounding', label: 'Округление' },
        { key: 'dayOff', label: 'Считать выходной урочным временем' },
    ];

    useEffect(() => {
        document.title = 'Тарифы';
        ApiService.setToastHandlers(setToastMessage, setToastType);

    }, []);

    useEffect(() => {
        if (id) setSelectedRow(id);
    }, [id]);

    const setSelectRow = (id) => {
        setSelectedRow(id);
        let currentPath = location.pathname;
        if (!currentPath.endsWith('/') && !currentPath.match(/\/\d+\/?$/)) {
            currentPath += '/';
        }
        // Получаем текущий путь и разделяем его по слешам
        let pathSegments = currentPath.split('/');

        // Убедимся, что у нас есть достаточно сегментов для изменения
        if (pathSegments.length > 1) {
            // Заменяем последний сегмент на новый id
            pathSegments[pathSegments.length - 1] = id;

            // Объединяем сегменты обратно в строку и заменяем текущий путь в истории
            navigate(pathSegments.join('/'), { replace: true });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTariffData(prevData => ({ ...prevData, [name]: value }));
    };

    // Функция, вызывающая перерисовку компонента
    const refreshTable = () => {
        setListener(prevKey => prevKey + 1);
    };


    const openCreate = async () => {
        setTariffData({
            id: '',
            name: '',
            startTime: '',
            endTime: '',
            extracurricularCost: '',
            lessonCost: '',
            rounding: '',
            dayOff: false,
        })
        setIsCreateModalOpen(true)
    }

    const openUpdate = async () => {
        const response = await ApiService.fetchDataWithToken(`/tariff/${selectedRow}`)
        setTariffData({
            id: response[0].id,
            name: response[0].name,
            startTime: response[0].startTime,
            endTime: response[0].endTime,
            extracurricularCost: response[0].extracurricularCost,
            lessonCost: response[0].lessonCost,
            rounding: response[0].rounding,
            dayOff: response[0].dayOff,
        })
        setIsUpdateModalOpen(true)
    }

    const updateTariff = async () => {
        try {
            await ApiService.postDataWithToken(`/tariff/update`, tariffData);
            setToastType('success');
            setToastMessage('Тариф успешно изменен');
            refreshTable()
            setIsUpdateModalOpen(false)
        } catch (error) {
        }
    }

    const createTariff = async () => {
        try {
            await ApiService.postDataWithToken(`/tariff/add`, tariffData);
            setToastType('success');
            setToastMessage('Тариф успешно сохранен');
            refreshTable()
            setIsCreateModalOpen(false)
        } catch (error) {
        }
    }
    const deleteTariff = async () => {
        try {
            await ApiService.postDataWithToken(`/tariff/delete`, { id: selectedRow });
            setToastType('success');
            setToastMessage('Тариф успешно удален');
            refreshTable()
            setIsDeleteModalOpen(false)
        } catch (error) {
        }
    }
    return (
        <div>
            <div className="grid-view-container">
                <Grid
                    listener={listener}
                    apiUrl={apiUrl}
                    columns={columns}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectRow}
                />
                <View listener={listener} selectedId={selectedRow} apiUrl={apiUrl} detailsConfig={detailsConfig}>
                    <button style={{ height: '45px', margin: '5px' }}
                        className="task-table-button"
                        onClick={() => { openUpdate() }}
                    >
                        Редактировать
                    </button>
                    <button style={{ height: '45px', margin: '5px' }}
                        className="task-table-button"
                        onClick={() => { openCreate() }}
                        alwaysVisible
                    >
                        Создать
                    </button>
                    <button style={{ height: '45px', margin: '5px' }}
                        className="task-table-button"
                        onClick={() => { setIsDeleteModalOpen(true) }}
                    >
                        Удалить
                    </button>
                </View>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isUpdateModalOpen} setActive={setIsUpdateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Редактор тарифа {tariffData.id}</p>
                <p style={{ fontWeight: 'bold' }}>Название</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="name" value={tariffData.name} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Начало интервала урочного(рабочего) времени</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="time" name="startTime" min="0" max='23' value={tariffData.startTime} onChange={handleInputChange} />
                </div>
                <p style={{ fontWeight: 'bold' }}>Конец интервала урочного(рабочего) времени</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="time" name="endTime" min="0" max='23' value={tariffData.endTime} onChange={handleInputChange} />
                </div>
                <p style={{ fontWeight: 'bold' }}>Стоимость в рабочее время</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="lessonCost" min="0" value={tariffData.lessonCost} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Стоимость в нерабочее время</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="extracurricularCost" min="0" value={tariffData.extracurricularCost} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Округление времени (в минутах)</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="rounding" min="0" value={tariffData.rounding} onChange={handleInputChange} placeholder="15" />
                </div>
                <label className="checkbox-container">
                    <input
                        style={{ height: '30px', width: '30px' }}
                        type="checkbox"
                        className="checkbox-input"
                        checked={tariffData.dayOff}
                        onChange={() => setTariffData(prevData => ({
                            ...prevData,
                            dayOff: !prevData.dayOff
                        }))}
                    />
                    <span className="checkmark"></span>
                    <span style={{ fontSize: '18px' }}>Урочная стоимость действует всю неделю в указанное время</span>
                </label>
                <button
                    className="button"
                    onClick={updateTariff}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isCreateModalOpen} setActive={setIsCreateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Создание тарифа</p>
                <p style={{ fontWeight: 'bold' }}>Название</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="name" value={tariffData.name} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Начало интервала урочного(рабочего) времени</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="time" name="startTime" min="0" max='23' value={tariffData.startTime} onChange={handleInputChange} />
                </div>
                <p style={{ fontWeight: 'bold' }}>Конец интервала урочного(рабочего) времени</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="time" name="endTime" min="0" max='23' value={tariffData.endTime} onChange={handleInputChange} />
                </div>
                <p style={{ fontWeight: 'bold' }}>Стоимость в рабочее время</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="lessonCost" min="0" value={tariffData.lessonCost} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Стоимость в нерабочее время</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="extracurricularCost" min="0" value={tariffData.extracurricularCost} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Округление времени (в минутах)</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="rounding" min="0" value={tariffData.rounding} onChange={handleInputChange} placeholder="15" />
                </div>
                <label className="checkbox-container">
                    <input
                        style={{ height: '30px', width: '30px' }}
                        type="checkbox"
                        className="checkbox-input"
                        checked={tariffData.dayOff}
                        onChange={() => setTariffData(prevData => ({
                            ...prevData,
                            dayOff: !prevData.dayOff
                        }))}
                    />
                    <span className="checkmark"></span>
                    <span style={{ fontSize: '18px' }}>Урочная стоимость действует всю неделю в указанное время</span>
                </label>

                <button
                    className="button"
                    onClick={createTariff}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isDeleteModalOpen} setActive={setIsDeleteModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}> Вы уверены что хотите удалить тариф {selectedRow}</p>
                <button
                    className="button"
                    onClick={deleteTariff}
                >
                    Да, я уверен(а)
                </button>
            </Modal>
        </div>
    );
};

export default Tariff
