import React, { useState } from 'react';

const Input = ({ data, setData, placeholder, validation , type, readOnly = false}) => {
    const [typeInput, setType] = useState(type || 'text');
    const [isFocus, setFocus] = useState(false);

    // Функция для проверки условий валидации
    const isValid = () => {
        if (!validation) return true; // Если валидация отключена
        if (validation.required && data === '') return false;
        if (validation.minLength && data.length < validation.minLength) return false;
        return true;
    };

    const showError =!isValid(); // Показывать ошибку только при фокусе и наличии ошибки

    return (
        <div>
            <input
                type={typeInput}
                value={data}
                placeholder={placeholder}
                onFocus={() => { setFocus(true); }}
                onBlur={() => { setFocus(false); }}
                style={{
                    borderColor: showError ? 'red' : '', // Изменение цвета рамки при ошибке
                    boxShadow: showError && isFocus ? '0 0 5px red' : '', // Тень для визуального выделения поля с ошибкой
                }}
                readOnly={readOnly}
                min={validation?.min} // Добавление атрибута min, если он передан валидации
                max={validation?.max} // Добавление атрибута max, если он передан валидации
                onChange={(e) => setData(e.target.value)}
            />
            <div style={{ position: "relative" }}>
                <p
                    style={{
                        color: 'rgb(211, 0, 0)',
                        fontSize: '12px',
                        height: '15px',
                        margin: '0px',
                        visibility: showError ? 'visible' : 'hidden', // Показать или скрыть сообщение об ошибке
                    }}
                >
                    {validation?.message || 'Обязательное поле'}
                </p>
            </div>
        </div>
    );
};

export default Input;
