import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import pdf from '../assets/images/icons/PDF.png';
import dock from '../assets/images/icons/DOC.png';
import xls from '../assets/images/icons/XLS.png';
import pptx from '../assets/images/icons/PPTX.png';
import jpg from '../assets/images/icons/JPEG.png';
import def from '../assets/images/icons/__SATORI.png';

const InputMention = ({ items, onSelectItem, placeHolder, setInput, input, listener, listeners, setFile, handleSubmit }) => {
    const [inputValue, setInputValue] = useState('');
    const [showMenu, setShowMenu] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [files, setFiles] = useState([]);
    const textareaRef = useRef(null);
    const fileInputRef = useRef(null);

    const handleChange = useCallback((event) => {
        const { value } = event.target;
        setInputValue(value);
        setInput(value);
        setShowMenu(value.includes('@') || value.includes('+'));

        const updatedUsers = selectedUsers.filter((user) => {
            const userFullName = `${user.firstName} ${user.lastName}`;
            return value.includes(`@${userFullName},`) || value.includes(`@${user.lastName},`) || value.includes(`+${userFullName},`) || value.includes(`+${user.lastName},`);
        });
        setSelectedUsers(updatedUsers);
        onSelectItem(updatedUsers);
    }, [selectedUsers, setInput, onSelectItem]);

    useEffect(() => {
        setInputValue('');
        setInput('')
        setSelectedUsers([]);
        setShowMenu(false);
        setFile([]);
        setFiles([]);

        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    }, [listener, listeners]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            const scrollHeight = textareaRef.current.scrollHeight;
            const maxHeight = parseInt(window.getComputedStyle(textareaRef.current).maxHeight);
            textareaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
        }
    }, [inputValue]);

    const handleSelectUser = useCallback((user) => {
        const isUserSelected = selectedUsers.some((u) => u.id === user.id);
        const userFullName = `${user.firstName} ${user.lastName}`;

        if (!isUserSelected) {
            const updatedUsers = [...selectedUsers, user];
            setSelectedUsers(updatedUsers);

            const query = inputValue.split(/[@+]/).pop().trim();
            const newInputValue = inputValue.substring(0, inputValue.lastIndexOf(query) - 1);

            setInputValue(newInputValue + `@${userFullName}, `);
            setInput(newInputValue + `@${userFullName}, `);
            onSelectItem(updatedUsers);
        } else {
            const query = inputValue.split(/[@+]/).pop().trim();
            const newInputValue = inputValue.substring(0, inputValue.lastIndexOf(query) - 1);

            setInputValue(newInputValue + `@${userFullName}, `);
            setInput(newInputValue + `@${userFullName}, `);
        }
        setShowMenu(false);
    }, [inputValue, selectedUsers, setInput, onSelectItem]);

    const filteredItems = useMemo(() => {
        const query = inputValue.split(/[@+]/).pop().trim().toLowerCase();
        if (!inputValue.includes('@') && !inputValue.includes('+')) return [];
        if (!query) return items;
        return items.filter((user) =>
            `${user.firstName} ${user.lastName}`.toLowerCase().includes(query)
        );
    }, [inputValue, items]);

    const handleClipClick = useCallback(() => {
        document.getElementById(`file-upload-${listener}`).click();
    }, [listener]);

    const handleMenuIconClick = useCallback(() => {
        setInput((prevInput) => {
            // Check if '@' is already present at the end of the input
            if (prevInput.endsWith('@')) {
                // Remove '@' from the end of the input
                return prevInput.slice(0, -1);
            } else {
                // Append '@' to the end of the input
                return prevInput + '@';
            }
        });

        setInputValue((prevInputValue) => {
            // Check if '@' is already present at the end of the inputValue
            if (prevInputValue.endsWith('@')) {
                // Remove '@' from the end of the inputValue
                return prevInputValue.slice(0, -1);
            } else {
                // Append '@' to the end of the inputValue
                return prevInputValue + '@';
            }
        });
    }, [setInput, setInputValue]);


    const handleFileChange = useCallback((e) => {
        const newFiles = Array.from(e.target.files);
        setFiles(prevFiles => [...prevFiles, ...newFiles].slice(0, 14));
        setFile(prevFiles => [...prevFiles, ...newFiles].slice(0, 14));
    }, [setFile]);

    const removeFile = useCallback((index) => {
        setFiles(currentFiles => currentFiles.filter((_, i) => i !== index));
        setFile(currentFiles => currentFiles.filter((_, i) => i !== index));
    }, [setFile]);

    const getFileIcon = useCallback((filename) => {
        if (!filename) return def;
        const extension = filename.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf': return pdf;
            case 'doc':
            case 'docx': return dock;
            case 'xls':
            case 'xlsx': return xls;
            default: return def;
        }
    }, []);

    return (
        <div className="input-small-menu-container">
            <div className="input-container">
                <svg onClick={handleClipClick} className="clip-icon" viewBox="0 0 24 24" width="24" height="24" fill="none">
                    <path d="M8 8.00049V6.00049C8 3.79135 9.79086 2.00049 12 2.00049C14.2091 2.00049 16 3.79135 16 6.00049V18.0005C16 20.2096 14.2091 22.0005 12 22.0005C9.79086 22.0005 8 20.2096 8 18.0005V13.5005C8 12.1198 9.11929 11.0005 10.5 11.0005C11.8807 11.0005 13 12.1198 13 13.5005V16.0005" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <svg onClick={handleMenuIconClick} className="clip-icon" style={{ marginLeft: '0px' }} viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                    <path d="M15.6 8.40033V12.9003C15.6 14.3915 16.8088 15.6003 18.3 15.6003C19.7912 15.6003 21 14.3915 21 12.9003V12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C14.0265 21 15.8965 20.3302 17.4009 19.2M15.6 12.0003C15.6 13.9886 13.9882 15.6003 12 15.6003C10.0118 15.6003 8.4 13.9886 8.4 12.0003C8.4 10.0121 10.0118 8.40033 12 8.40033C13.9882 8.40033 15.6 10.0121 15.6 12.0003Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
                <textarea
                    ref={textareaRef}
                    type="text"
                    value={inputValue}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSubmit();
                        }
                    }}
                    placeholder={placeHolder}
                    className="input-mention"
                />
                <svg onClick={handleSubmit} className="clip-icon" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" />
                    <path d="M10.5 8C10.5 8 13.5 10.946 13.5 12C13.5 13.0541 10.5 16 10.5 16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <input
                    type="file"
                    multiple
                    accept="*/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    id={`file-upload-${listener}`}
                    ref={fileInputRef}  // Присваиваем ссылку input[type="file"]
                />
            </div>
            <div className="files-preview">
                {files.map((file, index) => (
                    <div key={index} className="file-item">
                        {file.type.startsWith('image/') ? (
                            <img src={URL.createObjectURL(file)} alt={`preview ${index}`} className="file-image" />
                        ) : (
                            <div className="file-icon">
                                <img src={getFileIcon(file.name)} alt={`icon ${index}`} />
                            </div>
                        )}
                        <div className="file-name">
                            {file.name}
                        </div>
                        <button onClick={() => removeFile(index)} className="remove-file-btn">X</button>
                    </div>
                ))}
            </div>
            {filteredItems.length > 0 && (
                <ul className="menu-list">
                    {filteredItems.map((user, index) => (
                        <li key={index} className="menu-item" onClick={() => handleSelectUser(user)}>
                            {user.firstName} {user.lastName}
                        </li>
                    ))}
                </ul>
            )}

        </div>
    );
};

export default InputMention;
