import React from 'react';

const CheckboxGroup = ({ options, setOptions, specialKeys }) => {
    const handleChange = (key) => {
        setOptions(current => {
            const isSpecialKey = specialKeys.includes(key);

            // Проход по всем ключам и обновление состояния
            return Object.keys(current).reduce((acc, currentKey) => {
                if (isSpecialKey) {
                    // Если ключ особый, сбрасываем все чекбоксы, кроме активного
                    acc[currentKey] = {
                        ...current[currentKey],
                        checked: currentKey === key ? !current[currentKey].checked : false
                    };
                } else {
                    // Если ключ не особый, сбрасываем только чекбоксы из списка specialKeys
                    acc[currentKey] = {
                        ...current[currentKey],
                        checked: currentKey === key
                            ? !current[currentKey].checked
                            : specialKeys.includes(currentKey) ? false : current[currentKey].checked
                    };
                }
                return acc;
            }, {});
        });
    };

    return (
        <div>
            {Object.keys(options).map((key) => (
                <div key={key} style={{ marginTop: "10px" }}>
                    <label className="checkbox-container" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            style={{ height: '25px', width: '25px' }}
                            type="checkbox"
                            className="checkbox-input"
                            checked={options[key].checked}
                            onChange={() => handleChange(key)}
                            disabled={options[key].disabled}
                        />
                        <span className="checkmark"></span>
                        <span style={{ marginLeft: '5px', fontSize: '14px' }}>{options[key].label}</span>
                    </label>
                </div>
            ))}
        </div>
    );
};

export default CheckboxGroup;
