import React, { useState, useEffect, useMemo, useRef } from 'react';
import ApiService from '../../components/ApiService';
import { useNavigate, useParams } from 'react-router-dom';
import YearMonthSelector from '../../components/YearMonthSelector';
import Modal from '../../components/Modal';
import SmallMenu from '../../components/smallMenu';
import Chat from '../../components/chat';
import View from '../../views/view';
import CustomToast from '../../components/CustomToast';
import AutocompleteInput from '../../components/autoCompliteInputs/FithoutCreate';
import ForBookeeping from '../../components/tables/forBookeeping';
import TaskFinishStatistic from '../../components/taskFinishStatistic';

const TaskTime = () => {
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [statistic, setStatistic] = useState(false);
    const [item, setItem] = useState([])

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) { setSelectedRow(id); handleOpenView(id) }
    }, [id]);

    const [currentDate, setCurrentDate] = useState(new Date());
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // Месяц начинается с 0

    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);

    const MounthStart = useMemo(() => {
        const MounthStart = new Date(selectedYear, selectedMonth, 1);
        MounthStart.setHours(0, 0, 0, 0);
        return MounthStart;
    }, [selectedYear, selectedMonth]);

    const MounthEnd = useMemo(() => {
        const MounthEnd = new Date(selectedYear, selectedMonth + 1, 0);
        MounthEnd.setHours(23, 59, 59, 999);
        return MounthEnd;
    }, [selectedYear, selectedMonth]);
    const [companyId, setCompanyId] = useState('');
    const { companyId: routeCompanyId } = useParams(); // Получение параметра из URL

    useEffect(() => {
        // Если URL-параметр существует и отличается от текущего состояния, обновляем состояние
        if (routeCompanyId) {
            setCompanyId(routeCompanyId);
        }
    }, [])

    useEffect(() => {
        // Если URL-параметр существует и отличается от текущего состояния, обновляем состояние
        if (routeCompanyId !== companyId) {
            navigate(`/bookkeeping/${companyId}`, { replace: true });
        }
    }, [routeCompanyId, companyId, navigate]); // Добавил navigate в зависимости

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.postDataWithToken('/application/forBookkeeping', {
                    startDate: MounthStart,
                    endDate: MounthEnd,
                    companyId: companyId,
                });
                console.log(response);
                setItem(response);
            } catch (error) {
                console.log(error);
            }
        };

        // Если существует идентификатор компании, загружаем данные
        if (companyId) {
            fetchData();
        }
    }, [MounthStart, MounthEnd, companyId]);


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        const reloadPage = setTimeout(() => {
            window.location.reload();
        }, 10 * 60 * 1000);

        return () => {
            clearInterval(intervalId);
            clearTimeout(reloadPage);
        };
    }, []);


    const [userItems, setUserItems] = useState([]);
    const [modalActive, setModalActive] = useState(false);
    const [nameInput, setNameInput] = useState('');
    const [commentInput, setCommentInput] = useState('');
    const [description, setDescription] = useState('');
    const [projectIdInput, setProjectIdInput] = useState('');
    const [projectName, setProjectName] = useState('');
    const [firmIdInput, setFirmIdInput] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [priorityName, setPriorityName] = useState('');
    const [tipIdInput, setTipIdInput] = useState('');
    const [tipIdName, setTipName] = useState('');
    const [priorityIdInput, setPriorityIdInput] = useState('');
    const [isFocus, setFocus] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [newDeadline, setNewDeadline] = useState(null);
    const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [openModelDeadline, setOpenModelDeadline] = useState(false);
    const [openView, setOpenView] = useState(false); // Массив для хранения изображений
    const [divisionId, setDivisionId] = useState(null);
    const [ORGId, setORGId] = useState(null);
    const [completion_time, setCompletion_time] = useState(null);
    const [PCId, setPCId] = useState(null);

    const prevIsViewOpenRef = useRef(openView);

    useEffect(() => {
        if (!openView && prevIsViewOpenRef.current) {
            // Убедитесь, что навигация выполняется только один раз
            prevIsViewOpenRef.current = false;
            setSelectedRow(null);
            navigate(`/bookkeeping/${companyId}`); // Укажите нужный путь
        }
    }, [openView, navigate, companyId]);


    const detailsConfig = [
        { key: 'id', label: "ИД" },
        { key: 'createdAt', label: "Дата создания" },
        { key: 'name', label: "Имя" },
        { key: 'status', label: "Статус" },
        { key: 'company', label: 'Компания' },
        { key: 'createbyuser', label: "Ответственный" },
        { key: 'category', label: "Тип" },
        { key: 'priority', label: "Приоритет" },
        { key: 'adres', label: "Адрес филиала" },
        { key: 'number', label: "Номер ПК" },
        { key: 'anyDesk', label: "Деск" },
        { key: 'deadline', label: "Крайний срок", date: true },
    ];

    const refreshTaskTable = () => {
        setListener(prevKey => prevKey + 1);
    };

    const handleFinishOpen = async () => {
        try {
            const task = await ApiService.fetchDataWithToken('/application', { taskId: selectedRow });
            setCompletion_time(task.completion_time || null)
            setIsFinishModalOpen(true)
        } catch (e) {

        }
    };

    const handleFinishSubmit = async () => {
        try {
            const user = await ApiService.fetchDataWithToken('/user/byToken', {})
            const body = {
                id: selectedRow,
                comment: commentInput,
                completion_time: completion_time,
                userId: user.id
            }
            const response = await ApiService.postDataWithToken('/application/commentAndFinish', body)
            setToastType('success');
            setToastMessage(response.message);
            refreshTaskTable()
            setIsFinishModalOpen(false)
        } catch (e) {
        }
    };


    const handleUserSelect = async (userId) => {
        try {
            if (userId !== '') {
                const body = {
                    id: selectedRow,
                    userId: userId
                };
                const delegate = await ApiService.postDataWithToken('/application/messageToUser', body);
                setToastType('success');
                setToastMessage(delegate.message);
                refreshTaskTable()
            }
        } catch (error) {
            setToastType('error');
            setToastMessage(error.response.data.message);
        }
    };

    const handleOpenView = async (id) => {
        setSelectedRow(id);
        setOpenView(true); // Устанавливаем состояние модалки сразу
        await handleGetItemData(id);
    };

    const handleGetItemData = async (id) => {
        const response = await ApiService.fetchDataWithToken('/application', { taskId: id });
        const company = response?.company || {};
        const project = response?.project || {};
        const category = response?.category || {};
        const projectId = response?.projectId || '';
        await setProjectIdInput(projectId);
        setNameInput(response?.name || '');
        setTipIdInput(response?.categoryId);
        setDivisionId(response?.divisionId || null);
        setPCId(response?.pcId || null);
        setNewDeadline(response?.deadline);
        setDescription(response?.description || '');
        setPriorityName(response?.priority || '');
        setCompanyName(company.name || '');
        setProjectName(project.name || '');
        setTipName(category.name || '');
        setFirmIdInput(response?.companyId || null);
        setIsChecked(response?.oneTime || false);
        const user = await ApiService.fetchDataWithToken('/user/byProject', { projectId });
        setUserItems(user);
    }

    const updateTask = async () => {
        const body = {
            taskId: selectedRow,
            name: nameInput,
            description: commentInput,
            priority: priorityName,
            projectId: projectIdInput,
            companyId: firmIdInput,
            divisionId: divisionId,
            pcId: PCId,
            ORGId: ORGId,
            categoryId: tipIdInput,
            companyName: companyName,
            tipName: tipIdName,
            projectName: projectName,
            categoryName: tipIdName,
            oneTime: isChecked,
        }
        try {
            if (!isChecked) {
                await ApiService.postDataWithToken('/application/update', body);
            } else {
                await ApiService.postDataWithToken('/application/updateOneTime', body);
            }
            setToastType('success');
            setToastMessage('Заявка успешно изменена');
            refreshTaskTable()
            setModalActive(false)

        } catch (error) {
        }
    }
    const deleteTask = async () => {
        const body = {
            taskId: selectedRow,
        }
        try {
            await ApiService.postDataWithToken('/application/delete', body);
            refreshTaskTable()
        } catch (error) {

        }
    }

    const resumeTask = async () => {
        const body = {
            taskId: selectedRow,
        }
        try {
            await ApiService.postDataWithToken('/application/resumeCompletedTask', body);
            refreshTaskTable()
        } catch (error) {

        }
    }

    const openUpdateDeadline = () => {
        setCommentInput('')
        setOpenModelDeadline(true)
    };

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        const dateObject = new Date(selectedDate);
        setNewDeadline(dateObject);
    };

    const formatDate = (dateIn) => {
        if (!dateIn) return '';
        const date = new Date(dateIn)
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const handleUpdateDeadline = async () => {
        try {
            const bodyDeadline = {
                id: selectedRow,
                deadline: newDeadline,
                comment: commentInput,
            };

            await ApiService.postDataWithToken('/application/setDeadline', bodyDeadline);
            setToastType('success');
            setToastMessage('Крайний срок изменен');
            setOpenModelDeadline(false)
            refreshTaskTable();
        } catch (e) {
            // Обработка ошибки
        }
    };

    const columns = [
        { key: 'id', title: "ИД" },
        { key: 'divisionName', title: 'Филиал' },
        { key: 'createdAt', title: "Дата создания", date: true },
        { key: 'name', title: "Имя" },
        { key: 'tariff_time', title: "Время в тарифе", type: 'number' },
        { key: 'cost_time', title: "Время вне тарифа", type: 'number' },
        { key: 'preliminaryCost', title: 'Стоимость', type: 'number' },
    ];

    return (
        <>
            <div style={{ margin: '10px' }}>
                Текущая дата: {currentDate.toLocaleString()}
            </div>
            <div style={{ margin: '10px' }}>
                <YearMonthSelector
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    onYearChange={setSelectedYear}
                    onMonthChange={setSelectedMonth}
                />
            </div>
            <AutocompleteInput
                apiUrl={`/firm`}
                name='name'
                placeholder="*Название компании"
                setId={setCompanyId}
                chekValue={companyId}
            />
            <div className="grid-view-container">
                {item.length > 0 && <ForBookeeping columns={columns} item={item} setSelectedRow={setSelectedRow} handleOpenView={handleOpenView} />}
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={openView} setActive={setOpenView}>
                <View
                    listener={listener}
                    apiUrl={"/application/table"}
                    selectedId={selectedRow}
                    detailsConfig={detailsConfig}
                >
                    <button onClick={() => { openUpdateDeadline() }}>Крайний срок</button>
                    <SmallMenu items={userItems} onSelectItem={handleUserSelect} placeHolder={'Передать заявку'} />
                    <button
                        onClick={() => { setModalActive(true) }}
                    >
                        Редактировать
                    </button>
                    <button onClick={() => { setStatistic(!statistic) }}>Статистика</button>
                    {statistic && <TaskFinishStatistic taskId={selectedRow} />}
                    {selectedRow && <Chat taskId={selectedRow} listener={listener} params={{ projectId: projectIdInput }}></Chat>}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '5px' }}>
                        <button style={{ margin: '5px' }} onClick={() => { handleFinishOpen() }}>Завершить</button>
                        <button style={{ margin: '5px' }} onClick={() => { resumeTask() }}>Возобновить</button>
                    </div>
                </View>
            </Modal>
            <Modal active={openModelDeadline} setActive={setOpenModelDeadline}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Изменить крайний срок {selectedRow}</p>

                <p style={{ fontWeight: 'bold' }}>Введите комментарий</p>
                <div style={{ marginBottom: '10px' }}>
                    <input
                        type="text"
                        placeholder="Текст"
                        value={commentInput}
                        onChange={(e) => setCommentInput(e.target.value)}
                    />
                </div>
                <p style={{ fontWeight: 'bold' }}>Дата</p>
                <div style={{ marginBottom: '10px' }}>
                    <input
                        type='datetime-local'
                        name='newDeadline'
                        value={formatDate(newDeadline)}
                        onChange={handleDateChange}
                    />
                </div>
                <button onClick={handleUpdateDeadline}>Отправить</button>
            </Modal>
            <Modal active={isFinishModalOpen} setActive={setIsFinishModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Завершить заявку {selectedRow}</p>
                <p style={{ fontWeight: 'bold' }}>Введите комментарий</p>
                <input
                    style={{ marginBottom: "10px" }}
                    type="text"
                    placeholder="Текст"
                    value={commentInput}
                    onChange={(e) => setCommentInput(e.target.value)}
                />
                <p style={{ fontWeight: 'bold' }}>Введите время выполнения заявки в минутах</p>
                <input
                    style={{ marginBottom: "10px" }}
                    type="number"
                    min='0'
                    placeholder="15"
                    value={completion_time}
                    onChange={(e) => setCompletion_time(e.target.value)}
                />
                <button onClick={handleFinishSubmit}>Завершить</button>
            </Modal>
            <Modal active={modalActive} setActive={setModalActive}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Редактор заявки {selectedRow}</p>
                <p style={{ fontWeight: 'bold' }}>Отдел</p>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInput
                        apiUrl={`/depart`}
                        name='name'
                        placeholder="Выбрать"
                        setId={setProjectIdInput}
                        setName={setProjectName}
                        chekValue={projectIdInput}
                        validation={true}
                    />
                </div>
                {projectIdInput && <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Тип заявки</p>
                    <AutocompleteInput
                        key={projectIdInput}
                        apiUrl={`/tip`}
                        params={{ projectId: projectIdInput }}
                        name='name'
                        placeholder="Выбрать"
                        setId={setTipIdInput}
                        setName={setTipName}
                        chekValue={tipIdInput}
                        validation={true}
                    />
                </div>}
                {<div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Название компании</p>
                    <AutocompleteInput
                        apiUrl={`/firm`}
                        name='name'
                        placeholder="Выбрать"
                        setId={setFirmIdInput}
                        setName={setCompanyName}
                        chekValue={firmIdInput}
                        validation={!isChecked}
                    />
                </div>}
                {firmIdInput && <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Филиал</p>
                    <AutocompleteInput
                        key={firmIdInput}
                        apiUrl={`/firm/${firmIdInput}/division`}
                        name='adres'
                        placeholder="Выбрать"
                        setId={setDivisionId}
                        chekValue={divisionId}
                        validation={false}
                    />
                </div>}
                {divisionId && <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>ПК</p>
                    <AutocompleteInput
                        key={divisionId}
                        apiUrl={`/firm/${firmIdInput}/division/${divisionId}/pc`}
                        name='number'
                        placeholder="Выбрать"
                        setId={setPCId}
                        chekValue={PCId}
                        validation={false}
                    />
                </div>}
                {divisionId && <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>ОРГ</p>
                    <AutocompleteInput
                        key={divisionId}
                        apiUrl={`/firm/${firmIdInput}/division/${divisionId}/org`}
                        name='number'
                        placeholder="Выбрать"
                        setId={setORGId}
                        chekValue={ORGId}
                        validation={false}
                    />
                </div>}
                <p style={{ fontWeight: 'bold' }}>Название заявки(кратко)</p>
                <div style={{ marginBottom: "10px" }}>
                    <input
                        type="text"
                        value={nameInput}
                        placeholder="Текст"
                        onFocus={() => { setFocus(true) }}
                        onBlur={() => { setFocus(false) }}
                        style={{
                            borderColor: nameInput === '' ? 'red' : '', // Изменяем цвет рамки в случае ошибки валидации
                            boxShadow: isFocus && nameInput === '' ? '0 0 5px red' : '',

                        }}
                        maxLength={200}
                        onChange={(e) => setNameInput(e.target.value)} />
                    {nameInput.length > 199 && (
                        <p style={{
                            color: 'rgb(211, 0, 0)',
                            fontSize: '12px',
                            height: '15px',
                            margin: '0px',
                            visibility: (nameInput.length > 199) ? 'visible' : 'hidden',
                        }}>
                            Вы достигли ограничения по символам ({200})
                        </p>
                    )}
                    <div style={{ position: "relative" }}>
                        <p
                            style={{
                                color: 'rgb(211, 0, 0)',
                                fontSize: '12px',
                                height: '15px',
                                margin: '0px',
                                visibility: (nameInput !== '') ? 'hidden' : 'visible',
                            }}
                        >
                            Обязательное поле
                        </p>
                    </div>
                </div>
                <p style={{ fontWeight: 'bold' }}>Заявка (подробно)</p>
                <div style={{ marginTop: "10px" }}>
                    <input
                        type="text"
                        value={description}
                        placeholder="Текст"
                        maxLength={2000}
                        onChange={(e) => setDescription(e.target.value)} />
                </div>
                {description.length > 1999 && (
                    <p style={{
                        color: 'rgb(211, 0, 0)',
                        fontSize: '12px',
                        height: '15px',
                        margin: '0px',
                        visibility: (description.length > 1999) ? 'visible' : 'hidden',
                    }}>
                        Вы достигли ограничения по символам ({2000})
                    </p>
                )}
                <div style={{ marginTop: "20px" }}>
                    <p style={{ fontWeight: 'bold' }}>Приоритет</p>
                    <AutocompleteInput
                        apiUrl={`/priority`}
                        name='name'
                        placeholder="Выбрать"
                        setId={setPriorityIdInput}
                        setName={setPriorityName}
                        chekValue={priorityName}
                        validation={true}
                        id={setSelectedRow}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <button
                        className="button"
                        onClick={updateTask}
                    >
                        Отправить
                    </button>
                    <label className="checkbox-container">
                        <input
                            style={{ height: '30px', width: '30px' }}
                            type="checkbox"
                            className="checkbox-input"
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                        />
                        <span className="checkmark"></span>
                        <span style={{ fontSize: '18px' }}>Разовая</span>
                    </label>
                </div>

            </Modal>
        </>
    );
};

export default TaskTime;
