import React, { useState, useEffect } from 'react';
import PcAudit from '../components/audits/PcAudit';
import Company from '../components/audits/company';
import ServerAudit from '../components/audits/serverAudit';
import OrgAudit from '../components/audits/orgAudit';
import CashboxAudit from '../components/audits/cashBoxAudit';

const Audit = () => {
    // Состояние для хранения значения из localStorage
    const [config, setConfig] = useState(null);
    useEffect(() => {
        document.title = 'Аудит';
        console.log(config);
    }, [config]);
    // Условная отрисовка на основе значения из localStorage
    return (
        <div>
            {config === null ?
                <Company setRefresh={setConfig} /> :
                (config.type === 'server' ?
                    <ServerAudit setRefresh={setConfig} config={config} /> :
                    (config.type === 'pc' ?
                        <PcAudit setRefresh={setConfig} config={config} /> :
                        (config.type === 'org' ?
                            <OrgAudit setRefresh={setConfig} config={config} /> :
                            (config.type === 'cashbox' ?
                                <CashboxAudit setRefresh={setConfig} config={config} /> :
                                null)))
                )
            }

        </div>
    );
};

export default Audit;
