import React, { useEffect, useRef, useState } from 'react';
import ApiService from './ApiService';
import uvedomlenie from '../assets/audio/uvedomlenie.mp3';

const NotificationIcon = () => {
    const [showNotifications, setShowNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [unreadPersonalCount, setUnreadPersonalCount] = useState(0);
    const [unreadSystemCount, setUnreadSystemCount] = useState(0);
    const [prevNotificationsLength, setPrevNotificationsLength] = useState(0);
    const [initialLoad, setInitialLoad] = useState(true);
    const [activeTab, setActiveTab] = useState('personal');
    const notificationRef = useRef(null);

    useEffect(() => {
        const fetchNotifications = async () => {
            const data = await ApiService.fetchDataWithToken('/notification');
            setNotifications(data);
            setPrevNotificationsLength(data.length);
            setInitialLoad(false);
        };

        fetchNotifications();
    }, []);

    const handleNewNotification = (newNotification) => {
        setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
    };

    useEffect(() => {
        ApiService.connectWebSocket('/notification', handleNewNotification);
    }, []);

    useEffect(() => {
        const unreadPersonalNotifications = notifications.filter(notification => !notification.read && !notification.sistem);
        const unreadSystemNotifications = notifications.filter(notification => !notification.read && notification.sistem);

        setUnreadPersonalCount(unreadPersonalNotifications.length);
        setUnreadSystemCount(unreadSystemNotifications.length);

        if (!initialLoad && notifications.length > prevNotificationsLength) {
            playNotificationSound();
        }
        setPrevNotificationsLength(notifications.length);
    }, [notifications, initialLoad, prevNotificationsLength]);

    const playNotificationSound = () => {
        const audio = new Audio(uvedomlenie);
        audio.play().catch(error => {
            console.error('Failed to play audio:', error);
        });
    };

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const markAsRead = async (id) => {
        const body = { id: id };
        await ApiService.postDataWithToken('/notification/read', body);
        setNotifications(notifications.map(notification =>
            notification.id === id ? { ...notification, read: true } : notification
        ));
    };

    const deleteNotification = async (id) => {
        const body = { id: id };
        await ApiService.postDataWithToken('/notification/delete', body);
        setNotifications(notifications.filter(notification => notification.id !== id));
    };

    const linkifyText = (text) => {
        if (!text) return '';
        const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
    };

    const filteredNotifications = notifications.filter(notification =>
        activeTab === 'personal' ? !notification.sistem : notification.sistem
    );

    const sortedNotifications = filteredNotifications
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Сортировка по дате: новые сверху
        .sort((a, b) => a.read - b.read); // Сортировка: непрочитанные сверху

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setShowNotifications(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [notificationRef]);

    return (
        <div className="notification-icon-container" ref={notificationRef}>
            <div className={`notification-icon`} onClick={toggleNotifications}>
                {unreadPersonalCount > 0 && (
                    <div className="notification-count personal-count">
                        {unreadPersonalCount}
                    </div>
                )}
                <div className={`icon ${unreadPersonalCount + unreadSystemCount > 0 ? 'blink' : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="black">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path fill="#000000" d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm8-6v-2c0-3.22-1.98-5.67-5.04-6.86V9c0 1.1-.9 2-2 2h-2c-1.1 0-2-.9-2-2v-.86C5.98 8.33 4 10.78 4 14v2l-2 2v1h20v-1l-2-2zM6.23 6l1.47-1.47C9.05 3.54 10.47 3 12 3s2.95.54 4.3 1.53L17.77 6H6.23z" />
                    </svg>
                </div>
                {unreadSystemCount > 0 && (
                    <div className="notification-count system-count">
                        {unreadSystemCount}
                    </div>
                )}
            </div>
            <div className={`notification-list ${showNotifications ? 'open' : ''}`}>
                <div className="notification-tabs">
                    <button className={activeTab === 'personal' ? 'active' : ''} onClick={() => setActiveTab('personal')}>
                        Личные ({unreadPersonalCount})
                    </button>
                    <button className={activeTab === 'sistem' ? 'active' : ''} onClick={() => setActiveTab('sistem')}>
                        Системные ({unreadSystemCount})
                    </button>
                </div>
                <ul>
                    {sortedNotifications.length > 0 ? (
                        sortedNotifications
                            .map(notification => (
                                <li
                                    key={notification.id}
                                    className={`notification-item ${!notification.read ? 'unread' : 'read'}`}
                                    onClick={() => markAsRead(notification.id)}
                                >
                                    <div className="notification-content">
                                        <div className="notification-header">
                                            <a href={`/ktasks/${notification.taskId}`} className="task-id">
                                                {notification.taskId}
                                            </a>
                                            <span className="notification-date">
                                                {new Date(notification.createdAt).toLocaleString()} {/* Форматируем дату и время */}
                                            </span>
                                        </div>
                                        <span className="message" dangerouslySetInnerHTML={{ __html: linkifyText(notification.message) }} />
                                        <button
                                            className="delete-button"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                deleteNotification(notification.id);
                                            }}
                                        >
                                            &#x2715;
                                        </button>
                                    </div>
                                </li>
                            ))
                    ) : (
                        <p className="notification-item" style={{ padding: '15px' }}>Уведомления отсутствуют</p>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default NotificationIcon;
