import React, { useState, useEffect } from 'react';
import ApiService from './ApiService';

const CheckList = ({ apiUrl, taskId }) => {
    const [checkBoxes, setCheckBoxes] = useState([]);
    const [newCheckBox, setNewCheckBox] = useState('');
    const [checkedItems, setCheckedItems] = useState({});
    const [editingId, setEditingId] = useState(null);

    const fetchData = async () => {
        try {
            if (taskId) {
                const response = await ApiService.fetchDataWithToken(apiUrl + `/${taskId}`,);
                setCheckBoxes(response);
                const initialCheckedItems = {};
                response.forEach(cb => {
                    initialCheckedItems[cb.id] = cb.checked || false;
                });
                setCheckedItems(initialCheckedItems);
            }
        } catch (error) {
            console.error('Ошибка загрузки чекбоксов:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [taskId]);

    const handleAddCheckBox = async () => {
        const newCheckBoxObj = { name: newCheckBox, taskId: taskId };
        console.log(apiUrl)
        await ApiService.postDataWithToken(apiUrl + '/add', newCheckBoxObj)
        fetchData();
    };

    const handleDeleteCheckBox = async (id) => {
        await ApiService.postDataWithToken(apiUrl + '/delete', { id: id })
        fetchData();
    };

    const handleCheckBoxChange = async (id) => {
        const updatedChecked = !checkedItems[id];
        await ApiService.postDataWithToken(apiUrl + '/update', { id: id, checked: updatedChecked })
        fetchData()
    };

    const handleNameChange = async (id, value) => {
        await ApiService.postDataWithToken(apiUrl + '/update', { id: id, name: value })
        fetchData()
    };

    const handleBlur = (id) => {
        if (editingId === id) {
            setEditingId(null);
        }
    };

    return (
        <div className="checklist-container">
            <div style={{ display: 'flex', marginBottom: '15px' }}>
                <input
                    type="text"
                    value={newCheckBox}
                    onChange={(e) => setNewCheckBox(e.target.value)}
                    placeholder="Добавить пункт"
                    style={{ flex: 1, marginRight: '10px', padding: '8px' }}
                />
                <button onClick={handleAddCheckBox} className="add-button">
                    <i className="fas fa-plus-circle"></i>
                </button>
            </div>

            {checkBoxes.map(cb => (
                <div key={cb.id} className="checkbox-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0', borderBottom: '1px solid #e0e0e0' }}>
                    <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <input
                            type="checkbox"
                            className="checkbox-input"
                            checked={checkedItems[cb.id] || false}
                            onChange={() => handleCheckBoxChange(cb.id)}
                            style={{ height: '30px', width: '30px', marginRight: '10px' }}
                        />
                        {editingId === cb.id ? (
                            <>
                                <span className="checkmark"></span>
                                <input
                                    type="text"
                                    className='input-check'
                                    value={cb.name}
                                    onChange={(e) => handleNameChange(cb.id, e.target.value)}
                                    onBlur={() => handleBlur(cb.id)}
                                    autoFocus
                                    style={{ padding: '6px', width: '200px' }}
                                />
                            </>
                        ) : (
                            <>
                                <span className="checkmark"></span>
                                <span style={{ fontSize: '18px' }}>{cb.name}</span>
                            </>
                        )}
                    </label>
                    <div>
                        <i
                            className="fas fa-edit icon-button"
                            onClick={() => setEditingId(editingId === cb.id ? null : cb.id)}
                            style={{ marginRight: '15px', cursor: 'pointer' }}
                        />
                        <i
                            className="fas fa-trash-alt icon-button"
                            onClick={() => handleDeleteCheckBox(cb.id)}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </div>

            ))}
        </div>
    );
};

export default CheckList;
