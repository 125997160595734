import React, { useState, useEffect } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import Grid from '../../components/tables/Grid';
import View from '../../views/view';
import Modal from '../../components/Modal'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const ClientCompany = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { id } = useParams();
    const { companyId } = useParams(); // Получаем ID компании из URL
    const { divisionId } = useParams(); // Получаем ID филиала из URL

    const apiUrl = `/firm/${companyId}/client`;

    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const [clientData, setClientData] = useState({
        id: '',
        number: '',
        model: ''
    });

    const columns = [
        { key: 'id', title: 'ИД' },
        { key: 'divisionAdres', title: 'Филиал' },
        { key: 'fio', title: 'ФИО' },
        { key: 'position', title: 'Должность' },
        { key: 'phone', title: 'Телефон' },
    ];
    const detailsConfig = [
        { key: 'id', label: 'ИД' },
        { key: 'fio', label: 'ФИО' },
        { key: 'position', label: 'Должность' },
        { key: 'phone', label: 'Телефон' },
    ];

    useEffect(() => {
        document.title = 'Сотрудники';
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);

    useEffect(() => {
        if (id) setSelectedRow(id);
    }, [id]);

    const setSelectRow = (id) => {
        setSelectedRow(id);
        let currentPath = location.pathname;
        if (!currentPath.endsWith('/') && !currentPath.match(/\/\d+\/?$/)) {
            currentPath += '/';
        }
        // Получаем текущий путь и разделяем его по слешам
        let pathSegments = currentPath.split('/');

        // Убедимся, что у нас есть достаточно сегментов для изменения
        if (pathSegments.length > 1) {
            // Заменяем последний сегмент на новый id
            pathSegments[pathSegments.length - 1] = id;

            // Объединяем сегменты обратно в строку и заменяем текущий путь в истории
            navigate(pathSegments.join('/'), { replace: true });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClientData(prevData => ({ ...prevData, [name]: value }));
    };

    // Функция, вызывающая перерисовку компонента
    const refreshTable = () => {
        setListener(prevKey => prevKey + 1);
    };


    const openCreate = async () => {
        setClientData({
            id: "",
            firstName: "",
            lastName: "",
            middleName: "",
            phone: "",
        })
        setIsCreateModalOpen(true)
    }

    const openUpdate = async () => {
        const response = await ApiService.fetchDataWithToken(`/client/${selectedRow}`)
        setClientData({
            id: response[0].id,
            firstName: response[0].firstName,
            lastName: response[0].lastName,
            middleName: response[0].middleName,
            phone: response[0].phone,
        })
        setIsUpdateModalOpen(true)
    }

    const updateClient = async () => {
        try {
            await ApiService.postDataWithToken(`/client/update`, clientData);
            setToastType('success');
            setToastMessage('Сотрудник успешно изменен');
            refreshTable()
            setIsUpdateModalOpen(false)
        } catch (error) {
        }
    }

    const createClient = async () => {
        try {
            await ApiService.postDataWithToken(`/firm/${companyId}/division/${divisionId}/client/add`, clientData);
            setToastType('success');
            setToastMessage('Сотрудник успешно сохранен');
            refreshTable()
            setIsCreateModalOpen(false)
        } catch (error) {
        }
    }

    return (
        <div>
            <div className="grid-view-container">
                <Grid
                    listener={listener}
                    apiUrl={apiUrl}
                    columns={columns}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectRow}
                />
                <View listener={listener} selectedId={selectedRow} apiUrl={'/client/'} detailsConfig={detailsConfig}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                        
                    </div>
                </View>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isUpdateModalOpen} setActive={setIsUpdateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Редактор представиеля {selectedRow}</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="firstName" value={clientData.firstName} onChange={handleInputChange} placeholder="Фамилия" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="lastName" value={clientData.lastName} onChange={handleInputChange} placeholder="Имя" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="middleName" value={clientData.middleName} onChange={handleInputChange} placeholder="Отчество" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="phone" value={clientData.phone} onChange={handleInputChange} placeholder="Телефон" />
                </div>
                <button
                    className="button"
                    onClick={updateClient}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isCreateModalOpen} setActive={setIsCreateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Добавить представиеля</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="firstName" value={clientData.firstName} onChange={handleInputChange} placeholder="Фамилия" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="lastName" value={clientData.lastName} onChange={handleInputChange} placeholder="Имя" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="middleName" value={clientData.middleName} onChange={handleInputChange} placeholder="Отчество" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="phone" value={clientData.phone} onChange={handleInputChange} placeholder="Телефон" />
                </div>
                <button
                    className="button"
                    onClick={createClient}
                >
                    Отправить
                </button>
            </Modal>
        </div>
    );
};

export default ClientCompany