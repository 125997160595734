import React, { useRef } from 'react';

const FileUpload = ({ onFileSelectSuccess, onFileSelectError }) => {

    const fileInput = useRef(null);

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        if (file && file.size > 1024 * 1024 * 15) { // Ограничение размера файла до 5MB
            onFileSelectError("File size cannot exceed more than 15MB");
        } else {
            onFileSelectSuccess(file);
        }
    };

    return (
        <div>
            <input type="file" onChange={handleFileInput} ref={fileInput} style={{ display: 'none' }} />
            <button onClick={() => fileInput.current && fileInput.current.click()} className="btn-upload">
                Выбрать файл
            </button>
        </div>
    );
};

export default FileUpload;
