import React, { useState, useEffect } from 'react';
import ApiService from './ApiService';

const TaskFinishStatistic = ({ taskId }) => {
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(true);

    const initialColumns = [
        { key: 'userName', title: 'Пользователь' },
        { key: 'categoryCoefficient', title: 'Еноты' },
        { key: 'categoryName', title: 'Категория' },
        { key: 'timeSpent', title: 'Время (Минуты)' },
        { key: 'lessonCost', title: 'Урочная' },
        { key: 'extracurricularCost', title: 'Внеурочная' },
        { key: 'totalCost', title: 'Итоговая стоимость' },
    ];

    const [columnWidths, setColumnWidths] = useState(() => {
        const savedWidths = localStorage.getItem('columnWidths');
        return savedWidths ? JSON.parse(savedWidths) : {};
    });

    useEffect(() => {
        const fetchStatistics = async () => {
            try {
                const body = { id: taskId };
                const response = await ApiService.postDataWithToken(`/application/bookkeeping`, body);
                setStatistics(response);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchStatistics();
    }, [taskId]);

    const handleResizeStart = (e, columnKey) => {
        e.preventDefault();
        e.stopPropagation();

        const startX = e.clientX || e.touches[0].clientX;
        const columnHeader = e.target.parentElement;
        const startWidth = columnWidths[columnKey] || columnHeader.offsetWidth;

        const handleMouseMove = (moveEvent) => {
            const moveX = moveEvent.clientX || moveEvent.touches[0].clientX;
            const newWidth = Math.max(startWidth + moveX - startX, 50); // Минимальная ширина 50px

            columnHeader.style.width = `${newWidth}px`;
        };
        console.log(columnWidths)
        const handleMouseUp = () => {
            const newWidth = parseFloat(columnHeader.style.width);

            setColumnWidths(prevWidths => {
                const updatedWidths = { ...prevWidths, [columnKey]: newWidth };
                localStorage.setItem('columnWidths', JSON.stringify(updatedWidths));
                return updatedWidths;
            });

            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchmove', handleMouseMove);
        document.addEventListener('touchend', handleMouseUp);
    };

    const getColumnTitle = (key) => {
        const column = initialColumns.find(column => column.key === key);
        return column ? column.title : key;
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="statistics-container">
            <h2>Статистика по задачам</h2>
            <div className='statistics-table-wrapper'>
                <table className="statistics-table">
                    <thead>
                        <tr>
                            {initialColumns.map((column) => (
                                <th
                                    key={column.key}
                                    style={{
                                        width: columnWidths[column.key] || 'auto',
                                    }}
                                >
                                    {getColumnTitle(column.key)}
                                    <div
                                        className='resizer-area'
                                        onMouseDown={(e) => handleResizeStart(e, column.key)}
                                        onTouchStart={(e) => handleResizeStart(e, column.key)}
                                        
                                    />
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {statistics.map((item, index) => (
                            <tr key={index}>
                                {initialColumns.map((column) => (
                                    <td key={`${index}-${column.key}`}>
                                        {['lessonCost', 'extracurricularCost', 'totalCost'].includes(column.key)
                                            ? parseFloat(item[column.key]).toFixed(2)
                                            : item[column.key]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TaskFinishStatistic;
