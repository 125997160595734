import React, { useEffect, useState } from 'react';
import '../assets/styles/smallMenu.scss';

const SmallMenu = ({ items, onSelectItem, placeHolder, listener }) => {
    const [itemm, setItem] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        setItem(items);
    }, [items]);

    useEffect(() => {
            setSelectedValue('');
            onSelectItem('')
    }, [listener]);

    const handleSelectChange = (e) => {
        const selected = e.target.value;
        setSelectedValue(selected);
        onSelectItem(selected);
    };

    return (
        <select value={selectedValue || ''} onChange={handleSelectChange} className="select-wrapper">
            <option value="">{placeHolder}</option>
            {itemm && itemm.map(item => (
                <option key={item.id} value={item.id}>
                    {item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : item.name}
                </option>
            ))}
        </select>
    );
};

export default SmallMenu;
