import React, { useState, useEffect, useRef } from 'react';

const WorkLoadComponent = ({ apiUrl, workLoad, detailsConfig, setUrl }) => {
    const [visibleDropdown, setVisibleDropdown] = useState(null);
    const [selectedItems, setSelectedItems] = useState(JSON.parse(localStorage.getItem('selectedItems')) ?? {});
    const dropdownRefs = useRef([]);

    // Восстановление состояния при монтировании компонента
    useEffect(() => {
        updateUrl(selectedItems);
    }, []);

    // Сохранение состояния в localStorage
    useEffect(() => {
        try {
            localStorage.setItem('selectedItems', JSON.stringify(selectedItems));
        } catch (error) {
            console.error("Error saving to localStorage", error);
        }
    }, [selectedItems]);


    const toggleDropdown = (key) => {
        setVisibleDropdown(visibleDropdown === key ? null : key);
    };

    const selectItem = (key, item, column) => {
        const updatedSelectedItems = { ...selectedItems, [key]: { ...item, column } };
        console.log('Selecting item:', updatedSelectedItems); // Добавьте это
        setSelectedItems(updatedSelectedItems);
        updateUrl(updatedSelectedItems);
        setVisibleDropdown(null);
    };

    const clearSelection = (key, event) => {
        event.stopPropagation();
        const updatedSelectedItems = { ...selectedItems };
        delete updatedSelectedItems[key];
        console.log('Clearing selection:', updatedSelectedItems); // Добавьте это
        setSelectedItems(updatedSelectedItems);
        updateUrl(updatedSelectedItems);
    };


    const getItems = (key) => {
        return workLoad[key] || [];
    };

    const updateUrl = (items) => {
        const params = Object.values(items).map(item => `search=${item.id}&column=${item.column}`).join('&');
        setUrl(`${apiUrl}?${params}`);
    };


    const handleClickOutside = (event) => {
        if (!dropdownRefs.current.some(ref => ref && ref.contains(event.target))) {
            setVisibleDropdown(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='depart-workload'>
            {detailsConfig.map((config, index) => (
                <div key={config.key} className="dropdown" ref={el => (dropdownRefs.current[index] = el)}>
                    <button
                        onClick={() => toggleDropdown(config.key)}
                        className={`dropdown-button ${selectedItems[config.key] ? 'selected' : ''}`}
                    >
                        {selectedItems[config.key] ? `${selectedItems[config.key].name}: ${selectedItems[config.key].tasksCount}` : config.label}
                        {selectedItems[config.key] && (
                            <svg className="clear-selection" onClick={(event) => clearSelection(config.key, event)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                <path d="M18 6L12 12M12 12L6 18M12 12L18 18M12 12L6 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        )}
                    </button>
                    {visibleDropdown === config.key && (
                        <div className="dropdown-content" style={{ fontFamily: 'Roboto Mono, monospace' }}>
                            {getItems(config.key)
                                .sort((a, b) => a.name.localeCompare(b.name)) // Сортируем по имени
                                .map((item) => {
                                    const nameText = item.name;
                                    const tasksCountText = item.tasksCount;
                                    const combinedText = `${nameText}: ${tasksCountText}`;
                                    const spacesNeeded = Math.max(0, 25 - combinedText.length);
                                    const spaces = String.fromCharCode(160).repeat(spacesNeeded);
                                    return (
                                        <div
                                            key={item.id}
                                            className={`item ${selectedItems[config.key]?.id === item.id ? 'selected' : ''}`}
                                            onClick={() => selectItem(config.key, item, config.column)}
                                        >
                                            <p className="item-name">{nameText}:{spaces}{tasksCountText}</p>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default WorkLoadComponent;
