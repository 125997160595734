import React from 'react';

const YearMonthSelector = ({ selectedYear, selectedMonth, onYearChange, onMonthChange }) => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear - 9 + i);
    const monthNames = [
        'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
    ];

    return (
        <div className="year-month-selector">
            <select
                className="year-month-selector__select"
                value={selectedYear}
                onChange={(e) => onYearChange(parseInt(e.target.value))}
            >
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>

            <select
                className="year-month-selector__select"
                value={selectedMonth}
                onChange={(e) => onMonthChange(parseInt(e.target.value))}
            >
                {monthNames.map((month, index) => (
                    <option key={month} value={index }>
                        {month}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default YearMonthSelector;
