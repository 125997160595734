import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Approuter from "./components/Router";
import './assets/styles/all.scss'
import './assets/styles/grid-view.scss'
import CustomToast from "./components/CustomToast";
function App() {
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const portalRoot = document.getElementById('portal-root') || document.createElement('div');
  portalRoot.id = 'portal-root';
  document.body.appendChild(portalRoot);
  return (
    <BrowserRouter>
      <Approuter />
      <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
    </BrowserRouter>
  );
}

export default App;
