import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import Grid from '../../components/tables/Grid';
import View from '../../views/view';
import Modal from '../../components/Modal'
import AutocompleteInput from '../../components/autoCompliteInputs/FithoutCreate';

const UserAdmin = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { id } = useParams();

    const apiUrl = '/user/admin';

    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [roleIdInput, setRoleIdInput] = useState('');
    const [roleName, setRoleName] = useState('');

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const [userData, setUserData] = useState({
        newId: null,
        email: '',
        telegramStatus: '',
        emailStatus: '',
        firstName: '',
        lastName: '',
        middleName: '',
        roleName: '',
        roleId: null,
    });

    const columns = [
        { key: 'id', title: 'ИД' },
        { key: 'firstName', title: 'Фамилия' },
        { key: 'roleName', title: 'Роль' },
    ];
    const detailsConfig = [
        { key: 'id', label: 'ИД' },
        { key: 'email', label: 'Почта' },
        { key: 'emailStatus', label: 'Почта' },
        { key: 'telegramStatus', label: 'Телеграмм' },
        { key: 'lastName', label: 'Фамилия' },
        { key: 'firstName', label: 'Имя' },
        { key: 'middleName', label: 'Отчество' },
        { key: 'roleName', label: 'Роль' },
    ];

    useEffect(() => {
        document.title = 'Сотрудники';
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);


    useEffect(() => {
        if (id) setSelectedRow(id);
    }, [id]);

    const setSelectRow = (id) => {
        setSelectedRow(id);
        let currentPath = location.pathname;
        if (!currentPath.endsWith('/') && !currentPath.match(/\/\d+\/?$/)) {
            currentPath += '/';
        }
        // Получаем текущий путь и разделяем его по слешам
        let pathSegments = currentPath.split('/');
        // Убедимся, что у нас есть достаточно сегментов для изменения
        if (pathSegments.length > 1) {
            // Заменяем последний сегмент на новый id
            pathSegments[pathSegments.length - 1] = id;

            // Объединяем сегменты обратно в строку и заменяем текущий путь в истории
            navigate(pathSegments.join('/'), { replace: true });
        }
    };

    const openUpdate = async () => {
        const response = await ApiService.fetchDataWithToken(`/user/admin/${selectedRow}`)
        setUserData({
            newId: response[0].id,
            email: response[0].email || "",
            firstName: response[0].firstName || "",
            lastName: response[0].lastName || "",
            middleName: response[0].middleName || "",
            roleId: response[0].roleId || ""
        })
        console.log(response)
        setRoleIdInput(response[0].roleId || "")
        setRoleName(response[0].roleName || "")
        setIsUpdateModalOpen(true)
    }

    const openCreate = async () => {
        setUserData({
            id: "",
            email: "",
            firstName: "",
            lastName: "",
            middleName: "",
            roleId: "",
        })
        setRoleIdInput("")
        setRoleName("")
        setIsCreateModalOpen(true)
    }

    const updateUser = async () => {
        try {
            const updatedUserData = {
                ...userData, // Копируем все текущие данные
                roleId: roleIdInput, // Обновляем только 
            };
            console.log(updatedUserData)
            await ApiService.postDataWithToken(`/user/admin/update/${selectedRow}`, updatedUserData);
            setToastType('success');
            setToastMessage('Сотрудник успешно изменена');
            refreshTable();
            setIsUpdateModalOpen(false);
        } catch (error) {
        }
    }

    const createUser = async () => {
        try {
            const createUserData = {
                ...userData, // Копируем все текущие данные
                roleId: roleIdInput, // Обновляем только companytypesid
            };
            console.log(createUserData)
            await ApiService.postDataWithToken(`/admin/add`, createUserData);
            setToastType('success');
            setToastMessage('Сотрудник успешно сохранен');
            refreshTable()
            setIsCreateModalOpen(false)
        } catch (error) {
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevData => ({ ...prevData, [name]: value }));
    };

    // Функция, вызывающая перерисовку компонента
    const refreshTable = () => {
        setListener(prevKey => prevKey + 1);
    };
    return (
        <div>
            <div className="grid-view-container">
                <Grid
                    listener={listener}
                    apiUrl={apiUrl}
                    columns={columns}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectRow}
                />
                <View listener={listener} selectedId={selectedRow} apiUrl={apiUrl} detailsConfig={detailsConfig}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                        <button style={{ height: '45px' }}
                            className="task-table-button"
                            onClick={() => { openUpdate() }}
                        >
                            Редактировать
                        </button>
                    </div>
                </View>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isUpdateModalOpen} setActive={setIsUpdateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Редактор сотрудника {selectedRow}</p>
                <p style={{ fontWeight: 'bold' }}>ИД</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="newId" value={userData.newId} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Почта</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="email" value={userData.email} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Имя</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="email" name="lastName" value={userData.lastName} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Фамилия</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="firstName" value={userData.firstName} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Отчество</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="email" name="middleName" value={userData.middleName} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Роль</p>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInput
                        apiUrl={`/user/roles`}
                        name='name'
                        placeholder="*Выбрать"
                        setId={setRoleIdInput}
                        setName={setRoleName}
                        chekValue={roleIdInput}
                        validation={false}
                    />
                </div>
                <button
                    className="button"
                    onClick={updateUser}
                >
                    Отправить
                </button>
            </Modal>
        </div>
    );
};

export default UserAdmin;