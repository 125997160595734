import React, { useState, useEffect, useMemo } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import YearMonthSelector from '../../components/YearMonthSelector';

const CompanyCategory = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [tableMounth, setTableMounth] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // Месяц начинается с 0
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);

    const MounthStart = useMemo(() => {
        const MounthStart = new Date(selectedYear, selectedMonth, 1);
        MounthStart.setHours(0, 0, 0, 0);
        return MounthStart;
    }, [selectedYear, selectedMonth]);

    const MounthEnd = useMemo(() => {
        const MounthEnd = new Date(selectedYear, selectedMonth + 1, 0);
        MounthEnd.setHours(23, 59, 59, 999);
        return MounthEnd;
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        if (tableMounth.length === 0) return;

        setColumnWidths(prevWidths => {
            const updatedWidths = { ...prevWidths };
            const columnKeys = Object.keys(tableMounth[0]);

            columnKeys.forEach(key => {
                if (!updatedWidths[key] && key !== 'userId') {
                    updatedWidths[key] = 20; // Default width
                }
            });

            localStorage.setItem('columnWidths', JSON.stringify(updatedWidths));
            return updatedWidths;
        });
    }, [tableMounth]);


    useEffect(() => {
        document.title = 'Статистика';
        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        const reloadPage = setTimeout(() => {
            window.location.reload();
        }, 10 * 60 * 1000);

        return () => {
            clearInterval(intervalId);
            clearTimeout(reloadPage);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [mounthResponse] = await Promise.all([
                    ApiService.fetchDataWithToken('/kpi/companyCategory', { startDate: MounthStart, endDate: MounthEnd }),
                ]);
                setTableMounth(mounthResponse.companies);
            } catch (error) {
            }
        };
        fetchData();

    }, [MounthStart, MounthEnd]);


    const handleSort = (column) => {
        console.log('dfdf')
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            console.log('ddddd')
        } else {
            setSortColumn(column);
            setSortDirection('asc');
            console.log('aaaaa')
        }
    };

    /*const sortedData = tableMounth.sort((a, b) => {
        const aValue = parseFloat(a[sortColumn]);
        const bValue = parseFloat(b[sortColumn]);
        if (!isNaN(aValue) && !isNaN(bValue)) {
            if (sortDirection === 'asc') {
                return aValue - bValue;
            } else {
                return bValue - aValue;
            }
        } else {
            return 0; // Значения отсутствуют или не являются числами, нечего сравнивать
        }
    });*/
    const sortedData = tableMounth.sort((a, b) => {
        if (sortColumn === 'Сумма') {
            const aValue = Object.keys(a).reduce((acc, key) => {
                if (key !== 'userid') {
                    return acc + (parseFloat(a[key]) || 0);
                }
                return acc;
            }, 0);
            const bValue = Object.keys(b).reduce((acc, key) => {
                if (key !== 'userid') {
                    return acc + (parseFloat(b[key]) || 0);
                }
                return acc;
            }, 0);
            if (!isNaN(aValue) && !isNaN(bValue)) {
                return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
            } else {
                return 0;
            }
        } else {
            const aValue = parseFloat(a[sortColumn]);
            const bValue = parseFloat(b[sortColumn]);
            if (!isNaN(aValue) && !isNaN(bValue)) {
                return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
            } else {
                return 0;
            }
        }
    });
    const [columnWidths, setColumnWidths] = useState(() => {
        const savedWidths = localStorage.getItem('columnWidths');
        return savedWidths ? JSON.parse(savedWidths) : {};
    });

    const handleResizeStart = (e, columnKey) => {
        e.preventDefault();
        e.stopPropagation();

        const startX = e.type === 'mousedown' ? e.clientX : e.touches[0].clientX;
        const columnHeader = e.target.parentElement;
        const startWidth = columnWidths[columnKey] || columnHeader.offsetWidth;

        const handleMouseMove = (moveEvent) => {
            const moveX = moveEvent.type === 'mousemove' ? moveEvent.clientX : moveEvent.touches[0].clientX;
            const newWidth = Math.max(startWidth + moveX - startX, 50); // Минимальная ширина 50px

            columnHeader.style.width = `${newWidth}px`;
        };

        const handleMouseUp = () => {
            const newWidth = parseFloat(columnHeader.style.width);

            setColumnWidths(prevWidths => {
                const updatedWidths = { ...prevWidths, [columnKey]: newWidth };
                localStorage.setItem('columnWidths', JSON.stringify(updatedWidths));
                return updatedWidths;
            });

            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchmove', handleMouseMove);
        document.addEventListener('touchend', handleMouseUp);
    };

    return (
        <div>
            <div style={{ margin: '10px' }}>
                Текущая дата: {currentDate.toLocaleString()}
            </div>
            <div style={{ margin: '10px' }}>
                <YearMonthSelector
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    onYearChange={setSelectedYear}
                    onMonthChange={setSelectedMonth}
                />
            </div>
            <div className="kpi-tasks-table-container">
                <div className='table-wrapper'>
                    <table className="table">
                        <thead>
                            <tr>
                                {['Компания', 'totaltasks', 'TotalTimeHours', '1С (обновление настройка отчетов в', 'АТС', 'Видео', 'Выезд', 'Диагностика ПК и Оборудования', 'Доступы VPN 1С Сервер Почта', 'Другое', 'Интернет и сетевое оборудование', 'Монтаж', 'Оргтехника', 'Отдел Продаж', 'Подключение новых устройств (роут', 'Проектные работы', 'Работа с ПО БАЗОВОЕ', 'Работа с ПО ПРОФ', 'Торговое оборудование', 'Установка ОС БАЗ', 'Установка ОС ПРОФ', 'ЭЦП ЭДО ГосПорталы'].map((key, index) => (
                                    <th
                                        key={index}
                                        className={`kpi-table-header ${sortColumn === key ? 'selected-column' : ''}`}
                                        style={{ width: columnWidths[key] || 'auto' }}  // Apply column width
                                        onClick={() => handleSort(key)}
                                    >
                                        {key.toUpperCase()}
                                        <div className='resizer-area' onMouseDown={(e) => handleResizeStart(e, key)} onTouchStart={(e) => handleResizeStart(e, key)}>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((item, index) => (
                                <tr key={index}>
                                    <td className="kpi-table-cell">{item.companyname}</td>
                                    <td className="kpi-table-cell">{item['totaltasks']}</td>
                                    <td className="kpi-table-cell">{item['TotalTimeHours']}</td>
                                    <td className="kpi-table-cell">{item['1С (обновление настройка отчетов в']}</td>
                                    <td className="kpi-table-cell">{item['АТС']}</td>
                                    <td className="kpi-table-cell">{item['Видео']}</td>
                                    <td className="kpi-table-cell">{item['Выезд']}</td>
                                    <td className="kpi-table-cell">{item['Диагностика ПК и Оборудования']}</td>
                                    <td className="kpi-table-cell">{item['Доступы VPN 1С Сервер Почта']}</td>
                                    <td className="kpi-table-cell">{item['Другое']}</td>
                                    <td className="kpi-table-cell">{item['Интернет и сетевое оборудование']}</td>
                                    <td className="kpi-table-cell">{item['Монтаж']}</td>
                                    <td className="kpi-table-cell">{item['Оргтехника']}</td>
                                    <td className="kpi-table-cell">{item['Отдел Продаж']}</td>
                                    <td className="kpi-table-cell">{item['Подключение новых устройств (роут']}</td>
                                    <td className="kpi-table-cell">{item['Проектные работы']}</td>
                                    <td className="kpi-table-cell">{item['Работа с ПО БАЗОВОЕ']}</td>
                                    <td className="kpi-table-cell">{item['Работа с ПО ПРОФ']}</td>
                                    <td className="kpi-table-cell">{item['Торговое оборудование']}</td>
                                    <td className="kpi-table-cell">{item['Установка ОС БАЗ']}</td>
                                    <td className="kpi-table-cell">{item['Установка ОС ПРОФ']}</td>
                                    <td className="kpi-table-cell">{item['ЭЦП ЭДО ГосПорталы']}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
        </div>
    );

};

export default CompanyCategory;