import React, { useEffect, useState } from 'react';
import ApiService from '../ApiService';
import defaultAvatar from '../../assets/images/avatar.png'; // Замените на путь к вашему изображению аватара по умолчанию
import axios from 'axios';
const apiKey = process.env.REACT_APP_API_KEY;

const UserComponent = ({ user }) => {
    const [avatar, setAvatar] = useState(defaultAvatar);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        // Проверяем наличие аватара в localStorage при загрузке компонента
        const storedAvatar = localStorage.getItem('avatar');
        if (storedAvatar) {
            setAvatar(storedAvatar);
        } else {
            fetchAvatarFromServer(); // Если аватара нет в localStorage, пытаемся загрузить с сервера
        }
    }, []);

    const fetchAvatarFromServer = async () => {
        try {
            const users = await ApiService.fetchDataWithToken('/user/byToken');
            const body = {
                type: 'logo',
                userId: users.id,
            };
            const token = localStorage.getItem('token');
            console.log(token)
            const response = await axios.post(apiKey + '/user/download', body, {
                responseType: 'blob',
                headers: { Authorization: `Bearer ${token}` }
            });
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result;
                setAvatar(base64data);
                localStorage.setItem('avatar', base64data);
            };
            reader.readAsDataURL(response.data);
        } catch (error) {
            console.error('Ошибка при загрузке аватара с сервера:', error);
        }
    };


    if (!user) {
        return <div>Loading...</div>;
    }

    const handleAvatarChange = async (event) => {
        const file = event.target.files[0]; // получаем выбранный файл
        if (!file) return; // если файл не выбран, выходим из функции

        const formData = new FormData();
        formData.append('userId', user.id);
        formData.append('type', 'logo');
        formData.append('files', file);

        try {
            const response = await ApiService.postDataWithToken('/user/upload', formData);
            console.log('Ответ сервера:', response);
            localStorage.removeItem('avatar');
            window.location.reload();
            // Дополнительная логика по обработке ответа от сервера, если нужно
        } catch (error) {
            console.error('Ошибка при загрузке файла:', error);
            // Обработка ошибок, если нужно
        }
    };

    return (
        <div className="user-container-info">
            <div className="user-info">
                <div
                    className="avatar"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    <img src={avatar} alt="User Avatar" className="avatar-image" />
                    {hover && (
                        <div className="avatar-hover">
                            <label htmlFor="file-upload" className="custom-file-upload">
                                Загрузить фото
                            </label>
                            <input id="file-upload" type="file" onChange={handleAvatarChange} />
                        </div>
                    )}
                </div>
                <div>
                    <h2>Пользователь</h2>
                    <ul>
                        <li><strong>ID:</strong> {user.id}</li>
                        <li><strong>Email:</strong> {user.email}</li>
                        <li><strong>Фамилия:</strong> {user.firstName}</li>
                        <li><strong>Имя:</strong> {user.lastName}</li>
                        {user.role && <li><strong>Отдел:</strong> {user.role.name}</li>}
                        {user.company && <li><strong>Компания:</strong> {user.company.name}</li>}
                        {user.category && <li><strong>Тип:</strong> {user.category.name}</li>}
                    </ul>
                </div>
            </div>
            <div className="user-info">
                <div>
                    <h2>Статистика</h2>
                    <ul>
                        <li><strong>Заявок в работа:</strong> {user.inWork}</li>
                        <li><strong>Заявок на паузе:</strong> {user.onPause}</li>
                        <li><strong>Просроченных заявок:</strong> {user.deadline}</li>
                        <li><strong>Точные ЕНОТы до бонусов:</strong> {user.ENOTs}</li>
                        <li><strong>Среднее время выполнения заявки:</strong> {parseFloat(user.averageExecutionTime).toFixed(2)}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default UserComponent;
