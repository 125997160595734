import React, { useEffect, useState } from 'react';
import ApiService from '../ApiService';

const UserBonus = ({ startDate, endDate }) => {
    const [items, setItems] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [sum, setSum] = useState(0);
    const [ratio, setRatio] = useState(null);
    const [role, setIsRole] = useState(false);

    useEffect(() => {
        // Проверка роли при загрузке компонента
        const role = localStorage.getItem('role');
        setIsRole(role);
        fetchBonusFromServer(); // Загрузка данных при изменении startDate и endDate
    }, [startDate, endDate]);

    const fetchBonusFromServer = async () => {
        try {
            // Получаем данные бонусов
            const items = await ApiService.fetchDataWithToken('/sheet/bonus', { startDate, endDate });
            console.log(items);
            // Рассчитываем сумму бонусов
            let totalSum = 0;
            items.forEach(item => {
                totalSum += item.сумма;
            });
            setSum(totalSum);
            setItems(items);
            // Получаем данные наград
            const award = await ApiService.fetchDataWithToken('/sheet/award', { startDate, endDate });
            // Обрабатываем данные награды
            if (award && award.value !== null && award.value !== undefined) {
                setInputValue(award.value);
                if (totalSum > 0) {
                    setRatio(parseFloat(award.value) / totalSum);
                } else {
                    setRatio(0);
                }
            } else {
                // Если награды нет, очищаем значение и сбрасываем ratio
                setInputValue('');
                setRatio(0); // Можно сбросить ratio, если нет награды
            }
        } catch (error) {
            console.error('Ошибка при загрузке данных с сервера:', error);
        }
    };


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputBlur = async () => {
        try {
            await ApiService.postDataWithToken('/sheet/award/update', { startDate: startDate, endDate: endDate, value: inputValue });
            const totalSum = sum;
            if (totalSum > 0) {
                setRatio(parseFloat(inputValue) / totalSum);
            }
        } catch (error) {
        }
    };

    return (
        <div>
            {role == 'admin' ? (
                <input
                    type="number"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur} // Сохранение при выходе из инпута
                    placeholder="Введите число"
                />
            ) : (
                <input
                    type="number"
                    value={inputValue}
                    onBlur={handleInputBlur} // Сохранение при выходе из инпута
                    placeholder="Введите число"
                />
            )}
            <div className="summary">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Заявки</th>
                            <th>Заявка</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{sum}</td>
                            <td>{ratio !== null ? ratio.toFixed(2) : '-'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="main-table">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Сотрудник</th>
                            <th>Премия</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => (
                            <tr key={item.userId}>
                                <td>{item.сотрудник}</td>
                                <td>{ratio !== null ? (item.сумма * ratio).toFixed(2) : '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserBonus;
