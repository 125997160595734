import React, { useState, useEffect } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import Grid from '../../components/tables/Grid';
import View from '../../views/view';
import Modal from '../../components/Modal'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AutocompleteInput from '../../components/autoCompliteInputs/FithoutCreate';

const Server = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { companyId } = useParams(); // Получаем ID компании из URL
    const { divisionId } = useParams(); // Получаем ID филиала из URL
    const apiUrl = `/firm/${companyId}/division/${divisionId}/server`;

    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [header, setHeader] = useState('');
    const [ofdId, setOfdId] = useState('');

    const [cashboxData, setCashboxData] = useState({
        id: '',
        model: '',
        dateOfdEnd: '',
        dateFnEnd: '',
        numberFn: '',
        factoryNumber: '',
        RNM: '',
    });

    const columns = [
        { key: 'id', title: 'ИД' },
        { key: 'number', title: 'Номер' },
    ];
    const detailsConfig = [
        { key: 'id', label: 'ИД' },
        { key: 'number', label: 'Номер' },
    ];

    useEffect(() => {
        document.title = 'Кассы';
        setHead()
        ApiService.setToastHandlers(setToastMessage, setToastType);

    }, []);

    useEffect(() => {
        if (id) setSelectedRow(id);
    }, [id]);

    const setHead = async () => {
        const response = await ApiService.fetchDataWithToken(`${apiUrl}/header`)
        setHeader(response.message)
    }

    const setSelectRow = (id) => {
        setSelectedRow(id);
        let currentPath = location.pathname;
        if (!currentPath.endsWith('/') && !currentPath.match(/\/\d+\/?$/)) {
            currentPath += '/';
        }
        // Получаем текущий путь и разделяем его по слешам
        let pathSegments = currentPath.split('/');

        // Убедимся, что у нас есть достаточно сегментов для изменения
        if (pathSegments.length > 1) {
            // Заменяем последний сегмент на новый id
            pathSegments[pathSegments.length - 1] = id;

            // Объединяем сегменты обратно в строку и заменяем текущий путь в истории
            navigate(pathSegments.join('/'), { replace: true });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCashboxData(prevData => ({ ...prevData, [name]: value }));
    };

    // Функция, вызывающая перерисовку компонента
    const refreshTable = () => {
        setListener(prevKey => prevKey + 1);
    };


    const openCreate = async () => {
        setCashboxData({
            id: '',
            number: '',
        })
        setIsCreateModalOpen(true)
    }

    const openUpdate = async () => {
        const response = await ApiService.fetchDataWithToken(`/server/${selectedRow}`)
        setCashboxData({
            id: response[0].id,
            number: response[0].number,
        })
        setIsUpdateModalOpen(true)
    }

    const updateCashbox = async () => {
        try {
            const updateCashBox = {
                ...cashboxData, // Копируем все текущие данные
                companyId: companyId,
                divisionId: divisionId
            };
            await ApiService.postDataWithToken(`/server/update`, updateCashBox);
            setToastType('success');
            setToastMessage('Сервер успешно изменен');
            refreshTable()
            setIsUpdateModalOpen(false)
        } catch (error) {
        }
    }

    const createCashbox = async () => {
        try {
            const createCashBox = {
                ...cashboxData, // Копируем все текущие данные
                companyId: companyId,
                divisionId: divisionId,
            };
            await ApiService.postDataWithToken(`/server/update`, createCashBox);
            setToastType('success');
            setToastMessage('Сервер успешно сохранен');
            refreshTable()
            setIsCreateModalOpen(false)
        } catch (error) {
        }
    }
    const deleteCashbox = async () => {
        try {
            await ApiService.postDataWithToken(`/server/delete`, { id: selectedRow });
            setToastType('success');
            refreshTable()
            setIsDeleteModalOpen(false)
            setToastMessage('Сервер успешно удален');
        } catch (error) {
        }
    }

    return (
        <div>
            <div class="header">
                <p>{header}</p>
            </div>
            <div className="grid-view-container">
                <Grid
                    listener={listener}
                    apiUrl={apiUrl}
                    columns={columns}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectRow}
                />
                <View listener={listener} selectedId={selectedRow} apiUrl={`/server`} detailsConfig={detailsConfig}>
                <button
                        className="task-table-button"
                        onClick={() => { openCreate() }}
                        alwaysVisible
                    >
                        Создать
                    </button>
                    <button
                        className="task-table-button"
                        onClick={() => { openUpdate() }}
                    >
                        Редактировать
                    </button>
                    <button
                        className="task-table-button"
                        onClick={() => { setIsDeleteModalOpen(true) }}
                    >
                        Удалить
                    </button>
                </View>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isUpdateModalOpen} setActive={setIsUpdateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Редактор сервера {cashboxData.model}</p>
                <p style={{ fontWeight: 'bold' }}>Номер</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="number" value={cashboxData.number} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <button
                    className="button"
                    onClick={updateCashbox}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isCreateModalOpen} setActive={setIsCreateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Создание сервера</p>
                <p style={{ fontWeight: 'bold' }}>Номер</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="number" value={cashboxData.number} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <button
                    className="button"
                    onClick={createCashbox}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isDeleteModalOpen} setActive={setIsDeleteModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}> Вы уверены что хотите удалить сервер {selectedRow}</p>
                <button
                    className="button"
                    onClick={deleteCashbox}
                >
                    Да, я уверен(а)
                </button>
            </Modal>
        </div>
    );
};

export default Server