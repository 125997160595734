import React, { useState, useEffect } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import Grid from '../../components/tables/Grid';
import View from '../../views/view';
import Modal from '../../components/Modal'
import { useLocation, useNavigate } from 'react-router-dom';

const Access = () => {
    const navigate = useNavigate();


    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const [name, setName] = useState({});
    const [link, setLink] = useState({});

    const columns = [
        { key: 'id', title: 'ИД' },
        { key: 'name', title: 'Название' },
        { key: 'link', title: 'Ссылка' },
    ];
    const detailsConfig = [
        { key: 'id', label: 'ИД' },
        { key: 'name', label: 'Название' },
        { key: 'link', label: 'ссылка' },
    ];

    useEffect(() => {
        document.title = 'Доступы';
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);

    const location = useLocation();
    const setSelectRow = (id) => {
        setSelectedRow(id);
        let currentPath = location.pathname;
        if (!currentPath.endsWith('/') && !currentPath.match(/\/\d+\/?$/)) {
            currentPath += '/';
        }
        // Получаем текущий путь и разделяем его по слешам
        let pathSegments = currentPath.split('/');

        // Убедимся, что у нас есть достаточно сегментов для изменения
        if (pathSegments.length > 1) {
            // Заменяем последний сегмент на новый id
            pathSegments[pathSegments.length - 1] = id;

            // Объединяем сегменты обратно в строку и заменяем текущий путь в истории
            navigate(pathSegments.join('/'), { replace: true });
        }
    };

    // Функция, вызывающая перерисовку компонента
    const refreshTable = () => {
        setListener(prevKey => prevKey + 1);
    };


    const openCreate = async () => {
        setName('');
        setLink('');
        setIsCreateModalOpen(true)
    }

    const openUpdate = async () => {
        const response = await ApiService.fetchDataWithToken(`/access/${selectedRow}`)
        setName(response.name);
        setLink(response.link);
        setIsUpdateModalOpen(true)
    }

    const update = async () => {
        try {
            const body = {
                name: name,
                link: link,
            }
            await ApiService.postDataWithToken(`/access/update`, body);
            setToastType('success');
            setToastMessage('Сотрудник успешно изменен');
            refreshTable()
            setIsUpdateModalOpen(false)
        } catch (error) {
        }
    }

    const create = async () => {
        try {
            const body = {
                name: name,
                link: link,
            }
            await ApiService.postDataWithToken(`/access/add`, body);
            setToastType('success');
            setToastMessage('Сотрудник успешно сохранен');
            refreshTable()
            setIsCreateModalOpen(false)
        } catch (error) {
        }
    }

    return (
        <div>
            <div className="grid-view-container">
                <Grid
                    listener={listener}
                    apiUrl={`/access`}
                    columns={columns}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectRow}
                />
                <View listener={listener} selectedId={selectedRow} apiUrl={'/access'} detailsConfig={detailsConfig}>
                    <button
                        className="task-table-button"
                        onClick={() => { openCreate() }}
                        alwaysVisible
                    >
                        Создать
                    </button>
                    <button
                        className="task-table-button"
                        onClick={() => { openUpdate() }}
                    >
                        Обновить
                    </button>
                </View>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isUpdateModalOpen} setActive={setIsUpdateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Редактор доступа {selectedRow}</p>
                <p style={{ fontWeight: 'bold' }}>Доступ</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Ссылка</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="link" value={link} onChange={(e) => setLink(e.target.value)} placeholder="Текст" />
                </div>
                <button
                    className="button"
                    onClick={update}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isCreateModalOpen} setActive={setIsCreateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Добавить доступ</p>
                <p style={{ fontWeight: 'bold' }}>Доступ</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Ссылка</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="link" value={link} onChange={(e) => setLink(e.target.value)} placeholder="Текст" />
                </div>
                <button
                    className="button"
                    onClick={create}
                >
                    Отправить
                </button>
            </Modal>
        </div>
    );
};

export default Access