import React, { useState, useEffect } from 'react';
import CustomToast from '../../components/CustomToast';
import ApiService from '../../components/ApiService';
import AutocompleteInput from '../../components/autoCompliteInputs/AndCreate';
import Input from '../../components/input';
const CashboxAudit = ({ setRefresh, config }) => {

    const division = config.division;
    const company = config.company;
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [modal, setModal] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                await ApiService.setToastHandlers(setToastMessage, setToastType);
                const response = await ApiService.fetchDataWithToken(`/firm/${company.id}`)
                resetAllStates();
                setDisabled(false);
            } catch (error) {
            }
        };
        fetchData();
    }, []);

    const resetAllStates = () => {
        setModal('')
    };

    const handleButtonClick = async () => {
        setDisabled(true);
        const requestData = {
            model: modal,
            companyId: company.id,
            divisionId: division.id
        };
        try {
            await ApiService.postDataWithToken('/cashbox/create', requestData);
            setToastType('success');
            setToastMessage('Успешно');
            resetAllStates();
            setDisabled(false);
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
            setDisabled(false);
        }
    };

    return (
        <div>
            <div className="App">
                <header className="App-header">
                </header>
                <div class="header">
                    <p>Клиент {company.value}, филиал {division.value}</p>
                </div>
                <div className="headerTitle">Сервисный опрос клиента</div>
                <div className='form-container'>
                    <div className="headerContanier">Первичная информация</div>
                    <p style={{ fontWeight: 'bold' }}>Модель кассы</p>
                    <div style={{ marginBottom: "10px" }}>
                        <Input data={modal} setData={setModal} placeholder={'Модель'} />
                    </div>
                </div>
                <div className='form-container'>
                    <button
                        className="button"
                        disabled={disabled}
                        isLoading={false}
                        onClick={() => {
                            setRefresh(null)
                        }}
                    >
                        Перейти к общим сведеньям
                    </button>
                    <button
                        style={{ marginLeft: '10px' }}
                        className="button"
                        disabled={disabled}
                        isLoading={false}
                        onClick={() => {
                            handleButtonClick()
                        }}
                    >
                        Сохранить
                    </button>
                </div>
                <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            </div>
        </div>
    );
};
export default CashboxAudit;