import React from 'react';
import pdf from '../../assets/images/icons/PDF.png'
import dock from '../../assets/images/icons/DOC.png'
import xls from '../../assets/images/icons/XLS.png'
import pptx from '../../assets/images/icons/PPTX.png'
import jpg from '../../assets/images/icons/JPEG.png'
import def from '../../assets/images/icons/__SATORI.png'

const FileList = ({ data, setData, placeholder, idSuffix }) => {
    const handleChange = (e) => {
        const newFiles = Array.from(e.target.files);
        setData(prevImages => [...prevImages, ...newFiles].slice(0, 14));
    };

    const remove = (index) => {
        setData(currentImages => currentImages.filter((_, i) => i !== index));
    };

    const getFileIcon = (filename) => {
        if (!filename) {
            return def; // Используем иконку по умолчанию, если filename не определен
        }
        const extension = filename.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf':
                return pdf;
            case 'doc':
            case 'docx':
                return dock;
            case 'xls':
            case 'xlsx':
                return xls;
            // Добавьте другие расширения файлов и соответствующие иконки по мере необходимости
            default:
                return def; // Иконка по умолчанию для неизвестных файлов
        }
    };
    const inputId = `file-upload${idSuffix}`; // Уникальный ID для каждого input

    return (
        <>
            <input
                type="file"
                multiple
                accept="*/*"
                onChange={handleChange}
                style={{ display: 'none' }}
                id={inputId}
            />
            <label htmlFor={inputId} className="file-upload-label">
                {placeholder}
            </label>

            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                {data.map((file, index) => (
                    <div key={index} style={{ position: 'relative', margin: '5px', width: "100px", height: '130px' }}>
                        {file.type.startsWith('image/') ? (
                            <img src={URL.createObjectURL(file)} alt={`preview ${index}`} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                        ) : (
                            <div style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={getFileIcon(file.name)} alt={`icon ${index}`} style={{ width: '50%', height: '50%' }} />
                            </div>
                        )}
                        <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', background: 'rgba(0,0,0,0.5)', color: 'white', padding: '5px' }}>
                            {file.name}
                        </div>
                        <button
                            onClick={() => remove(index)}
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                padding: '2px 5px',
                                color: 'white',
                                background: 'red',
                                border: 'none',
                                cursor: 'pointer'
                            }}>
                            X
                        </button>
                    </div>
                ))}
            </div>
            </>
    );
};

export default FileList;
