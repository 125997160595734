import React, { useState, useEffect } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import Grid from '../../components/tables/Grid';
import AutocompleteInputFromObject from '../../components/autoCompliteInputs/fromObject';
import View from '../../views/view';
import Modal from '../../components/Modal'
import AutocompleteInput from '../../components/autoCompliteInputs/FithoutCreate';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Divizion = () => {
    const { companyId } = useParams(); // Получаем ID компании из URL
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [header, setHeader] = useState('');
    const [providerId, setProviderId] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const apiUrl = `/firm/${companyId}/division`;
    const [divisionData, setDivisionData] = useState({
        id: '',
        adres: '',
        phone: '',
        countPc: 0,
        countServer: 0,
    });
    const columns = [
        { key: 'number', title: 'Номер' },
        { key: 'phone', title: 'Телефон' },
        { key: 'adres', title: 'Адрес' },
    ];
    const detailsConfig = [
        { key: 'number', label: 'Номер' },
        { key: 'phone', label: 'Телефон' },
        { key: 'adres', label: 'Адрес' },
        { key: 'providerName', label: 'Провайдер' },
        { key: 'ATC', label: 'ATC' },
        { key: 'CCTV', label: 'CCTV' },
    ];
    const ATCoptions = [
        { value: "Присутствует облачная АТС" },
        { value: "Присутствует аналоговая АТС" },
        { value: "Отсутствует" },
        { value: "Не выяснили" }
    ];

    const CctvOptions = [
        { value: "Присутствует облачное видеонаблюдение" },
        { value: "Присутствует видеонаблюдение" },
        { value: "Отсутствует" },
        { value: "Не выяснили" }
    ];

    const [cctv, setCctv] = useState('');
    const [ATC, setATC] = useState('');

    useEffect(() => {
        document.title = 'Филиалы';
        ApiService.setToastHandlers(setToastMessage, setToastType);
        setHead()
    }, []);
    useEffect(() => {
        if (id) setSelectedRow(id);
    }, [id]);

    const setSelectRow = (id) => {
        setSelectedRow(id);
        let currentPath = location.pathname;
        if (!currentPath.endsWith('/') && !currentPath.match(/\/\d+\/?$/)) {
            currentPath += '/';
        }
        // Получаем текущий путь и разделяем его по слешам
        let pathSegments = currentPath.split('/');

        // Убедимся, что у нас есть достаточно сегментов для изменения
        if (pathSegments.length > 1) {
            // Заменяем последний сегмент на новый id
            pathSegments[pathSegments.length - 1] = id;

            // Объединяем сегменты обратно в строку и заменяем текущий путь в истории
            navigate(pathSegments.join('/'), { replace: true });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDivisionData(prevData => ({ ...prevData, [name]: value }));
    };

    // Функция, вызывающая перерисовку компонента
    const refreshTable = () => {
        setListener(prevKey => prevKey + 1);
    };

    const setHead = async () => {
        const response = await ApiService.fetchDataWithToken(`/firm/${companyId}/division/header`)
        setHeader(response.message)
    }

    const openCreate = async () => {
        setDivisionData({
            id: "",
            number: "",
            adres: "",
            phone: "",
            countPc: 0,
            countServer: 0,
        })
        setATC('')
        setCctv('')
        setProviderId('')
        setIsCreateModalOpen(true)
    }

    const openUpdate = async () => {
        const response = await ApiService.fetchDataWithToken(`/firm/${companyId}/division/${selectedRow}`)
        setDivisionData({
            id: response[0].id || '',
            number: response[0].number || '',
            adres: response[0].adres || '',
            phone: response[0].phone || '',
            countPc: response[0].countPc || 0,
            countServer: response[0].countServer || 0,
        })
        setATC(response[0].ATC || '')
        setCctv(response[0].CCTV || '')
        setProviderId(response[0].providerId || '')
        setIsUpdateModalOpen(true)
        console.log(providerId)
    }

    const updateDivision = async () => {
        try {
            const updatedDivisionData = {
                ...divisionData,
                providerId: providerId, // Здесь добавляем новые поля
                ATC: ATC,
                CCTV: cctv,
            };
            await ApiService.postDataWithToken(`/division/update`, updatedDivisionData);
            setToastType('success');
            setToastMessage('Филиал успешно изменен');
            refreshTable()
            setIsUpdateModalOpen(false)
        } catch (error) {
        }
    }
    const createDivision = async () => {
        try {
            const createDivisionData = {
                ...divisionData,
                providerId: providerId, // Здесь добавляем новые поля
                ATC: ATC,
                CCTV: cctv,
            };
            await ApiService.postDataWithToken(`/firm/${companyId}/division/add`, divisionData);
            setToastType('success');
            setToastMessage('Филиал успешно сохранен');
            refreshTable()
            setIsCreateModalOpen(false)
        } catch (error) {
        }
    }

    const downloadFlyFile = async () => {
        try {
            await ApiService.downloadFlyFile(`/firm/${companyId}/division/audit/${selectedRow}`);
        } catch (error) {

        }
    }

    return (
        <div>
            <div class="header">
                <p>{header}</p>
            </div>
            <div className="grid-view-container">
                <Grid
                    listener={listener}
                    apiUrl={apiUrl}
                    columns={columns}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectRow}
                />
                <View listener={listener} selectedId={selectedRow} apiUrl={apiUrl} detailsConfig={detailsConfig}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                        <button style={{ height: '45px' }}
                            className="task-table-button"
                            onClick={() => { openUpdate() }}
                        >
                            Редактировать
                        </button>
                    </div>
                    <button style={{ marginTop: '10px' }} onClick={() => { downloadFlyFile() }}>Аудит</button>
                    <div style={{ marginTop: "10px" }} alwaysVisible>
                        <button style={{ height: '45px' }}
                            className="task-table-button"
                            onClick={() => { openCreate() }}
                        >
                            Создать
                        </button>
                    </div>
                </View>
            </div>
            <div style={{ marginTop: "10px" }}>

                <button disabled={!selectedRow} style={{ margin: '5px' }} onClick={() => { navigate(`${location.pathname}/org/`) }}>
                    Оргтехника
                </button>
                <button disabled={!selectedRow} style={{ margin: '5px' }} onClick={() => { navigate(`${location.pathname}/networkEquipment/`) }}>
                    Сетевое оборудование
                </button>
                <button disabled={!selectedRow} style={{ margin: '5px' }} onClick={() => { navigate(`${location.pathname}/client/`) }}>
                    Представители
                </button>
                <button disabled={!selectedRow} style={{ margin: '5px' }} onClick={() => { navigate(`${location.pathname}/pc/`) }}>
                    ПК
                </button>
                <button disabled={!selectedRow} style={{ margin: '5px' }} onClick={() => { navigate(`${location.pathname}/cashbox/`) }}>
                    Кассы
                </button>
                <button style={{ margin: '5px' }} disabled={!selectedRow} onClick={() => { navigate(`${location.pathname}/server/`) }}>
                    Сервера
                </button>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isUpdateModalOpen} setActive={setIsUpdateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Редактор филиала {divisionData.number}</p>
                <p style={{ fontWeight: 'bold' }}>Номер филиала</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="number" min="0" value={divisionData.number} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Адрес</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="adres" value={divisionData.adres} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Телефон</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="phone" value={divisionData.phone} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>ATC</p>
                <AutocompleteInputFromObject
                    options={ATCoptions}
                    name="value"
                    placeholder="*Выбрать"
                    setData={setATC}
                    data={ATC}
                />
                <p style={{ fontWeight: 'bold' }}>Видеонаблюдение</p>
                <AutocompleteInputFromObject
                    options={CctvOptions}
                    name="value"
                    placeholder="*Выбрать"
                    setData={setCctv}
                    data={cctv}
                />
                <p style={{ fontWeight: 'bold' }}>Кол-во пк на филиале</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countPc" min="0" value={divisionData.countPc} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во серверов на филиале</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countServer" min="0" value={divisionData.countServer} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Провайдер</p>
                <AutocompleteInput
                    apiUrl={`/firm/provider`}
                    name="name"
                    description={'обязательное поле'}
                    placeholder="*Выбрать"
                    setId={setProviderId}
                    chekValue={providerId}
                    validation={false}
                />
                <button
                    className="button"
                    onClick={updateDivision}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isCreateModalOpen} setActive={setIsCreateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Создание филиала </p>
                <p style={{ fontWeight: 'bold' }}>Номер филиала</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="number" min="0" value={divisionData.number} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Адрес</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="adres" value={divisionData.adres} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Телефон</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="phone" value={divisionData.phone} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>ATC</p>
                <AutocompleteInputFromObject
                    options={ATCoptions}
                    name="value"
                    placeholder="*Выбрать"
                    setData={setATC}
                    data={ATC}
                />
                <p style={{ fontWeight: 'bold' }}>Видеонаблюдение</p>
                <AutocompleteInputFromObject
                    options={CctvOptions}
                    name="value"
                    placeholder="*Выбрать"
                    setData={setCctv}
                    data={cctv}
                />
                <p style={{ fontWeight: 'bold' }}>Кол-во пк на филиале</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countPc" min="0" value={divisionData.countPc} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Кол-во серверов на филиале</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="number" name="countServer" min="0" value={divisionData.countServer} onChange={handleInputChange} placeholder="0" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Провайдер</p>
                <AutocompleteInput
                    apiUrl={`/firm/provider`}
                    name="name"
                    description={'обязательное поле'}
                    placeholder="*Выбрать"
                    setId={setProviderId}
                    chekValue={providerId}
                    validation={false}
                />
                <button
                    className="button"
                    onClick={createDivision}
                >
                    Отправить
                </button>
            </Modal>
        </div>
    );
};

export default Divizion