import React, { useEffect, useCallback } from "react";
import close from '../assets/images/close.svg';

const SideModal = ({ active, setActive, children, loading = false }) => {
  const closeModal = () => {
    setActive(false);
  };

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  }, []);

  useEffect(() => {
    if (active) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [active, handleKeyDown]);

  return (
    <div
      className={active ? "side-modal active" : "side-modal"}
      onClick={closeModal}
    >
      <div
        className="side-modal__content"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
        <i
          onClick={closeModal}
          className="fas fa-times-circle side-modal__close"
          style={{ cursor: 'pointer', fontSize: '24px' }}
          aria-hidden="true"
        ></i>
        {loading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 4000,
            }}
          >
            <div className="spinner"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideModal;
