import React, { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import ApiService from '../../components/ApiService';
import View from '../../views/view';
import CustomToast from '../../components/CustomToast';
import ForBookeeping from '../../components/tables/forBookeeping';
import AutocompleteInput from '../../components/autoCompliteInputs/FithoutCreate';

const BufferTracking = () => {
    const newTask = '/bufer/'

    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [openIpdateAndConfirm, setOpenIpdateAndConfirm] = useState(false);
    const [openView, setOpenView] = useState(false); // Массив для хранения изображений
    const [tipIdInput, setTipIdInput] = useState(null);
    const [priorityIdInput, setPriorityIdInput] = useState(null);
    const [priorityName, setPriorityName] = useState(null);
    const [user, setUser] = useState(null);
    const [item, setItem] = useState([])

    const detailsConfig = [
        { key: 'id', label: "ИД" },
        { key: 'name', label: "Имя" },
        { key: 'categoryName', label: "Тип" },
        { key: 'priority', label: "Приоритет" },
    ];
    const confirmHandler = async () => {
        try {
            const body = {
                taskId: selectedRow
            }
            const response = await ApiService.postDataWithToken('/bufer/confirm', body)
            setSelectedRow(null)
            refreshTaskTable()
            setOpenView(false)
            setToastMessage('Успешно')
            setToastType('success')
        } catch (error) {
        }
    };
    const updateAndConfirm = async () => {
        try {
            const body = {
                taskId: selectedRow,
                categoryId:tipIdInput,
                priority:priorityName,
            }
            const response = await ApiService.postDataWithToken('/bufer/update', body)
            setSelectedRow(null)
            refreshTaskTable()
            setTipIdInput(null)
            setPriorityIdInput(null)
            setPriorityName('')
            setOpenView(false)
            setToastMessage('Успешно')
            setToastType('success')
        } catch (error) {
            setOpenView(false)
        }
    };
    // Функция, вызывающая перерисовку компонента
    const refreshTaskTable = () => {
        setListener(prevKey => prevKey + 1);
    };

    useEffect(() => {
        document.title = 'Заявки';
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);

    const columns = [
        { key: 'order_index', title: "ИД" },
        { key: 'createdAt', title: "Дата создания", date: true },
        { key: 'name', title: "Имя" },
    ];

    const handleOpenView = async (id) => {
        setSelectedRow(id);
        await handleGetItemData(id)
        setOpenView(true)
    };

    const handleGetItemData = async (id) => {
        const response = await ApiService.fetchDataWithToken('/bufer', { id: id });
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.fetchDataWithToken('/bufer/');
                setItem(response.items);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [listener]);
    const handleNewNotification = () => {
        refreshTaskTable()
    };

    useEffect(() => {
        ApiService.connectWebSocket('/bufer',handleNewNotification);
    }, []);

    return (
        <div className='container'>
            <div className="grid-view-container">
                {item.length > 0 && <ForBookeeping columns={columns} item={item} setSelectedRow={setSelectedRow} handleOpenView={handleOpenView} />}
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={openView} setActive={setOpenView}>
                <View
                    listener={listener}
                    apiUrl={"/bufer"}
                    selectedId={selectedRow}
                    detailsConfig={detailsConfig}
                    refreshTaskTable={refreshTaskTable}
                >
                    <div style={{ margin: '5px' }}><button onClick={() => { confirmHandler() }}>Подвердить</button></div>
                    <div style={{ margin: '5px' }}><button onClick={() => { setOpenIpdateAndConfirm(true) }}>Изменить</button></div>
                </View>
            </Modal>
            <Modal active={openIpdateAndConfirm} setActive={setOpenIpdateAndConfirm}>
                <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Редактор заявки {selectedRow}</p>
                <div>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInput
                            apiUrl={`/tip/all`}
                            name='name'
                            placeholder="Тип заявки"
                            setId={setTipIdInput}
                            chekValue={tipIdInput}
                            validation={true}
                        />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <AutocompleteInput
                            apiUrl={`/priority`}
                            name='name'
                            placeholder="*Приоритет"
                            setId={setPriorityIdInput}
                            chekValue={priorityName}
                            validation={true}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <button
                            className="button"
                            onClick={updateAndConfirm}
                        >
                            Подтвердить
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default BufferTracking;
