import React, { useState, useEffect } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import Grid from '../../components/tables/Grid';
import View from '../../views/view';
import Modal from '../../components/Modal'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FileUpload from '../../components/file/file';

const Client = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { id } = useParams();
    const { companyId } = useParams(); // Получаем ID компании из URL
    const { divisionId } = useParams(); // Получаем ID филиала из URL

    const apiUrl = `/firm/${companyId}/division/${divisionId}/client`;

    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [accessList, setAccessList] = useState([]); // Все элементы доступа
    const [approvedAccess, setApprovedAccess] = useState([]); // Утвержденные элементы доступа
    const [checkedItems, setCheckedItems] = useState({}); // Состояние для чекбоксов
    const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);
    const [header, setHeader] = useState('');

    const [clientData, setClientData] = useState({
        id: '',
        number: '',
        model: ''
    });

    const columns = [
        { key: 'id', title: 'ИД' },
        { key: 'fio', title: 'ФИО' },
        { key: 'position', title: 'Должность' },
        { key: 'phone', title: 'Телефон' },
    ];
    const detailsConfig = [
        { key: 'id', label: 'ИД' },
        { key: 'phone', label: 'Телефон' },
        { key: 'fio', label: 'Фио' },
        { key: 'position', label: 'Должность' },
    ];

    useEffect(() => {
        document.title = 'Сотрудники';
        ApiService.setToastHandlers(setToastMessage, setToastType);
        setHead()
    }, []);

    useEffect(() => {
        if (id) setSelectedRow(id);
    }, [id]);

    const setSelectRow = (id) => {
        setSelectedRow(id);
        let currentPath = location.pathname;
        if (!currentPath.endsWith('/') && !currentPath.match(/\/\d+\/?$/)) {
            currentPath += '/';
        }
        // Получаем текущий путь и разделяем его по слешам
        let pathSegments = currentPath.split('/');

        // Убедимся, что у нас есть достаточно сегментов для изменения
        if (pathSegments.length > 1) {
            // Заменяем последний сегмент на новый id
            pathSegments[pathSegments.length - 1] = id;

            // Объединяем сегменты обратно в строку и заменяем текущий путь в истории
            navigate(pathSegments.join('/'), { replace: true });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClientData(prevData => ({ ...prevData, [name]: value }));
    };

    // Функция, вызывающая перерисовку компонента
    const refreshTable = () => {
        setListener(prevKey => prevKey + 1);
    };

    const setHead = async () => {
        const response = await ApiService.fetchDataWithToken(`/firm/${companyId}/division/${divisionId}/client/header`)
        setHeader(response.message)
    }

    const openCreate = async () => {
        setClientData({
            id: "",
            firstName: "",
            lastName: "",
            middleName: "",
            phone: "",
        })
        setIsCreateModalOpen(true)
    }

    const openUpdate = async () => {
        const response = await ApiService.fetchDataWithToken(`/firm/${companyId}/division/${divisionId}/client/${selectedRow}`)
        setClientData({
            id: response[0].id,
            firstName: response[0].firstName,
            lastName: response[0].lastName,
            middleName: response[0].middleName,
            phone: response[0].phone,
        })
        setIsUpdateModalOpen(true)
    }

    const openAccess = async () => {
        try {
            const response = await ApiService.fetchDataWithToken(`/access`);
            const approved = await ApiService.fetchDataWithToken(`/access/client/${selectedRow}`);

            setAccessList(response.items); // Сохранение всех элементов доступа
            setApprovedAccess(approved.items); // Сохранение утвержденных элементов доступа

            // Создание состояния чекбоксов на основе совпадений
            const checkedState = response.items.reduce((acc, item) => {
                acc[item.id] = approved.items.some((approvedItem) => approvedItem.id === item.id);
                return acc;
            }, {});

            setCheckedItems(checkedState);
            setIsAccessModalOpen(true);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    };


    const updateClient = async () => {
        try {
            await ApiService.postDataWithToken(`/firm/${companyId}/division/${divisionId}/client/update`, clientData);
            setToastType('success');
            setToastMessage('Сотрудник успешно изменен');
            refreshTable()
            setIsUpdateModalOpen(false)
        } catch (error) {
        }
    }

    const createClient = async () => {
        try {
            await ApiService.postDataWithToken(`/firm/${companyId}/division/${divisionId}/client/add`, clientData);
            setToastType('success');
            setToastMessage('Сотрудник успешно сохранен');
            refreshTable()
            setIsCreateModalOpen(false)
        } catch (error) {
        }
    }
    const renderCheckboxes = () => {
        return accessList.map((item) => (
            <label key={item.id} className="checkbox-container">
                <input
                    style={{ height: '30px', width: '30px' }}
                    type="checkbox"
                    className="checkbox-input"
                    checked={checkedItems[item.id] || false}
                    onChange={() => handleCheckboxChange(item.id)}
                />
                <span className="checkmark"></span>
                <span style={{ fontSize: '18px' }}>{item.name}</span>
            </label>
        ));
    };
    const handleCheckboxChange = async (id) => {
        try {
            // Отправка запроса на сервер для переключения состояния
            const response = await ApiService.postDataWithToken(`/access/client/add`, { clientId: selectedRow, clientaccessId: id });

            // Обновление состояния чекбоксов в зависимости от ответа сервера
            setCheckedItems((prevState) => ({
                ...prevState,
                [id]: response.status === 'created', // Устанавливаем состояние в зависимости от ответа
            }));

            // Повторное получение данных после изменения
            const updatedAccessList = await ApiService.fetchDataWithToken(`/access`);
            setAccessList(updatedAccessList.items);
        } catch (error) {
            console.error('Ошибка при изменении доступа:', error);
        }
    };

    const handleFileSubmit = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            await ApiService.postDataWithToken(`/client/uploadFile/${selectedRow}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setToastMessage('Данные и файл успешно обновлены');
            setToastType('success');
            refreshTable()
            setIsUpdateModalOpen(false)
        } catch (error) {
            setToastMessage('Ошибка при отправке данных и файла');
            setToastType('error');
        }
    };

    return (
        <div>
            <div class="header">
                <p>{header}</p>
            </div>
            <div className="grid-view-container">
                <Grid
                    listener={listener}
                    apiUrl={apiUrl}
                    columns={columns}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectRow}
                />
                <View listener={listener} selectedId={selectedRow} apiUrl={apiUrl} detailsConfig={detailsConfig}>

                    <button
                        className="task-table-button"
                        onClick={() => { openUpdate() }}
                    >
                        Редактировать
                    </button>
                    <button
                        className="task-table-button"
                        onClick={() => { openAccess() }}
                    >
                        Доступы
                    </button>

                    <button
                        className="task-table-button"
                        onClick={() => { openCreate() }}
                    >
                        Создать
                    </button>
                    <FileUpload onFileSelectSuccess={handleFileSubmit} onFileSelectError={(error) => setToastMessage(error)} />
                </View>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isUpdateModalOpen} setActive={setIsUpdateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Редактор представиеля {selectedRow}</p>
                <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Фамилия</p>
                    <input type="text" name="firstName" value={clientData.firstName} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Имя</p>
                    <input type="text" name="lastName" value={clientData.lastName} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Отчество</p>
                    <input type="text" name="middleName" value={clientData.middleName} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Телефон</p>
                    <input type="text" name="phone" value={clientData.phone} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <button
                    className="button"
                    onClick={updateClient}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isCreateModalOpen} setActive={setIsCreateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Добавить представиеля</p>
                <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Фамилия</p>
                    <input type="text" name="firstName" value={clientData.firstName} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Имя</p>
                    <input type="text" name="lastName" value={clientData.lastName} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Отчество</p>
                    <input type="text" name="middleName" value={clientData.middleName} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Телефон</p>
                    <input type="text" name="phone" value={clientData.phone} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <button
                    className="button"
                    onClick={createClient}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isAccessModalOpen} setActive={setIsAccessModalOpen}>
                <div>
                    <h3>Настройка доступа</h3>
                    {renderCheckboxes()} {/* Рендер чекбоксов */}
                    <button onClick={() => setIsAccessModalOpen(false)}>Закрыть</button>
                </div>
            </Modal>


        </div>
    );
};

export default Client