import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ApiService from './components/ApiService';
import axios from 'axios';

const apiKey = process.env.REACT_APP_API_KEY;

function AppWithBackgroundImage() {
  const [backgroundImage, setBackgroundImage] = useState(undefined);

  useEffect(() => {
    const fetchBackgroundImage = async () => {
      try {
        const users = await ApiService.fetchDataWithToken('/user/byToken');
        const body = {
          type: 'backgroundImage',
          userId: users.id,
        };
        const token = localStorage.getItem('token');
        const response = await axios.post(apiKey + '/user/download', body, {
          responseType: 'blob',
          headers: { Authorization: `Bearer ${token}` }
        });
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          setBackgroundImage(base64data);
          localStorage.setItem('backgroundImage', base64data);
        };
        reader.readAsDataURL(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке фонового изображения с сервера:', error);
      }
    };

    fetchBackgroundImage();
  }, []);

  return (
    <div style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100%', // Высота на весь экран
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <div id="app-background" >
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </div>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWithBackgroundImage />);