import React, { useState, useEffect } from 'react';
import CustomToast from '../../components/CustomToast';
import ApiService from '../../components/ApiService';
import AutocompleteInput from '../../components/autoCompliteInputs/AndCreate';
import AutocompleteInputFromObject from '../../components/autoCompliteInputs/fromObject';
import Input from '../../components/input';
import FileList from '../file/fileList';
import CheckboxGroup from '../CheckboxGroup';
const PcAudit = ({ setRefresh, config }) => {
    const division = config.division;
    const company = config.company;

    const AccountsOptions = [
        { value: "Одна/несколько учетная запись (Права администратора)" },
        { value: "Администратор и пользователь" },
        { value: "Администратор и пользователь(Полные права)" },
        { value: "Доменная учетная запись пользователя" },
        { value: "Возникли сложности с получением информации" }
    ];

    const PasswordOptions = [
        { value: "Да, установлен пароль" },
        { value: "Нет, пароль отсутствует" },
        { value: "Возникли сложности с получением информации" }
    ];

    const DustOptions = [
        { value: "Пыли практически нет" },
        { value: "Присутствует пыль (обычная продувка)" },
        { value: "Присутствует пыль (требуется полный разбор)" },
        { value: "Ноутбук или моноблок (требуется чистка)" },
        { value: "Возникли сложности с получением информации" }
    ];
    const IbpOptions = [
        { value: "Отсутствует" },
        { value: "Присутствует (Работает)" },
        { value: "Присутствует (не держит, требуется замена батареи)" },
        { value: "Присутствует (не держит, требуется диагностика)" },
        { value: "Ноутбук (держит)" },
        { value: "Ноутбук (не держит)" },
        { value: "Возникли сложности с получением информации" },
    ];

    const DiskOptions = [
        { value: "HDD" },
        { value: "SSD" },
        { value: "M2" },
        { value: "HDD (мало места)" },
        { value: "SDD (мало места)" },
        { value: "M2 (мало места)" },
        { value: "Возникли сложности с получением информации" }
    ];

    const ShutdownOptions = [
        { value: "Отсутствуют" },
        { value: "до 10" },
        { value: "более 10" },
        { value: "Возникли сложности с получением информации" }
    ];

    const InternetOptions = [
        { value: "Отсутствуют" },
        { value: "Да, интернет тормозит" },
        { value: "Да, интернет тормозит и пропадает" },
        { value: "Возникли сложности с получением информации" }
    ];

    const TypeLVSOptions = [
        { value: "Кабель UTP" },
        { value: "WI-FI" },
        { value: "Внешний модем" },
        { value: "Возникли сложности с получением информации" }
    ];

    const CableManagmentOptions = [
        { value: "Не требуется" },
        { value: "Требуется (мелкие работы)" },
        { value: "Требуется (монтаж)" },
        { value: "Возникли сложности с получением информации" }
    ];

    const [clientPosition, setClientPosition] = useState('');
    const [clientNumber, setClientNumber] = useState('');
    const [pcNumber, setPcNumber] = useState('');
    const [accounts, setAccounts] = useState('');
    const [password, setPassword] = useState('');
    const [dust, setDust] = useState('');
    const [ibp, setIbp] = useState('');
    const [disk, setDisk] = useState('');
    const [internet, setInternet] = useState('');
    const [description, setDescription] = useState('');
    const [typeJVS, setTypeJVS] = useState('');
    const [cableManagment, setCableManagment] = useState('');
    const [shutdown, setShutdown] = useState('');
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [firstFiles, setFirstFiles] = useState([]); // Массив для хранения изображений
    const [secondFiles, setSecondFiles] = useState([]); // Массив для хранения изображений
    const [countPc, setCountPc] = useState(0);
    const [clientFio, setClientFio] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [pcId, setPcId] = useState(null);
    const [pcData, setPcData] = useState({
        id: null,
        value: "",
    });
    const [clientData, setClientData] = useState({
        id: null,
        value: "",
    });
    const [antivirus, setAntivirus] = useState({
        id: null,
        value: "",
    });
    const [serverType, setServerType] = useState({
        id: null,
        value: "",
    });
    const [appearance, setAppearance] = useState({
        fullSet: { label: 'Корпус в полной комплектации', checked: false },
        noSide: { label: 'Отсутствуют боковые крышки системного блока', checked: false },
        noFront: { label: 'Отсутствуют крышки на передней панели', checked: false },
        brokeUsb: { label: 'Не работают передние разъемы USB системного блока', checked: false },
        noInfo: { label: 'Возникли сложности с получением информации', checked: false }
    });
    const [monitor, setMonitor] = useState({
        stopOut: { label: 'Отсутствует', checked: false },
        flickers: { label: 'Присутствует - "Мерцает"', checked: false },
        blowOut: { label: 'Присутствует - "Гаснет"', checked: false },
        powerProblem: { label: 'Отходит провод питания', checked: false },
        VGAHDMIProblem: { label: 'Отходит провод (VGA,HDMI) требуется замена', checked: false },
        noInfo: { label: 'Возникли сложности с получением информации', checked: false }
    });

    const [keyboardMouse, setKeyboardMouse] = useState({
        noProblem: { label: 'Проблемы отсутствуют', checked: false },
        abrasions: { label: 'Стерты буквы на клавиатуре', checked: false },
        mouseProblem: { label: 'Проблемы с мышкой (не работает колесико, плозо работает)', checked: false },
        keyboardProblem: { label: 'Не работает клавиатура (чать клавишь или полностью)', checked: false },
        noInfo: { label: 'Возникли сложности с получением информации', checked: false }
    });

    const [noise, setNoise] = useState({
        noProblem: { label: 'Отсутствует', checked: false },
        noiseProcessor: { label: 'Шум кулера процессора', checked: false },
        noisePower: { label: 'Шум кулера блока питания', checked: false },
        noiseAppearance: { label: 'Шум корпуса(вибрация)', checked: false },
        laptopMonoblock: { label: 'Ноутбук или моноблок', checked: false },
        noInfo: { label: 'Возникли сложности с получением информации', checked: false }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await ApiService.setToastHandlers(setToastMessage, setToastType);
                const response = await ApiService.fetchDataWithToken(`/firm/${company.id}`)
                setCountPc(response[0].countPc);
                resetAllStates();
                setDisabled(false);
            } catch (error) {
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if(clientData.id){
                    setClientId(clientData.id)
                    const response = await ApiService.fetchDataWithToken(`/client/${clientData.id}`)
                    console.log(response[0]);
                    setClientPosition(response[0].position);
                    setClientNumber(response[0].number)
                    setDisabled(false);
                }
            } catch (error) {
            }
        };
        fetchData();
    }, [clientData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if(pcData.id){
                    setPcId(pcData.id)
                }
            } catch (error) {
            }
        };
        fetchData();
    }, [pcData]);

    const checkAtLeastOneSelected = (object, groupName) => {
        const noneChecked = Object.values(object).every(item => !item.checked);
        if (noneChecked) {
            setToastType('error');
            setToastMessage(`Выберите хотя бы 1 пункт в ${groupName}`);
            setDisabled(false);
            return false;
        }
        return true;
    };

    const resetAllStates = () => {
        // Сброс состояния клиента
        setClientData({ id: null, value: "" });
        setClientFio('');
        setClientId(null);
        setPcId(null);
        setClientPosition('');
        setClientNumber('');
        setPcNumber('');
        setAccounts('');
        setDescription('');
        setPassword('');
        setDust('');
        setIbp('');
        setDisk('');
        setInternet('');
        setTypeJVS('');
        setCableManagment('');
        setShutdown('');
        // Сброс изображений
        setFirstFiles([]);
        setSecondFiles([]);
        // Сброс состояния антивируса и типа сервера
        setAntivirus({ id: null, value: "" });
        setServerType({ id: null, value: "" });
        // Сброс состояния внешнего вида
        setAppearance({
            fullSet: { label: 'Корпус в полной комплектации', checked: false },
            noSide: { label: 'Отсутствуют боковые крышки системного блока', checked: false },
            noFront: { label: 'Отсутствуют крышки на передней панели', checked: false },
            brokeUsb: { label: 'Не работают передние разъемы USB системного блока', checked: false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: false }
        });
        // Сброс состояния монитора
        setMonitor({
            stopOut: { label: 'Отсутствует', checked: false },
            flickers: { label: 'Присутствует - "Мерцает"', checked: false },
            blowOut: { label: 'Присутствует - "Гаснет"', checked: false },
            powerProblem: { label: 'Отходит провод питания', checked: false },
            VGAHDMIProblem: { label: 'Отходит провод (VGA, HDMI) требуется замена', checked: false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: false }
        });
        // Сброс состояния клавиатуры и мыши
        setKeyboardMouse({
            noProblem: { label: 'Проблемы отсутствуют', checked: false },
            abrasions: { label: 'Стерты буквы на клавиатуре', checked: false },
            mouseProblem: { label: 'Проблемы с мышкой (не работает колесико, плозо работает)', checked: false },
            keyboardProblem: { label: 'Не работает клавиатура (часть клавиш или полностью)', checked: false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: false }
        });
        // Сброс состояния шума
        setNoise({
            noProblem: { label: 'Отсутствует', checked: false },
            noiseProcessor: { label: 'Шум кулера процессора', checked: false },
            noisePower: { label: 'Шум кулера блока питания', checked: false },
            noiseAppearance: { label: 'Шум корпуса (вибрация)', checked: false },
            laptopMonoblock: { label: 'Ноутбук или моноблок', checked: false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: false }
        });
    };


    const handleButtonClick = async () => {
        setDisabled(true);

        const groups = [
            { data: appearance, name: 'Внешний вид' },
            { data: monitor, name: 'Монитор' },
            { data: keyboardMouse, name: 'Клавиатура и мышь' },
            { data: noise, name: 'Шум' }
        ];

        for (let group of groups) {
            if (!checkAtLeastOneSelected(group.data, group.name)) {
                return; // Выход из функции, если найдена группа без выбранных элементов
            }
        }

        const formData = new FormData();
        formData.append('number', pcNumber);
        formData.append('clientId', clientId);
        formData.append('pcId', pcId);
        formData.append('clientFio', clientFio);
        formData.append('clientPosition', clientPosition);
        formData.append('clientNumber', clientNumber);
        formData.append('password', password);
        formData.append('account', accounts);
        formData.append('dust', dust);
        formData.append('ibp', ibp);
        formData.append('disk', disk);
        formData.append('antivirusId', antivirus.id);
        formData.append('shutDown', shutdown);
        formData.append('internet', internet);
        formData.append('lvs', typeJVS);
        formData.append('cableManagement', cableManagment);
        formData.append('FullSet', appearance.fullSet.checked);
        formData.append('NoSide', appearance.noSide.checked);
        formData.append('NoFront', appearance.noFront.checked);
        formData.append('BrokeUsb', appearance.brokeUsb.checked);
        formData.append('appearanceNoInfo', appearance.noInfo.checked);
        formData.append('StopOut', monitor.stopOut.checked);
        formData.append('Flickers', monitor.flickers.checked);
        formData.append('BlowOut', monitor.blowOut.checked);
        formData.append('description', description);
        formData.append('PowerProblem', monitor.powerProblem.checked);
        formData.append('VGAHDMIProblem', monitor.VGAHDMIProblem.checked);
        formData.append('monitorNoInfo', monitor.noInfo.checked);
        formData.append('keyboardMouseNoProblem', keyboardMouse.noProblem.checked);
        formData.append('abrasions', keyboardMouse.abrasions.checked);
        formData.append('mouseProblem', keyboardMouse.mouseProblem.checked);
        formData.append('keyboardProblem', keyboardMouse.keyboardProblem.checked);
        formData.append('keyboardMouseNoInfo', keyboardMouse.noInfo.checked);
        formData.append('noiseNoProblem', noise.noProblem.checked);
        formData.append('noiseProcessor', noise.noiseProcessor.checked);
        formData.append('noisePower', noise.noisePower.checked);
        formData.append('noiseAppearance', noise.noiseAppearance.checked);
        formData.append('laptopMonoblock', noise.laptopMonoblock.checked);
        formData.append('serverTypeId', serverType.id);
        formData.append('noInfo', noise.noInfo.checked);
        firstFiles.forEach(file => {
            formData.append('files', file);
            formData.append('descriptions', 'Внешнее состояние рабочего стола');
        });

        secondFiles.forEach(file => {
            formData.append('files', file);
            formData.append('descriptions', 'Состояние локальной сети рабочего места');
        });
        try {
            await ApiService.formDataWithToken(`/firm/${company.id}/division/${division.id}/pc/audit`, formData);
            setToastType('success');
            setToastMessage('Успешно');
            resetAllStates();
            setDisabled(false);
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
            setDisabled(false);
        }
    };


    return (
        <div>
            <div className="App">
                <header className="App-header">
                </header>
                <div class="header">
                    <p>Клиент {company.value}, филиал {division.value}</p>
                </div>
                <div className="headerTitle">Сервисный опрос клиента</div>
                <div className='form-container'>
                    <div className="headerContanier">Первичная информация</div>
                    <p style={{ fontWeight: 'bold' }}>ФИО</p>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInput apiUrl={`/firm/${company.id}/division/${division.id}/client`} name="fio" description={'Если поле горит красным сотрудник создастся'} placeholder="Выбрать/Текст" setData={setClientData} data={clientData} setName={setClientFio} validation={{ required: true }} />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Input data={clientPosition} setData={setClientPosition} placeholder={'Должность'} validation={{ required: true, message: 'Обязательное поле' }} />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Input data={clientNumber} setData={setClientNumber} placeholder={'Телефон'} />
                    </div>
                    <p style={{ fontWeight: 'bold' }}>Номер ПК</p>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInput apiUrl={`/firm/${company.id}/division/${division.id}/pc`} name="number" description={'Если поле горит красным пк создастся'} placeholder="Выбрать/Цифры" setData={setPcData} data={pcData} setName={setPcNumber} validation={{ required: true }} />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInput apiUrl="/serverType" name="name" description={'Обязательное поле'} placeholder="Тип сервера" setData={setServerType} data={serverType} validation={{ required: true }} />
                    </div>
                    <p style={{ fontWeight: 'bold' }}>Укажите жалобы клиента</p>
                    <div style={{ marginBottom: "10px" }}>
                        <Input data={description} setData={setDescription} placeholder={'Текст'} />
                    </div>
                </div>

                <div className='form-container'>
                    <div className="headerContanier">Чек-лист безопасность</div>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInput apiUrl="/antivirus" name="name" description={'Обязательное поле'} placeholder="Антивирус" setData={setAntivirus} data={antivirus} validation={true} />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInputFromObject options={AccountsOptions} name="value" placeholder="Учетная запись" setData={setAccounts} data={accounts} validation={true} description="Обязательное поле" />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInputFromObject options={PasswordOptions} name="value" placeholder="Стоит пароль на учетной записи?" setData={setPassword} data={password} validation={true} description="Обязательное поле" />
                    </div>
                </div>

                <div className='form-container'>
                    <div className="headerContanier">Внешнее состояние рабочего места</div>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInputFromObject options={DustOptions} name="value" placeholder="Уровень запыленности системного блока" setData={setDust} data={dust} validation={true} description="Обязательное поле" />
                    </div>
                    <div className="сontanierText">Шум</div>
                    <CheckboxGroup options={noise} setOptions={setNoise} specialKeys={['noProblem', 'laptopMonoblock', 'noInfo']} />
                    <div className="сontanierText">Внешний вид системного блока</div>
                    <CheckboxGroup options={appearance} setOptions={setAppearance} specialKeys={['noInfo']} />
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInputFromObject options={IbpOptions} name="value" placeholder="Наличие ИБП" setData={setIbp} data={ibp} validation={true} description="Обязательное поле" />
                    </div>
                    <FileList data={firstFiles} setData={setFirstFiles} placeholder={'Загрузить изображение'} idSuffix="1" />
                </div>

                <div className='form-container'>
                    <div className="headerContanier">Аппаратное и программное состояние рабочего места</div>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInputFromObject options={DiskOptions} name="value" placeholder="На каком типе диска установлена ОС?" setData={setDisk} data={disk} validation={true} description="Обязательное поле" />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInputFromObject options={ShutdownOptions} name="value" placeholder="Количество критических отключений ПК (в месяц)" setData={setShutdown} data={shutdown} validation={true} description="Обязательное поле" />
                    </div>
                    <div className="сontanierText">Есть ли проблемы с монитором?</div>
                    <CheckboxGroup options={monitor} setOptions={setMonitor} specialKeys={['stopOut', 'noInfo']} />
                    <div className="сontanierText">Состояние мышки и клавиатуры</div>
                    <CheckboxGroup options={keyboardMouse} setOptions={setKeyboardMouse} specialKeys={['noProblem', 'noInfo']} />
                </div>

                <div className='form-container'>
                    <div className="headerContanier">Состояние локальной сети рабочего места</div>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInputFromObject options={InternetOptions} name="value" placeholder="Есть ли проблемы при работе в интернете?" setData={setInternet} data={internet} validation={true} description="Обязательное поле" />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInputFromObject options={TypeLVSOptions} name="value" placeholder="Тип подключения к ЛВС" setData={setTypeJVS} data={typeJVS} validation={true} description="Обязательное поле" />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <AutocompleteInputFromObject options={CableManagmentOptions} name="value" placeholder="Требуется ли кабель-менеджмент рабочего места?" setData={setCableManagment} data={cableManagment} validation={true} description="Обязательное поле" />
                    </div>
                    <FileList data={secondFiles} setData={setSecondFiles} placeholder={'Загрузить изображение'} idSuffix="2" />
                </div>
                <div className='form-container'>
                    <button
                        className="button"
                        disabled={disabled}
                        isLoading={false}
                        onClick={() => {
                            setRefresh(null)
                        }}
                    >
                        Перейти к общим сведеньям
                    </button>
                    <button
                        style={{ marginLeft: '10px' }}
                        className="button"
                        disabled={disabled}
                        isLoading={false}
                        onClick={() => {
                            handleButtonClick()
                        }}
                    >
                        Сохранить
                    </button>
                </div>
                <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            </div>
        </div>
    );
};
export default PcAudit;