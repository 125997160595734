import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { adminRoute, guestRoutes } from "../utils/Routes";
import AdminHeader from "./headers/AdminHeader";
import ManagerHeader from "./headers/ManagerHeader";
import EmployeeHeader from "./headers/EmployeeHeader";
import NotFound from "../pages/notFound";
import SalesManagerHeader from "./headers/SalesManagerHeader";
import ChatNotifications from "./chatNotifications";

const Approuter = () => {
  const [user, setUser] = useState(localStorage.getItem("role"));
  const location = useLocation();

  useEffect(() => {
    setUser(localStorage.getItem("role"))
  }, [location.pathname]); // useEffect запустится при изменении location.pathname

  return (
    <Routes>
      {user === "admin" &&
        adminRoute.map(({ path, Component }) => (
          <Route key={path} path={path} element={<><ChatNotifications /><AdminHeader /><Component /></>} exact />
        ))}
      {(user === "manager") &&
        adminRoute.map(({ path, Component }) => (
          <Route key={path} path={path} element={<><ChatNotifications /><ManagerHeader /><Component /></>} exact />
        ))}
      {user === "employee" &&
        adminRoute.map(({ path, Component }) => (
          <Route key={path} path={path} element={<><ChatNotifications /><EmployeeHeader /><Component /></>} exact />
        ))}
      {(user === "sales manager"|| user === "bookkeeping") &&
        adminRoute.map(({ path, Component }) => (
          <Route key={path} path={path} element={<><ChatNotifications /><SalesManagerHeader /><Component /></>} exact />
        ))}
      {user === "service" &&
        adminRoute.map(({ path, Component }) => (
          <Route key={path} path={path} element={<><ChatNotifications /><Component /></>} exact />
        ))}
      {guestRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} element={<Component />} exact />
      ))}
      <Route path="*" element={user ? <NotFound /> : <Navigate to="/authorization" />} />
    </Routes>
  );
};

export default Approuter;
