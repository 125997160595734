import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import Grid from '../../components/tables/Grid';
import View from '../../views/view';
import Input from '../../components/input';
import FileUpload from '../../components/file/file';
import Modal from '../../components/Modal'
import AutocompleteInputFromObject from '../../components/autoCompliteInputs/fromObject';
import CheckboxGroup from '../../components/CheckboxGroup';
import AutocompleteInputAudit from '../../components/autoCompliteInputs/AndCreate';
import AutocompleteInput from '../../components/autoCompliteInputs/FithoutCreate';

const PCCompany = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { id } = useParams();
    const { companyId } = useParams(); // Получаем ID компании из URL

    const apiUrl = `/firm/${companyId}/pc`;
    const [disabled, setDisabled] = useState(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [divisionId, setDivisionId] = useState(null);
    const [clientPosition, setClientPosition] = useState('');
    const [clientNumber, setClientNumber] = useState('');
    const [clientFio, setClientFio] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [clientData, setClientData] = useState({
        id: null,
        value: "",
    });


    const AccountsOptions = [
        { value: "Одна/несколько учетная запись (Права администратора)" },
        { value: "Администратор и пользователь" },
        { value: "Администратор и пользователь(Полные права)" },
        { value: "Доменная учетная запись пользователя" },
        { value: "Возникли сложности с получением информации" }
    ];

    const PasswordOptions = [
        { value: "Да, установлен пароль" },
        { value: "Нет, пароль отсутствует" },
        { value: "Возникли сложности с получением информации" }
    ];

    const DustOptions = [
        { value: "Пыли практически нет" },
        { value: "Присутствует пыль (обычная продувка)" },
        { value: "Присутствует пыль (требуется полный разбор)" },
        { value: "Ноутбук или моноблок (требуется чистка)" },
        { value: "Возникли сложности с получением информации" }
    ];
    const IbpOptions = [
        { value: "Отсутствует" },
        { value: "Присутствует (Работает)" },
        { value: "Присутствует (не держит, требуется замена батареи)" },
        { value: "Присутствует (не держит, требуется диагностика)" },
        { value: "Возникли сложности с получением информации" }
    ];

    const DiskOptions = [
        { value: "HDD" },
        { value: "SSD" },
        { value: "M2" },
        { value: "HDD (мало места)" },
        { value: "SDD (мало места)" },
        { value: "M2 (мало места)" },
        { value: "Возникли сложности с получением информации" }
    ];

    const ShutdownOptions = [
        { value: "Отсутствуют" },
        { value: "до 10" },
        { value: "более 10" },
        { value: "Возникли сложности с получением информации" }
    ];

    const InternetOptions = [
        { value: "Отсутствуют" },
        { value: "Да, интернет тормозит" },
        { value: "Да, интернет тормозит и пропадает" },
        { value: "Возникли сложности с получением информации" }
    ];

    const TypeLVSOptions = [
        { value: "Кабель UTP" },
        { value: "WI-FI" },
        { value: "Внешний модем" },
        { value: "Возникли сложности с получением информации" }
    ];

    const CableManagmentOptions = [
        { value: "Не требуется" },
        { value: "Требуется (мелкие работы)" },
        { value: "Требуется (монтаж)" },
        { value: "Возникли сложности с получением информации" }
    ];

    const [pcNumber, setPcNumber] = useState('');
    const [accounts, setAccounts] = useState('');
    const [password, setPassword] = useState('');
    const [dust, setDust] = useState('');
    const [ibp, setIbp] = useState('');
    const [disk, setDisk] = useState('');
    const [internet, setInternet] = useState('');
    const [typeJVS, setTypeJVS] = useState('');
    const [cableManagment, setCableManagment] = useState('');
    const [shutdown, setShutdown] = useState('');

    const [antivirus, setAntivirus] = useState({
        id: null,
        value: "",
    });
    const [serverType, setServerType] = useState({
        id: null,
        value: "",
    });
    const [appearance, setAppearance] = useState({
        fullSet: { label: 'Корпус в полной комплектации', checked: false },
        noSide: { label: 'Отсутствуют боковые крышки системного блока', checked: false },
        noFront: { label: 'Отсутствуют крышки на передней панели', checked: false },
        brokeUsb: { label: 'Не работают передние разъемы USB системного блока', checked: false },
        noInfo: { label: 'Возникли сложности с получением информации', checked: false }
    });
    const [monitor, setMonitor] = useState({
        stopOut: { label: 'Отсутствует', checked: false },
        flickers: { label: 'Присутствует - "Мерцает"', checked: false },
        blowOut: { label: 'Присутствует - "Гаснет"', checked: false },
        powerProblem: { label: 'Отходит провод питания', checked: false },
        VGAHDMIProblem: { label: 'Отходит провод (VGA,HDMI) требуется замена', checked: false },
        noInfo: { label: 'Возникли сложности с получением информации', checked: false }
    });

    const [keyboardMouse, setKeyboardMouse] = useState({
        noProblem: { label: 'Проблемы отсутствуют', checked: false },
        abrasions: { label: 'Стерты буквы на клавиатуре', checked: false },
        mouseProblem: { label: 'Проблемы с мышкой (не работает колесико, плозо работает)', checked: false },
        keyboardProblem: { label: 'Не работает клавиатура (чать клавишь или полностью)', checked: false },
        noInfo: { label: 'Возникли сложности с получением информации', checked: false }
    });

    const [noise, setNoise] = useState({
        noProblem: { label: 'Отсутствует', checked: false },
        noiseProcessor: { label: 'Шум кулера процессора', checked: false },
        noisePower: { label: 'Шум кулера блока питания', checked: false },
        noiseAppearance: { label: 'Шум корпуса(вибрация)', checked: false },
        laptopMonoblock: { label: 'Ноутбук или моноблок', checked: false },
        noInfo: { label: 'Возникли сложности с получением информации', checked: false }
    });





    const [pcData, setPcData] = useState({
        id: '',
        number: '',
        server: false,
        serverTypeId: null,
    });

    const columns = [
        { key: 'id', title: 'ИД' },
        { key: 'divisionAdres', title: 'Филиал' },
        { key: 'number', title: 'Номер' },
    ];
    const detailsConfig = [
        { key: 'id', label: 'ИД' },
        { key: 'number', label: 'Номер' },
        { key: 'server', label: 'Сервер' },
        { key: 'serberType', label: 'Тип сервера' },
    ];

    useEffect(() => {
        document.title = 'ПК';
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);

    useEffect(() => {
        if (id) setSelectedRow(id);
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (clientData.id) {
                    setClientId(clientData.id)
                    const response = await ApiService.fetchDataWithToken(`/client/${clientData.id}`)
                    console.log(response[0]);
                    setClientPosition(response[0].position);
                    setClientNumber(response[0].phone)
                    setDisabled(false);
                }
            } catch (error) {
            }
        };
        fetchData();
    }, [clientData]);
    useEffect(() => {
        const fetchDivisionId = async () => {
            try {
                const response = await ApiService.fetchDataWithToken(`/pc/${selectedRow}`)
                const pcData = response[0];
                setDivisionId(pcData.divisionId || null);
            } catch (error) {
            }
        };
        fetchDivisionId();
    }, [selectedRow]);
    useEffect(() => {
        setClientPosition('');
        setClientNumber('')
        setClientData({
            id: null,
            value: "",
        });
    }, [divisionId]);

    const setSelectRow = (id) => {
        setSelectedRow(id);

        let currentPath = location.pathname;
        if (!currentPath.endsWith('/') && !currentPath.match(/\/\d+\/?$/)) {
            currentPath += '/';
        }
        // Получаем текущий путь и разделяем его по слешам
        let pathSegments = currentPath.split('/');
        // Убедимся, что у нас есть достаточно сегментов для изменения
        if (pathSegments.length > 1) {
            // Заменяем последний сегмент на новый id
            pathSegments[pathSegments.length - 1] = id;

            // Объединяем сегменты обратно в строку и заменяем текущий путь в истории
            navigate(pathSegments.join('/'), { replace: true });
        }
    };

    // Функция, вызывающая перерисовку компонента
    const refreshTable = () => {
        setListener(prevKey => prevKey + 1);
    };

    const openCreate = async () => {
        resetAllStates()
        setIsCreateModalOpen(true)
    }

    const resetAllStates = () => {
        // Сброс состояния клиента
        setPcNumber('');
        setAccounts('');
        setClientPosition('');
        setClientData({
            id: null,
            value: "",
        });
        setClientNumber('')
        setPassword('');
        setDust('');
        setIbp('');
        setDisk('');
        setInternet('');
        setTypeJVS('');
        setCableManagment('');
        setShutdown('');
        // Сброс состояния антивируса и типа сервера
        setAntivirus({ id: null, value: "" });
        setServerType({ id: null, value: "" });
        // Сброс состояния внешнего вида
        setAppearance({
            fullSet: { label: 'Корпус в полной комплектации', checked: false },
            noSide: { label: 'Отсутствуют боковые крышки системного блока', checked: false },
            noFront: { label: 'Отсутствуют крышки на передней панели', checked: false },
            brokeUsb: { label: 'Не работают передние разъемы USB системного блока', checked: false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: false }
        });
        // Сброс состояния монитора
        setMonitor({
            stopOut: { label: 'Отсутствует', checked: false },
            flickers: { label: 'Присутствует - "Мерцает"', checked: false },
            blowOut: { label: 'Присутствует - "Гаснет"', checked: false },
            powerProblem: { label: 'Отходит провод питания', checked: false },
            VGAHDMIProblem: { label: 'Отходит провод (VGA, HDMI) требуется замена', checked: false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: false }
        });
        // Сброс состояния клавиатуры и мыши
        setKeyboardMouse({
            noProblem: { label: 'Проблемы отсутствуют', checked: false },
            abrasions: { label: 'Стерты буквы на клавиатуре', checked: false },
            mouseProblem: { label: 'Проблемы с мышкой (не работает колесико, плозо работает)', checked: false },
            keyboardProblem: { label: 'Не работает клавиатура (часть клавиш или полностью)', checked: false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: false }
        });
        // Сброс состояния шума
        setNoise({
            noProblem: { label: 'Отсутствует', checked: false },
            noiseProcessor: { label: 'Шум кулера процессора', checked: false },
            noisePower: { label: 'Шум кулера блока питания', checked: false },
            noiseAppearance: { label: 'Шум корпуса (вибрация)', checked: false },
            laptopMonoblock: { label: 'Ноутбук или моноблок', checked: false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: false }
        });
    };

    const deletePc = async () => {
        try {
            await ApiService.postDataWithToken(`/pc/delete`, { id: selectedRow });
            setToastType('success');
            setToastMessage('ПК удален');
            refreshTable();
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error('Ошибка при удалении ПК:', error);
            setToastMessage('ООшибка при удалении ПК');
            setToastType('error');
        }
    }

    const openUpdate = async () => {
        const response = await ApiService.fetchDataWithToken(`/pc/${selectedRow}`)
        const pcData = response[0];

        setPcNumber(pcData.number || "");
        setAccounts(pcData.account || "");
        setPassword(pcData.password || "");
        setDust(pcData.dust || "");
        setIbp(pcData.ibp || "");
        setDisk(pcData.disk || "");
        setInternet(pcData.internet || "");
        setTypeJVS(pcData.lvs || "");
        setCableManagment(pcData.cableManagement || "");
        setShutdown(pcData.shutDown || "");

        setAntivirus({
            id: pcData.antivirusId || null,
            value: pcData.antivirusName || ""
        });

        setServerType({
            id: pcData.serverTypeId || null,
            value: pcData.serverType || ""
        });

        setAppearance({
            fullSet: { label: 'Корпус в полной комплектации', checked: pcData.FullSet || false },
            noSide: { label: 'Отсутствуют боковые крышки системного блока', checked: pcData.NoSide || false },
            noFront: { label: 'Отсутствуют крышки на передней панели', checked: pcData.NoFront || false },
            brokeUsb: { label: 'Не работают передние разъемы USB системного блока', checked: pcData.BrokeUsb || false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: pcData.appearanceNoInfo || false }
        });

        setMonitor({
            stopOut: { label: 'Отсутствует', checked: pcData.StopOut || false },
            flickers: { label: 'Присутствует - "Мерцает"', checked: pcData.Flickers || false },
            blowOut: { label: 'Присутствует - "Гаснет"', checked: pcData.BlowOut || false },
            powerProblem: { label: 'Отходит провод питания', checked: pcData.PowerProblem || false },
            VGAHDMIProblem: { label: 'Отходит провод (VGA, HDMI) требуется замена', checked: pcData.VGAHDMIProblem || false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: pcData.monitorNoInfo || false }
        });

        setKeyboardMouse({
            noProblem: { label: 'Проблемы отсутствуют', checked: pcData.keyboardMouseNoProblem || false },
            abrasions: { label: 'Стерты буквы на клавиатуре', checked: pcData.abrasions || false },
            mouseProblem: { label: 'Проблемы с мышкой (не работает колесико, плохо работает)', checked: pcData.mouseProblem || false },
            keyboardProblem: { label: 'Не работает клавиатура (часть клавиш или полностью)', checked: pcData.keyboardProblem || false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: pcData.keyboardMouseNoInfo || false }
        });

        setNoise({
            noProblem: { label: 'Отсутствует', checked: pcData.noiseNoProblem || false },
            noiseProcessor: { label: 'Шум кулера процессора', checked: pcData.noiseProcessor || false },
            noisePower: { label: 'Шум кулера блока питания', checked: pcData.noisePower || false },
            noiseAppearance: { label: 'Шум корпуса (вибрация)', checked: pcData.noiseAppearance || false },
            laptopMonoblock: { label: 'Ноутбук или моноблок', checked: pcData.laptopMonoblock || false },
            noInfo: { label: 'Возникли сложности с получением информации', checked: pcData.noInfo || false }
        });
        setIsUpdateModalOpen(true)
    }

    const updatePc = async () => {
        setDisabled(true);

        const groups = [
            { data: appearance, name: 'Внешний вид' },
            { data: monitor, name: 'Монитор' },
            { data: keyboardMouse, name: 'Клавиатура и мышь' },
            { data: noise, name: 'Шум' }
        ];

        for (let group of groups) {
            if (!checkAtLeastOneSelected(group.data, group.name)) {
                return; // Выход из функции, если найдена группа без выбранных элементов
            }
        }

        const formData = new FormData();
        formData.append('pcId', selectedRow);
        formData.append('number', pcNumber);
        formData.append('password', password);
        formData.append('account', accounts);
        formData.append('dust', dust);
        formData.append('ibp', ibp);
        formData.append('disk', disk);
        formData.append('antivirusId', antivirus.id);
        formData.append('shutDown', shutdown);
        formData.append('internet', internet);
        formData.append('lvs', typeJVS);
        formData.append('cableManagement', cableManagment);
        formData.append('FullSet', appearance.fullSet.checked);
        formData.append('NoSide', appearance.noSide.checked);
        formData.append('NoFront', appearance.noFront.checked);
        formData.append('BrokeUsb', appearance.brokeUsb.checked);
        formData.append('appearanceNoInfo', appearance.noInfo.checked);
        formData.append('StopOut', monitor.stopOut.checked);
        formData.append('Flickers', monitor.flickers.checked);
        formData.append('BlowOut', monitor.blowOut.checked);
        formData.append('PowerProblem', monitor.powerProblem.checked);
        formData.append('VGAHDMIProblem', monitor.VGAHDMIProblem.checked);
        formData.append('monitorNoInfo', monitor.noInfo.checked);
        formData.append('keyboardMouseNoProblem', keyboardMouse.noProblem.checked);
        formData.append('abrasions', keyboardMouse.abrasions.checked);
        formData.append('mouseProblem', keyboardMouse.mouseProblem.checked);
        formData.append('keyboardProblem', keyboardMouse.keyboardProblem.checked);
        formData.append('keyboardMouseNoInfo', keyboardMouse.noInfo.checked);
        formData.append('noiseNoProblem', noise.noProblem.checked);
        formData.append('noiseProcessor', noise.noiseProcessor.checked);
        formData.append('noisePower', noise.noisePower.checked);
        formData.append('noiseAppearance', noise.noiseAppearance.checked);
        formData.append('laptopMonoblock', noise.laptopMonoblock.checked);
        formData.append('serverTypeId', serverType.id);
        formData.append('noInfo', noise.noInfo.checked);
        try {
            await ApiService.formDataWithToken(`/pc/updateFull`, formData);
            setToastType('success');
            setToastMessage('Успешно');
            setDisabled(false);
            setIsUpdateModalOpen(false)
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
            setDisabled(false);
        }
    };

    const checkAtLeastOneSelected = (object, groupName) => {
        const noneChecked = Object.values(object).every(item => !item.checked);
        if (noneChecked) {
            setToastType('error');
            setToastMessage(`Выберите хотя бы 1 пункт в ${groupName}`);
            setDisabled(false);
            return false;
        }
        return true;
    };

    const createPc = async () => {
        setDisabled(true);

        const groups = [
            { data: appearance, name: 'Внешний вид' },
            { data: monitor, name: 'Монитор' },
            { data: keyboardMouse, name: 'Клавиатура и мышь' },
            { data: noise, name: 'Шум' }
        ];

        for (let group of groups) {
            if (!checkAtLeastOneSelected(group.data, group.name)) {
                return; // Выход из функции, если найдена группа без выбранных элементов
            }
        }

        const formData = new FormData();
        formData.append('clientId', clientId);
        formData.append('clientFio', clientFio);
        formData.append('clientPosition', clientPosition);
        formData.append('clientNumber', clientNumber);
        formData.append('divisionId', divisionId);
        formData.append('number', pcNumber);
        formData.append('password', password);
        formData.append('account', accounts);
        formData.append('dust', dust);
        formData.append('ibp', ibp);
        formData.append('disk', disk);
        formData.append('antivirusId', antivirus.id);
        formData.append('shutDown', shutdown);
        formData.append('internet', internet);
        formData.append('lvs', typeJVS);
        formData.append('cableManagement', cableManagment);
        formData.append('FullSet', appearance.fullSet.checked);
        formData.append('NoSide', appearance.noSide.checked);
        formData.append('NoFront', appearance.noFront.checked);
        formData.append('BrokeUsb', appearance.brokeUsb.checked);
        formData.append('appearanceNoInfo', appearance.noInfo.checked);
        formData.append('StopOut', monitor.stopOut.checked);
        formData.append('Flickers', monitor.flickers.checked);
        formData.append('BlowOut', monitor.blowOut.checked);
        formData.append('PowerProblem', monitor.powerProblem.checked);
        formData.append('VGAHDMIProblem', monitor.VGAHDMIProblem.checked);
        formData.append('monitorNoInfo', monitor.noInfo.checked);
        formData.append('keyboardMouseNoProblem', keyboardMouse.noProblem.checked);
        formData.append('abrasions', keyboardMouse.abrasions.checked);
        formData.append('mouseProblem', keyboardMouse.mouseProblem.checked);
        formData.append('keyboardProblem', keyboardMouse.keyboardProblem.checked);
        formData.append('keyboardMouseNoInfo', keyboardMouse.noInfo.checked);
        formData.append('noiseNoProblem', noise.noProblem.checked);
        formData.append('noiseProcessor', noise.noiseProcessor.checked);
        formData.append('noisePower', noise.noisePower.checked);
        formData.append('noiseAppearance', noise.noiseAppearance.checked);
        formData.append('laptopMonoblock', noise.laptopMonoblock.checked);
        formData.append('serverTypeId', serverType.id);
        formData.append('noInfo', noise.noInfo.checked);
        try {
            await ApiService.formDataWithToken(`/pc/updateFull`, formData);
            setToastType('success');
            setToastMessage('Успешно');
            setDisabled(false);
            refreshTable()
            setIsCreateModalOpen(false)
            resetAllStates();
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
            setDisabled(false);
        }
    }

    const handleFileSubmit = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            await ApiService.postDataWithToken(`/firm/${companyId}/division/${divisionId}/pc/uploadFile/${selectedRow}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setToastMessage('Данные и файл успешно обновлены');
            setToastType('success');
            refreshTable()
            setIsUpdateModalOpen(false)
        } catch (error) {
            setToastMessage('Ошибка при отправке данных и файла');
            setToastType('error');
        }
    };

    return (
        <div>
            <div className="grid-view-container">
                <Grid
                    listener={listener}
                    apiUrl={apiUrl}
                    columns={columns}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectRow}
                />
                <View listener={listener} selectedId={selectedRow} apiUrl={`/pc`} detailsConfig={detailsConfig}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                        <button style={{ height: '45px' }}
                            className="task-table-button"
                            onClick={() => { openUpdate() }}
                        >
                            Редактировать
                        </button>
                    </div>

                    <div style={{ marginTop: "10px" }} alwaysVisible>
                        <button style={{ height: '45px' }}
                            className="task-table-button"
                            onClick={() => { openCreate() }}
                        >
                            Создать
                        </button>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                        <button style={{ height: '45px' }}
                            className="task-table-button"
                            onClick={() => { setIsDeleteModalOpen(true) }}
                        >
                            Удалить
                        </button>
                    </div>
                    <div style={{ marginTop: "10px" }} >
                        <FileUpload onFileSelectSuccess={handleFileSubmit} onFileSelectError={(error) => setToastMessage(error)} />
                    </div>
                </View>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isUpdateModalOpen} setActive={setIsUpdateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Редактор пк {pcNumber}</p>
                <div style={{ marginTop: "10px" }}>
                    <AutocompleteInputAudit apiUrl="/serverType" name="name" description={'Обязательное поле'} placeholder="Тип сервера" setData={setServerType} data={serverType} validation={{ required: true }} />
                </div>
                <div className="headerContanier">Чек-лист безопасность</div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputAudit apiUrl="/antivirus" name="name" description={'Обязательное поле'} placeholder="Антивирус" setData={setAntivirus} data={antivirus} validation={true} />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={AccountsOptions} name="value" placeholder="Учетная запись" setData={setAccounts} data={accounts} validation={true} description="обязательное поле" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={PasswordOptions} name="value" placeholder="Стоит пароль на учетной записи?" setData={setPassword} data={password} validation={true} description="обязательное поле" />
                </div>

                <div className="headerContanier">Внешнее состояние рабочего места</div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={DustOptions} name="value" placeholder="Уровень запыленности системного блока" setData={setDust} data={dust} validation={true} description="обязательное поле" />
                </div>
                <div className="headerContanier">Шум</div>
                <CheckboxGroup options={noise} setOptions={setNoise} specialKeys={['noProblem', 'laptopMonoblock', 'noInfo']} />
                <div className="headerContanier">Внешний вид системного блока</div>
                <CheckboxGroup options={appearance} setOptions={setAppearance} specialKeys={['noInfo']} />
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={IbpOptions} name="value" placeholder="Наличие ИБП" setData={setIbp} data={ibp} validation={true} description="обязательное поле" />
                </div>

                <div className="headerContanier">Аппаратное и программное состояние рабочего места</div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={DiskOptions} name="value" placeholder="На каком типе диска установлена ОС?" setData={setDisk} data={disk} validation={true} description="обязательное поле" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={ShutdownOptions} name="value" placeholder="Количество критических отключений ПК (в месяц)" setData={setShutdown} data={shutdown} validation={true} description="обязательное поле" />
                </div>
                <div className="headerContanier">Есть ли проблемы с монитором?</div>
                <CheckboxGroup options={monitor} setOptions={setMonitor} specialKeys={['stopOut', 'noInfo']} />
                <div className="headerContanier">Состояние мышки и клавиатуры</div>
                <CheckboxGroup options={keyboardMouse} setOptions={setKeyboardMouse} specialKeys={['noProblem', 'noInfo']} />

                <div className="headerContanier">Состояние локальной сети рабочего места</div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={InternetOptions} name="value" placeholder="Есть ли проблемы при работе в интернете?" setData={setInternet} data={internet} validation={true} description="обязательное поле" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={TypeLVSOptions} name="value" placeholder="Тип подключения к ЛВС" setData={setTypeJVS} data={typeJVS} validation={true} description="обязательное поле" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={CableManagmentOptions} name="value" placeholder="Требуется ли кабель-менеджмент рабочего места?" setData={setCableManagment} data={cableManagment} validation={true} description="обязательное поле" />
                </div>

                <button
                    disabled={disabled}
                    className="button"
                    onClick={updatePc}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isCreateModalOpen} setActive={setIsCreateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Добавление ПК</p>
                <p style={{ fontWeight: 'bold' }}>Номер ПК</p>
                <div style={{ marginBottom: "10px" }}>
                    <Input apiUrl={`/firm/${companyId}/division/${divisionId}/pc`} name="number" description={'Если поле горит красным пк создастья'} placeholder="Цифры" setData={setPcNumber} data={pcNumber} validation={{ required: true }} />
                </div>
                <p style={{ fontWeight: 'bold' }}>Отдел</p>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInput
                        apiUrl={`/firm/${companyId}/division`}
                        name='adres'
                        placeholder="Выбрать"
                        setId={setDivisionId}
                        chekValue={divisionId}
                        validation={true}
                    />
                </div>
                <div className="headerContanier">Первичная информация</div>
                {divisionId && <p style={{ fontWeight: 'bold' }}>ФИО</p>}
                {divisionId && <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputAudit apiUrl={`/firm/${companyId}/division/${divisionId}/client`} name="fio" description={'Если поле горит красным сотрудник создастья'} placeholder="Выбрать/Текст" setData={setClientData} data={clientData} setName={setClientFio} validation={{ required: true }} />
                </div>}
                {divisionId && <div style={{ marginBottom: "10px" }}>
                    <Input data={clientPosition} setData={setClientPosition} placeholder={'Должность'} validation={{ required: true, message: 'Обязательное поле' }} />
                </div>}
                {divisionId && <div style={{ marginBottom: "10px" }}>
                    <Input data={clientNumber} setData={setClientNumber} placeholder={'Телефон'} />
                </div>}
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputAudit apiUrl="/serverType" name="name" description={'Обязательное поле'} placeholder="Тип сервера" setData={setServerType} data={serverType} validation={{ required: true }} />
                </div>

                <div className="headerContanier">Чек-лист безопасность</div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputAudit apiUrl="/antivirus" name="name" description={'Обязательное поле'} placeholder="Антивирус" setData={setAntivirus} data={antivirus} validation={true} />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={AccountsOptions} name="value" placeholder="Учетная запись" setData={setAccounts} data={accounts} validation={true} description="обязательное поле" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={PasswordOptions} name="value" placeholder="Стоит пароль на учетной записи?" setData={setPassword} data={password} validation={true} description="обязательное поле" />
                </div>



                <div className="headerContanier">Внешнее состояние рабочего места</div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={DustOptions} name="value" placeholder="Уровень запыленности системного блока" setData={setDust} data={dust} validation={true} description="обязательное поле" />
                </div>
                <div className="сontanierText">Шум</div>
                <CheckboxGroup options={noise} setOptions={setNoise} specialKeys={['noProblem', 'laptopMonoblock', 'noInfo']} />
                <div className="сontanierText">Внешний вид системного блока</div>
                <CheckboxGroup options={appearance} setOptions={setAppearance} specialKeys={['noInfo']} />
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={IbpOptions} name="value" placeholder="Наличие ИБП" setData={setIbp} data={ibp} validation={true} description="обязательное поле" />
                </div>



                <div className="headerContanier">Аппаратное и программное состояние рабочего места</div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={DiskOptions} name="value" placeholder="На каком типе диска установлена ОС?" setData={setDisk} data={disk} validation={true} description="обязательное поле" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={ShutdownOptions} name="value" placeholder="Количество критических отключений ПК (в месяц)" setData={setShutdown} data={shutdown} validation={true} description="обязательное поле" />
                </div>
                <div className="сontanierText">Есть ли проблемы с монитором?</div>
                <CheckboxGroup options={monitor} setOptions={setMonitor} specialKeys={['stopOut', 'noInfo']} />
                <div className="сontanierText">Состояние мышки и клавиатуры</div>
                <CheckboxGroup options={keyboardMouse} setOptions={setKeyboardMouse} specialKeys={['noProblem', 'noInfo']} />




                <div className="headerContanier">Состояние локальной сети рабочего места</div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={InternetOptions} name="value" placeholder="Есть ли проблемы при работе в интернете?" setData={setInternet} data={internet} validation={true} description="обязательное поле" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={TypeLVSOptions} name="value" placeholder="Тип подключения к ЛВС" setData={setTypeJVS} data={typeJVS} validation={true} description="обязательное поле" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <AutocompleteInputFromObject options={CableManagmentOptions} name="value" placeholder="Требуется ли кабель-менеджмент рабочего места?" setData={setCableManagment} data={cableManagment} validation={true} description="обязательное поле" />
                </div>


                <button
                    disabled={disabled}
                    className="button"
                    onClick={createPc}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isDeleteModalOpen} setActive={setIsDeleteModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold' }}> Вы уверены что хотите удалить ПК {selectedRow}</p>
                <button
                    className="button"
                    onClick={deletePc}
                >
                    Да, я уверен(а)
                </button>
            </Modal>
        </div>
    );
};

export default PCCompany;