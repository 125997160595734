import React, { useState, useEffect, useCallback } from 'react';
import ApiService from '../ApiService';
import { debounce } from 'lodash';

const Grid = ({ apiUrl, columns, selectedRow, setSelectedRow, listener, params = {} }) => {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowId, setSelectedRowId] = useState(selectedRow);
  const [activeSearchColumn, setActiveSearchColumn] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 50; // Number of items per page

  useEffect(() => {
    setSelectedRowId(selectedRow);
  }, [selectedRow]);

  const handleRowClick = (id) => {
    setSelectedRowId(id);
    setSelectedRow(id);
  };

  const handleColumnHeaderClick = (columnKey) => {
    setActiveSearchColumn(columnKey === activeSearchColumn ? null : columnKey);
  };

  const getRowClass = (item) => {
    const classes = [];
    if (item.colorRed) {
      classes.push('highlight-red');
    }
    return classes.join(' ');
  };

  const fetchData = useCallback(debounce(async (query, column) => {
    try {
      const searchParams = query ? { search: query, column: column } : {};
      const combinedParams = { ...params, ...searchParams, page: currentPage, itemsPerPage };
      const response = await ApiService.fetchDataWithToken(apiUrl, combinedParams);
      const data = response.items; // Update based on your data structure
      const totalItems = response.totalItems; // Total number of items (should come from server)
      console.log(totalItems)
      setItems(data ?? []);
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  }, 300), [apiUrl, currentPage]);
  useEffect(() => {
    fetchData(searchTerm, activeSearchColumn);
  }, [searchTerm, activeSearchColumn, fetchData, listener, currentPage]);

  const formatDateTime = (value) => {
    const date = new Date(value);
    if (date.getTime() === 0) {
      return 'не указано';
    } else {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const [columnWidths, setColumnWidths] = useState(() => {
    const savedWidths = localStorage.getItem('columnWidths');
    return savedWidths ? JSON.parse(savedWidths) : {};
  });

  const handleResizeStart = (e, columnKey) => {
    e.preventDefault();
    e.stopPropagation();

    const startX = e.type === 'mousedown' ? e.clientX : e.touches[0].clientX;
    const columnHeader = e.target.parentElement;
    const startWidth = columnWidths[columnKey] || columnHeader.offsetWidth;

    const handleMouseMove = (moveEvent) => {
      const moveX = moveEvent.type === 'mousemove' ? moveEvent.clientX : moveEvent.touches[0].clientX;
      const newWidth = Math.max(startWidth + moveX - startX, 50); // Минимальная ширина 50px

      columnHeader.style.width = `${newWidth}px`;
    };

    const handleMouseUp = () => {
      const newWidth = parseFloat(columnHeader.style.width);

      setColumnWidths(prevWidths => {
        const updatedWidths = { ...prevWidths, [columnKey]: newWidth };
        localStorage.setItem('columnWidths', JSON.stringify(updatedWidths));
        return updatedWidths;
      });

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('touchend', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleMouseMove);
    document.addEventListener('touchend', handleMouseUp);
  };

  return (
    <div className='grid-container'>
      <div className='search-container'>
        <input
          type="text"
          placeholder="Введите значение для поиска..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="table-wrapper">
        <table className='table'>
          <thead>
            <tr>
              {columns.map(column => (
                <th
                  key={column.key}
                  className={activeSearchColumn === column.key ? 'selected-column' : ''}
                  style={{ width: columnWidths[column.key] || 'auto' }}
                  onClick={() => handleColumnHeaderClick(column.key)}
                >
                  {column.title}
                  <div className='resizer-area' onMouseDown={(e) => handleResizeStart(e, column.key)} onTouchStart={(e) => handleResizeStart(e, column.key)}>
                  </div>
                </th>

              ))}
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <tr key={item.id}
                className={`${getRowClass(item)} ${String(item.id) === String(selectedRowId) ? 'selected' : ''}`}
                onClick={() => handleRowClick(item.id)}>
                {columns.map(column => (
                  <td key={`${item.id}-${column.key}`}>
                    {column.date ? formatDateTime(item[column.key]) : item[column.key] || 'Не указанно'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='pagination'>
        <button
          className='button'
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}>
          &lt;
        </button>
        <span>{`Страница ${currentPage} из ${totalPages}`}</span>
        <button
          className='button'
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Grid;
