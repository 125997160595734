import React, { useState, useEffect } from 'react';
import CustomToast from '../../components/CustomToast';
import ApiService from '../../components/ApiService';
import AutocompleteInput from '../../components/autoCompliteInputs/AndCreate';
import AutocompleteInputFromObject from '../../components/autoCompliteInputs/fromObject';
const CompanyAudit = ({ setRefresh }) => {

  const ATCoptions = [
    { value: "Присутствует облачная АТС" },
    { value: "Присутствует аналоговая АТС" },
    { value: "Отсутствует" },
    { value: "Не выяснили" }
  ];

  const CctvOptions = [
    { value: "Присутствует облачное видеонаблюдение" },
    { value: "Присутствует видеонаблюдение" },
    { value: "Отсутствует" },
    { value: "Не выяснили" }
  ];

  const [cctv, setCctv] = useState('');
  const [ATC, setATC] = useState('');
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [disabled, setDisabled] = useState('');
  const [description, setDescriptiont] = useState('');
  const [images, setImages] = useState([]);

  const [company, setCompany] = useState({
    id: null,
    value: "",
  });
  const [provider, setProvider] = useState({
    id: null,
    value: "",
  });
  const [division, setDivision] = useState({
    id: null,
    value: "",
  });

  useEffect(() => {
    ApiService.setToastHandlers(setToastMessage, setToastType);
  }, []);
  useEffect(() => {
    if (company.id != null) {
      const fetchData = async () => {
        try {
          const response = await ApiService.fetchDataWithToken(`/firm/${company.id}`);
          console.log(division.id)
          if (division.id != null) {
            const divisionResponse = await ApiService.fetchDataWithToken(`/firm/${company.id}/division/${division.id}`);
            setProvider({ id: divisionResponse[0].providerId || null, value: divisionResponse[0].providerName || '' })
            setATC(divisionResponse[0].ATC || '');
            setCctv(divisionResponse[0].CCTV || '');
          }
          setDescriptiont(response[0].description || '')
        } catch (error) {
        }
      };
      fetchData();
    }
  }, [company, division]);

  const handleButtonClickToPC = async () => {
    setDisabled(true)
    console.log(division)
    if (division.id == null) {
      setToastType('error')
      setToastMessage('Укажите филиал')
      setDisabled(false)
      return
    }
    const body = {
      id: company.id,
      divisionId: division.id,
      ATC: ATC,
      description: description,
      CCTV: cctv,
      providerId: provider.id
    }
    try {
      const response = await ApiService.postDataWithToken('/firm/audit', body);
      setToastType('success');
      setToastMessage('Успешно');
      setDisabled(false)
      setRefresh({ type: 'pc', division: division, company: company })
    } catch (error) {
      setDisabled(false)
    }
  };

  const handleButtonClickToServer = async () => {
    setDisabled(true)
    console.log(division)
    if (division.id == null) {
      setToastType('error')
      setToastMessage('Укажите филиал')
      setDisabled(false)
      return
    }
    const body = {
      id: company.id,
      ATC: ATC,
      description: description,
      CCTV: cctv,
      divisionId: division.id,
      providerId: provider.id
    }
    try {
      const response = await ApiService.postDataWithToken('/firm/audit', body);
      setToastType('success');
      setToastMessage('Успешно');
      setDisabled(false)
      setRefresh({ type: 'server', division: division, company: company })
    } catch (error) {
      setDisabled(false)
    }
  };

  const handleButtonClickToOrg = async () => {
    setDisabled(true)
    console.log(division)
    if (division.id == null) {
      setToastType('error')
      setToastMessage('Укажите филиал')
      setDisabled(false)
      return
    }
    const body = {
      id: company.id,
      ATC: ATC,
      description: description,
      CCTV: cctv,
      divisionId: division.id,
      providerId: provider.id
    }
    try {
      const response = await ApiService.postDataWithToken('/firm/audit', body);
      setToastType('success');
      setToastMessage('Успешно');
      setDisabled(false)
      setRefresh({ type: 'org', division: division, company: company })
    } catch (error) {
      setDisabled(false)
    }
  };

  const handleButtonClickToCaxhbox = async () => {
    setDisabled(true)
    console.log(division)
    if (division.id == null) {
      setToastType('error')
      setToastMessage('Укажите филиал')
      setDisabled(false)
      return
    }
    const body = {
      id: company.id,
      ATC: ATC,
      description: description,
      CCTV: cctv,
      divisionId: division.id,
      providerId: provider.id
    }
    try {
      const response = await ApiService.postDataWithToken('/firm/audit', body);
      setToastType('success');
      setToastMessage('Успешно');
      setDisabled(false)
      setRefresh({ type: 'cashbox', division: division, company: company })
    } catch (error) {
      setDisabled(false)
    }
  };


  return (
    <div>
      <div className="App">
        <header className="App-header">
        </header>
        <div className="headerTitle">Общая информация о клиенте</div>
        <div className='form-container'>
          <p style={{ fontWeight: 'bold' }}>Клиент</p>
          <AutocompleteInput
            apiUrl="/firm"
            name="name"
            description={'Обязательное поле'}
            placeholder="*Выбрать"
            setData={setCompany}
            data={company}
            validation={true}
          />
          {company.id !== null && <div style={{ marginBottom: "10px" }}>
            <p style={{ fontWeight: 'bold' }}>Филиал</p>
            <AutocompleteInput
              key={company}
              description={'Обязательное поле'}
              apiUrl={`/firm/${company.id}/division`}
              name='adres'
              placeholder="*Выбрать"
              setData={setDivision}
              data={division}
              validation={true}
            />
          </div>}
          {division.id !== null && <div style={{ marginBottom: "10px" }}>
            <p style={{ fontWeight: 'bold' }}>Провайдер</p>
            <AutocompleteInput
              key={company}
              description={'Обязательное поле'}
              apiUrl={`/firm/provider`}
              name='name'
              placeholder="*Выбрать"
              setData={setProvider}
              data={provider}
              validation={true}
            />
          </div>}
          {division.id !== null && <div style={{ marginBottom: "10px" }}>
            <p style={{ fontWeight: 'bold' }}>ATC</p>
            <AutocompleteInputFromObject
              options={ATCoptions}
              name="value"
              placeholder="*Выбрать"
              setData={setATC}
              data={ATC}
              validation={true}
              description="Обязательное поле"
            />
          </div>}
          {division.id !== null && <div style={{ marginBottom: "10px" }}>
            <p style={{ fontWeight: 'bold' }}>Видеонаблюдение</p>
            <AutocompleteInputFromObject
              options={CctvOptions}
              name="value"
              placeholder="*Выбрать"
              setData={setCctv}
              data={cctv}
              validation={true}
              description="Обязательное поле"
            />
          </div>}
          <div style={{ marginTop: "20px" }}>
            <p style={{ fontWeight: 'bold' }}>Примечание</p>
            <input
              type="text"
              value={description}
              placeholder="Текст"
              onChange={(e) => setDescriptiont(e.target.value)} />
          </div>
          <button
            style={{ margin: '5px' }}
            className="button"
            disabled={disabled}
            isLoading={false}
            onClick={handleButtonClickToPC}
          >
            Перейти к ПК
          </button>
          <button
            style={{ margin: '5px' }}
            className="button"
            disabled={disabled}
            isLoading={false}
            onClick={handleButtonClickToServer}
          >
            Перейти к Серверам
          </button>
          <button
            style={{ margin: '5px' }}
            className="button"
            disabled={disabled}
            isLoading={false}
            onClick={handleButtonClickToOrg}
          >
            Перейти к Орг
          </button>
          <button
            style={{ margin: '5px' }}
            className="button"
            disabled={disabled}
            isLoading={false}
            onClick={handleButtonClickToCaxhbox}
          >
            Перейти к Кассам
          </button>
        </div>
        <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
      </div>
    </div>
  );
};
export default CompanyAudit;