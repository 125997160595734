import React from 'react';
import PropTypes from 'prop-types';

const YearSelector = ({ selectedYear, onYearChange }) => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear - 5 + i); // Диапазон годов от текущего года - 5 до текущего года + 4

    return (
        <div className='year-selector'>
            <p className='p'>Выберите год: </p>
            <select className="year" value={selectedYear} onChange={(e) => onYearChange(Number(e.target.value))}>
                {years.map(year => (
                    <option key={year} value={year}>{year}</option>
                ))}
            </select>
        </div>
    );
};

YearSelector.propTypes = {
    selectedYear: PropTypes.number.isRequired,
    onYearChange: PropTypes.func.isRequired
};

export default YearSelector;
