import React, { useEffect, useState } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';

const CategoryUser = () => {
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [categories, setCategories] = useState([]);
    const [users, setUsers] = useState([]);
    const [links, setLinks] = useState([]);
    const [draggedUserId, setDraggedUserId] = useState(null);
    const [draggedElement, setDraggedElement] = useState(null);

    const fetchData = async () => {
        try {
            const categoryData = await ApiService.fetchDataWithToken('/tip/all?page=1&itemsPerPage=1000');
            const userData = await ApiService.fetchDataWithToken('/user');
            const categoryUserLinks = await ApiService.fetchDataWithToken('/tip/categoriesUser');
            
            setCategories(categoryData.items);
            setUsers(userData);
            setLinks(categoryUserLinks);
        } catch (error) {
            // Обработка ошибок
        }
    };

    useEffect(() => {
        document.title = 'Связь категорий и пользователей';
        fetchData();
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);

    const handleRemoveLink = async (categoryId, userId) => {
        try {
            await ApiService.postDataWithToken(`/tip/categoriesUserDel`, { categoryId, userId });
            fetchData();
            setToastMessage("Связь успешно удалена");
            setToastType("success");
        } catch (error) {
            // Обработка ошибок
        }
    };

    const handleDragStart = (e, userId) => {
        setDraggedUserId(userId);
        const user = users.find(u => u.id === userId);
        if (user) {
            const elem = document.createElement('div');
            elem.className = 'dragged-element';
            elem.innerText = `${user.firstName} ${user.lastName}`;
            document.body.appendChild(elem);
            setDraggedElement(elem);
        }
    };

    const handleDrag = (e) => {
        if (draggedElement) {
            const offsetY = e.clientY + window.scrollY;
            draggedElement.style.left = `${e.clientX + 10}px`;
            draggedElement.style.top = `${offsetY + 10}px`;

            // Автоматическая прокрутка при достижении края видимой области
            if (e.clientY < 100) {
                window.scrollBy(0, -10);
            } else if (window.innerHeight - e.clientY < 100) {
                window.scrollBy(0, 10);
            }
        }
    };

    const handleDrop = async (e, categoryId) => {
        e.preventDefault();
        if (draggedUserId) {
            try {
                await ApiService.postDataWithToken('/tip/categoriesUser', { categoryId, userId: draggedUserId });
                fetchData();
            } catch (error) {
                // Обработка ошибок
            }
        }
        cleanupDrag();
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };

    const cleanupDrag = () => {
        setDraggedUserId(null);
        if (draggedElement) {
            document.body.removeChild(draggedElement);
            setDraggedElement(null);
        }
    };

    // Handlers for touch events (mobile devices)
    const handleTouchStart = (e, userId) => {
        setDraggedUserId(userId);
        const user = users.find(u => u.id === userId);
        if (user) {
            const elem = document.createElement('div');
            elem.className = 'dragged-element';
            elem.innerText = `${user.firstName} ${user.lastName}`;
            document.body.appendChild(elem);
            setDraggedElement(elem);
        }
    };

    const handleTouchMove = (e) => {
        e.preventDefault();
        const touch = e.touches[0];
        const offsetY = touch.clientY + window.scrollY;
        if (draggedElement) {
            draggedElement.style.left = `${touch.clientX + 10}px`;
            draggedElement.style.top = `${offsetY + 10}px`;

            // Автоматическая прокрутка при достижении края видимой области
            if (touch.clientY < 100) {
                window.scrollBy(0, -10);
            } else if (window.innerHeight - touch.clientY < 100) {
                window.scrollBy(0, 10);
            }
        }
    };

    const handleTouchEnd = async (e) => {
        if (!draggedUserId) return;

        const touch = e.changedTouches[0];
        const element = document.elementFromPoint(touch.clientX, touch.clientY);

        if (element && element.classList.contains('project')) {
            const categoryId = element.getAttribute('data-category-id');
            if (categoryId) {
                try {
                    await ApiService.postDataWithToken('/tip/categoriesUser', { categoryId, userId: draggedUserId });
                    fetchData();
                } catch (error) {
                    // Обработка ошибок
                }
            }
        }
        cleanupDrag();
    };

    return (
        <div className="link-container">
            <h1>Связь категорий и пользователей</h1>
            <div className="link-content">
                <div className="link-block">
                    <div className="project-def">
                        <h2>Пользователи</h2>
                        {users.map(user => (
                            <div
                                key={user.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, user.id)}
                                onDrag={(e) => handleDrag(e)}
                                onTouchStart={(e) => handleTouchStart(e, user.id)}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                                className="category"
                            >
                                {user.firstName} {user.lastName}
                            </div>
                        ))}
                    </div>
                    <div className='project-wrapper'>
                        {categories.map((category) => (
                            <div
                                key={category.id}
                                data-category-id={category.id}
                                onDrop={(e) => handleDrop(e, category.id)}
                                onDragOver={(e) => allowDrop(e)}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                                className="project"
                            >
                                <h3>{category.name}</h3>
                                {links
                                    .filter(link => link.categoryId === category.id)
                                    .map((link) => {
                                        const user = users.find(user => user.id === link.userId);
                                        return (
                                            <div key={link.userId} className="category-new">
                                                {user && `${user.firstName} ${user.lastName}`}
                                                <button
                                                    onClick={(e) => handleRemoveLink(category.id, link.userId)}
                                                    style={{
                                                        padding: '2px 2px',
                                                        color: 'white',
                                                        background: 'none',
                                                        border: 'none',
                                                        cursor: 'pointer'
                                                    }}>
                                                    ❌
                                                </button>
                                            </div>
                                        );
                                    })}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
        </div>
    );
};

export default CategoryUser;
