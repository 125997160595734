import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import ApiService from '../../components/ApiService';
import Modal from '../../components/Modal';
import CustomToast from '../../components/CustomToast';
import YearMonthSelector from '../../components/YearMonthSelector';
import UserBonus from '../../components/user/Bonus';

const Enots = () => {
    const [columns, setColumns] = useState([]);
    const [editingColumnId, setEditingColumnId] = useState(null);
    const [newColumnName, setNewColumnName] = useState('');
    const [newColumnCoefficient, setNewColumnNameCoefficient] = useState(null);
    const [isCreateModal, setIsCreateModal] = useState(false);
    const [isUpdateModal, setIsUpdateModal] = useState(false);
    const [newPositive, setNewPositive] = useState(false);
    const [isEditingColumns, setIsEditingColumns] = useState(false);
    const [value, setValue] = useState([]);
    const [users, setUsers] = useState([]);
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // Месяц начинается с 0
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [role, setIsRole] = useState(false);

    const MounthStart = useMemo(() => {
        const MounthStart = new Date(selectedYear, selectedMonth, 1);
        MounthStart.setHours(0, 0, 0, 0);
        return MounthStart;
    }, [selectedYear, selectedMonth]);

    const MounthEnd = useMemo(() => {
        const MounthEnd = new Date(selectedYear, selectedMonth + 1, 0);
        MounthEnd.setHours(23, 59, 59, 999);
        return MounthEnd;
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        document.title = 'Еноты';
        fetchColumns();
        fetchUsers();
        ApiService.setToastHandlers(setToastMessage, setToastType);
    }, []);

    useEffect(() => {
        fetchValue();
        const role = localStorage.getItem('role');
        setIsRole(role);
    }, [MounthStart, MounthEnd]);

    const fetchColumns = async () => {
        try {
            const response = await ApiService.fetchDataWithToken('/sheet/column');
            setColumns(response);
        } catch (error) {
            console.error('Ошибка при получении колонок:', error);
        }
    };

    const fetchValue = async () => {
        try {
            const response = await ApiService.fetchDataWithToken('/sheet/value', { startDate: MounthStart, endDate: MounthEnd });
            setValue(response);
        } catch (error) {
            console.error('Ошибка при получении значений:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await ApiService.fetchDataWithToken('/user/getList');
            setUsers(response);
        } catch (error) {
            console.error("Ошибка при получении пользователей:", error);
        }
    };

    const handleInputChange = (userId, columnId, newValue) => {
        const currentDate = new Date().toISOString().split('T')[0]; // Получаем текущую дату в формате YYYY-MM-DD
        setValue(prevValue => {
            const existingValueIndex = prevValue.findIndex(v => v.userId === userId && v.incentivesColumnId === columnId);

            if (existingValueIndex !== -1) {
                // Обновляем существующее значение
                const updatedValues = [...prevValue];
                updatedValues[existingValueIndex] = { ...updatedValues[existingValueIndex], value: newValue };
                return updatedValues;
            } else {
                // Создаем новое значение с текущей датой
                return [...prevValue, { userId, incentivesColumnId: columnId, value: newValue, date: currentDate }];
            }
        });
    };

    const handleBlur = async (userId, columnId) => {
        const valueToSave = value.find(v => v.userId === userId && v.incentivesColumnId === columnId);
        // Если запись существует (есть id), обновляем её
        try {
            await ApiService.postDataWithToken('/sheet/value/update', {
                userId,
                incentivesColumnId: columnId,
                value: valueToSave.value,
                startDate: MounthStart,
                endDate: MounthEnd
            });
        } catch (error) {
            console.error('Ошибка при сохранении значения:', error);
        }
    };

    const handleEditColumn = (columnId) => {
        setEditingColumnId(columnId);
        const editedColumn = columns.find(column => column.id === columnId);
        setNewColumnName(editedColumn.name);
        setNewPositive(editedColumn.isPositive);
        setNewColumnNameCoefficient(editedColumn.coefficient);
        setIsUpdateModal(true);
    };

    const handleSaveColumn = async () => {
        try {
            const body = {
                id: editingColumnId,
                name: newColumnName,
                isPositive: newPositive,
                coefficient: newColumnCoefficient,
            };
            await ApiService.postDataWithToken("/sheet/column/update", body);
            setEditingColumnId(null);
            setNewColumnNameCoefficient(null);
            setNewColumnName('');
            setNewPositive(false);
            fetchColumns();
            setIsUpdateModal(false);
        } catch (error) {
            console.error('Ошибка при редактировании колонки:', error);
        }
    };

    const handleDeleteColumn = async (columnId) => {
        try {
            await ApiService.postDataWithToken("/sheet/column/delete", { id: columnId });
            fetchColumns();
        } catch (error) {
            console.error('Ошибка при удалении колонки:', error);
        }
    };

    const handleAddColumn = async () => {
        try {
            const body = {
                name: newColumnName,
                isPositive: newPositive,
                coefficient: newColumnCoefficient,
            };
            await ApiService.postDataWithToken('/sheet/column/add', body);
            setNewColumnName('');
            setNewColumnNameCoefficient(null);
            setNewPositive(false);
            fetchColumns();
            setIsCreateModal(false);
        } catch (error) {
            console.error('Ошибка при добавлении колонки:', error);
        }
    };

    // Создаем объект, где ключ - это userId, а значение - это объект с данными для колонок
    const userValues = users.reduce((acc, user) => {
        acc[user.id] = columns.reduce((columnAcc, column) => {
            const valueData = value.find(v => v.incentivesColumnId === column.id && v.userId === user.id);
            columnAcc[column.id] = valueData ? valueData.value : 0;
            return columnAcc;

        }, {});
        return acc;
    }, {});

    return (
        <div className='enots'>
            <div style={{ margin: '10px' }}>
                <YearMonthSelector
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    onYearChange={setSelectedYear}
                    onMonthChange={setSelectedMonth}
                />
            </div>
            <h2>Бонусные ЕНОТы</h2>
            {role == 'admin' ? (
                <button onClick={() => setIsEditingColumns(!isEditingColumns)}>
                    {isEditingColumns ? "Отменить редактирование" : "Редактировать колонки"}
                </button>
            ) : (
                <></>
            )}

            <table className='table'>
                <thead>
                    <tr>
                        <th>Пользователи</th>
                        {columns.map(column => (
                            <th
                                key={column.id}
                            >
                                <div className='head-name'>
                                    {column.name}
                                    {isEditingColumns && (
                                        <>
                                            <svg className="clip-icon" onClick={() => handleEditColumn(column.id)} viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                                <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M11 20H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            <svg className="clip-icon" onClick={() => handleDeleteColumn(column.id)} viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                                <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                                                <path d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5" stroke="currentColor" stroke-width="1.5" />
                                            </svg>
                                        </>
                                    )}
                                </div>
                            </th>
                        ))}
                        {isEditingColumns && (
                            <th>
                                <svg className="clip-icon" onClick={() => setIsCreateModal(true)} viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                                    <path d="M12 8V16M16 12L8 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="currentColor" stroke-width="1.5" />
                                </svg>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.firstName} {user.lastName}</td>
                            {columns.map(column => (
                                <td
                                    key={column.id}
                                    className={column.isPositive ? 'positive-column' : 'negative-column'}
                                >
                                    {role == 'admin' || role == 'manager' || role == 'sales manager' || role == 'bookkeeping' ? (
                                        <input
                                            type="text"
                                            value={userValues[user.id]?.[column.id] || ''}
                                            onChange={(e) => handleInputChange(user.id, column.id, e.target.value)}
                                            onBlur={() => handleBlur(user.id, column.id)}
                                            placeholder="0"
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            value={userValues[user.id]?.[column.id] || ''}
                                            placeholder="0"
                                        />
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{ display: 'flex' }}>
                <div style={{ margin: '10px', width:'fit-content'}}>
                    <UserBonus startDate={MounthStart} endDate={MounthEnd} />
                </div>
                <div className='legend'>
                    <h3>Коэффициенты</h3>
                    <ul>
                        {columns.map(column => (
                            <li
                                key={column.id}
                                className={column.isPositive ? 'positive' : 'negative'}
                            >
                                {column.name}: {column.isPositive ? `+${parseFloat(column.coefficient).toFixed(2)}` : `-${parseFloat(column.coefficient).toFixed(2)}`}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isCreateModal} setActive={setIsCreateModal}>
                <input
                    style={{ marginTop: '40px' }}
                    value={newColumnName}
                    onChange={(e) => setNewColumnName(e.target.value)}
                    placeholder='Текст'
                />
                <input

                    style={{ marginTop: '20px' }}
                    value={newColumnCoefficient}
                    type='number'
                    onChange={(e) => setNewColumnNameCoefficient(e.target.value)}
                    placeholder='0.0'
                />
                <label className="checkbox-container">
                    <input

                        style={{ height: '30px', width: '30px' }}
                        type="checkbox"
                        className="checkbox-input"
                        checked={newPositive}
                        onChange={() => setNewPositive(!newPositive)}
                    />
                    <span style={{ fontSize: '18px' }}>Поощерения</span>
                </label>
                <button onClick={handleAddColumn}>Отправить</button>
            </Modal>
            <Modal active={isUpdateModal} setActive={setIsUpdateModal}>
                <input
                    style={{ marginTop: '40px' }}
                    value={newColumnName}
                    onChange={(e) => setNewColumnName(e.target.value)}
                    placeholder='Текст'
                />
                <input
                    style={{ marginTop: '20px' }}
                    value={newColumnCoefficient}
                    type='number'
                    onChange={(e) => setNewColumnNameCoefficient(e.target.value)}
                    placeholder='0.0'
                />
                <label className="checkbox-container">
                    <input
                        style={{ height: '30px', width: '30px' }}
                        type="checkbox"
                        className="checkbox-input"
                        checked={newPositive}
                        onChange={() => setNewPositive(!newPositive)}
                    />
                    <span style={{ fontSize: '18px' }}>Поощерения</span>
                </label>
                <button onClick={handleSaveColumn}>Отправить</button>
            </Modal>
        </div>
    );
};

export default Enots;
