
import React, { useState, useEffect } from 'react';
import ApiService from '../components/ApiService';
import CustomToast from '../components/CustomToast';
import Grid from '../components/tables/Grid';
import View from '../views/view';
import Modal from '../components/Modal'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Ofd = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { id } = useParams();

    const apiUrl = `/ofd`;

    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listener, setListener] = useState(0);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [ofdData, setOfdData] = useState({
        id: '',
        name: '',
        link: '',
    });

    const columns = [
        { key: 'id', title: 'ИД' },
        { key: 'name', title: 'Название' },
        { key: 'link', title: 'Ссылка' },
    ];
    const detailsConfig = [
        { key: 'id', label: 'ИД' },
        { key: 'name', label: 'Название' },
        { key: 'link', label: 'Ссылка' },
    ];

    useEffect(() => {
        document.title = 'ОФД';
        ApiService.setToastHandlers(setToastMessage, setToastType);

    }, []);

    useEffect(() => {
        if (id) setSelectedRow(id);
    }, [id]);

    const setSelectRow = (id) => {
        setSelectedRow(id);
        let currentPath = location.pathname;
        if (!currentPath.endsWith('/') && !currentPath.match(/\/\d+\/?$/)) {
            currentPath += '/';
        }
        // Получаем текущий путь и разделяем его по слешам
        let pathSegments = currentPath.split('/');

        // Убедимся, что у нас есть достаточно сегментов для изменения
        if (pathSegments.length > 1) {
            // Заменяем последний сегмент на новый id
            pathSegments[pathSegments.length - 1] = id;

            // Объединяем сегменты обратно в строку и заменяем текущий путь в истории
            navigate(pathSegments.join('/'), { replace: true });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOfdData(prevData => ({ ...prevData, [name]: value }));
    };

    // Функция, вызывающая перерисовку компонента
    const refreshTable = () => {
        setListener(prevKey => prevKey + 1);
    };


    const openCreate = async () => {
        setOfdData({
            id: '',
            name: '',
            link: '',
        })
        setIsCreateModalOpen(true)
    }

    const openUpdate = async () => {
        const response = await ApiService.fetchDataWithToken(`/ofd/${selectedRow}`)
        setOfdData({
            id: response[0].id,
            name: response[0].name,
            link: response[0].link,
        })
        setIsUpdateModalOpen(true)
    }

    const updateOfd = async () => {
        try {
            await ApiService.postDataWithToken(`/ofd/update`, ofdData);
            setToastType('success');
            setToastMessage('Офд успешно изменен');
            refreshTable()
            setIsUpdateModalOpen(false)
        } catch (error) {
        }
    }

    const createOfd = async () => {
        try {
            await ApiService.postDataWithToken(`/ofd/add`, ofdData);
            setToastType('success');
            setToastMessage('Офд успешно сохранен');
            refreshTable()
            setIsCreateModalOpen(false)
        } catch (error) {
        }
    }
    const deleteOfd = async () => {
        try {
            await ApiService.postDataWithToken(`/ofd/delete`, { id: selectedRow });
            setToastType('success');
            setToastMessage('Офд успешно удален');
            refreshTable()
            setIsDeleteModalOpen(false)
        } catch (error) {
        }
    }
    return (
        <div>
            <div className="grid-view-container">
                <Grid
                    listener={listener}
                    apiUrl={apiUrl}
                    columns={columns}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectRow}
                />
                <View listener={listener} selectedId={selectedRow} apiUrl={apiUrl} detailsConfig={detailsConfig}>
                    <button style={{ height: '45px', margin: '5px' }}
                        className="task-table-button"
                        onClick={() => { openUpdate() }}
                    >
                        Редактировать
                    </button>
                    <button style={{ height: '45px', margin: '5px' }}
                        className="task-table-button"
                        onClick={() => { openCreate() }}
                        alwaysVisible
                    >
                        Создать
                    </button>
                    <button style={{ height: '45px', margin: '5px' }}
                        className="task-table-button"
                        onClick={() => { setIsDeleteModalOpen(true) }}
                    >
                        Удалить
                    </button>
                </View>
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isUpdateModalOpen} setActive={setIsUpdateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Редактор ОФД {ofdData.id}</p>
                <p style={{ fontWeight: 'bold' }}>Название</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="name" value={ofdData.name} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Ссылка</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="link" value={ofdData.link} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <button
                    className="button"
                    onClick={updateOfd}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isCreateModalOpen} setActive={setIsCreateModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Создание ОФД</p>
                <p style={{ fontWeight: 'bold' }}>Название</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="name" value={ofdData.name} onChange={handleInputChange} placeholder="Текст" />
                </div>
                <p style={{ fontWeight: 'bold' }}>Ссылка</p>
                <div style={{ marginBottom: "10px" }}>
                    <input type="text" name="link" value={ofdData.link} onChange={handleInputChange} placeholder="Текст" />
                </div>

                <button
                    className="button"
                    onClick={createOfd}
                >
                    Отправить
                </button>
            </Modal>
            <Modal active={isDeleteModalOpen} setActive={setIsDeleteModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}> Вы уверены что хотите удалить кассу {selectedRow}</p>
                <button
                    className="button"
                    onClick={deleteOfd}
                >
                    Да, я уверен(а)
                </button>
            </Modal>
        </div>
    );
};

export default Ofd
