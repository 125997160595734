import React, { useEffect, useState } from 'react';
import ApiService from '../ApiService';

const CashboxExpiring = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.fetchDataWithToken('/cashbox/expiring');
                setData(response.cashboxes); // Предполагая, что данные приходят в этом формате
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const calculateDaysRemaining = (endDate) => {
        const today = new Date();
        const end = new Date(endDate);
        const timeDiff = end.getTime() - today.getTime();
        return Math.ceil(timeDiff / (1000 * 3600 * 24)); // Преобразование в дни
    };

    return (
        <div className="table-wrapper" style={{ padding: '20px' }}>
            <h2>Кассы с истекающим сроком</h2>
            {data.length > 0 ? (
                <table className="table" style={{ boxShadow: '0 0px 8px rgba(0, 0, 0, 0.3)', borderRadius: '8px' }}>
                    <thead>
                        <tr style={{ borderRadius: '8px' }}>
                            <th style={{ borderRadius: '8px' }}>ID</th>
                            <th>Модель</th>
                            <th>Адрес</th>
                            <th>Компания</th>
                            <th>Дней до окончания OFD</th>
                            <th style={{ borderRadius: '8px' }}>Дней до окончания FN</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(cashbox => (
                            <tr key={cashbox.id}>
                                <td>{cashbox.id}</td>
                                <td>{cashbox.model}</td>
                                <td>{cashbox.adres}</td>
                                <td>{cashbox.companyName}</td>
                                <td>{calculateDaysRemaining(cashbox.dateOfdEnd)} дней</td>
                                <td>{calculateDaysRemaining(cashbox.dateFnEnd)} дней</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Нет данных для отображения.</p>
            )}
        </div>
    );
};

export default CashboxExpiring;
