import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import ApiService from '../ApiService';

const AutocompleteInput = ({ apiUrl, name, placeholder, setData, data, validation, description, setName }) => {
    const [inputValue, setInputValue] = useState(data.value || '');
    const [options, setOptions] = useState([]);
    const [isInputFocused, setIsInputFocused] = useState(false);

    const fetchData = useCallback(debounce(async (query) => {
        try {
            const searchParams = { search: query, column: name };
            const response = await ApiService.fetchDataWithToken(apiUrl, searchParams);
            setOptions(response.items ?? response ?? []);
        } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
        }
    }, 500), [apiUrl, name]);

    useEffect(() => {
        fetchData(inputValue);
    }, [inputValue, fetchData]);

    useEffect(() => {
        setInputValue(data.value);
    }, [data]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        if (setName) {
            setName(value);
        }
        setData({ ...data, value, id: null });
    };

    const handleOptionClick = (option) => {
        setData({ id: option.id, value: option[name] });
        setInputValue(option[name]);
        if (setName) {
            setName(option[name]);
        }
        setIsInputFocused(false);
    };

    const handleInputFocus = () => {
        setIsInputFocused(true);
        fetchData(inputValue);
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setIsInputFocused(false);
            setOptions([]);
        }, 300);
    };

    const inputStyle = {
        borderColor: validation && !data.id ? 'red' : 'rgb(177, 228, 65)',
        outline: validation && !data.id ? 'none' : '',
        boxShadow: validation && !data.id && isInputFocused ? '0 0 5px red' : 'none'
    };

    return (
        <div>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder={placeholder}
                style={inputStyle}
            />
            {isInputFocused && options.length > 0 && (
                <div style={{ position: 'relative' }}>
                    <div className="options-list" style={{ cursor: 'pointer', border: '1px solid #ccc', position: 'absolute', width: '100%', backgroundColor: '#fff', zIndex: 1 }}>
                        {options.map((option, index) => (
                            <div className="options-list-item" key={index} onClick={() => handleOptionClick(option)}>
                                {option[name]}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <p
                style={{
                    color: 'rgb(211, 0, 0)',
                    fontSize: '12px',
                    height: '15px',
                    margin: '5px',
                    visibility: validation && !data.id ? 'visible' : 'hidden'
                }}
            >
                *{description}
            </p>
        </div>
    );
};

export default AutocompleteInput;
