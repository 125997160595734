import React, { useState, useEffect, useRef } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import View from '../../views/view';
import UserComponent from '../../components/user/UserComponent';
import SmallMenu from '../../components/smallMenu';
import TaskTable from '../../components/tables/TaskTable';
import Chat from '../../components/chat';
import Modal from '../../components/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import AutocompleteInput from '../../components/autoCompliteInputs/FithoutCreate';
import SideModal from '../../components/SideModal';
import CheckList from '../../components/CheckList';

const User = () => {

    const [item, setItem] = useState([])
    const [toastMessage, setToastMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [commentInput, setCommentInput] = useState('');
    const [userItems, setUserItems] = useState(null);
    const [deadline, setDeadline] = useState(new Date());
    const [selectedRow, setSelectedRow] = useState(null);
    const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
    const [listener, setListener] = useState(0);
    const [checkListOpenModal, setCheckListOpenModal] = useState(false);
    const [projectIdInput, setProjectIdInput] = useState('');
    const [newDeadline, setNewDeadline] = useState(null);
    const [openModelDeadline, setOpenModelDeadline] = useState(false);
    const [completion_time, setCompletion_time] = useState(null);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(false)
    const [enotModalOpen, setEnotModalOpen] = useState(false);


    const navigate = useNavigate();

    // Функция, вызывающая перерисовку компонента
    const refreshTaskTable = () => {
        setListener(prevKey => prevKey + 1);
    };
    const [isPutOffModalOpen, setIsPutOffModalOpen] = useState('');
    const textareaRef1 = useRef(null);
    const textareaRef2 = useRef(null);
    useEffect(() => {
        if (textareaRef1.current) {
            textareaRef1.current.style.height = 'auto'; // Сбрасываем высоту до автоматической
            const scrollHeight = textareaRef1.current.scrollHeight;
            const maxHeight = parseInt(window.getComputedStyle(textareaRef1.current).maxHeight);
            textareaRef1.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`; // Устанавливаем высоту в зависимости от содержимого и максимальной высоты
        }
        if (textareaRef2.current) {
            textareaRef2.current.style.height = 'auto'; // Сбрасываем высоту до автоматической
            const scrollHeight = textareaRef2.current.scrollHeight;
            const maxHeight = parseInt(window.getComputedStyle(textareaRef2.current).maxHeight);
            textareaRef2.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`; // Устанавливаем высоту в зависимости от содержимого и максимальной высоты
        }
    }, [commentInput]);

    const columns = [
        { key: 'id', title: "ИД" },
        { key: 'name', title: "Имя" },
        { key: 'status', title: "Статус" },
        { key: 'company', title: 'Компания' },
        { key: 'category', title: "Тип" },
        { key: 'priority', title: "Приоритет" },
        { key: 'deadline', title: "Крайний срок", date: true },
        { key: 'createdAt', title: "Дата создания", date: true },
    ];

    const detailsConfig = [
        { key: 'id', label: "ИД" },
        { key: 'name', label: "Имя" },
        { key: 'description', label: "Описание" },
        { key: 'status', label: "Статус" },
        { key: 'company', label: 'Компания' },
        { key: 'createbyuser', label: "Ответственный" },
        { key: 'category', label: "Тип" },
        { key: 'priority', label: "Приоритет" },
        { key: 'adres', label: "Адрес филиала" },
        { key: 'number', label: "Номер ПК" },
        { key: 'anyDesk', label: "Деск" },
        { key: 'deadline', label: "Крайний срок", date: true },
    ];


    useEffect(() => {
        ApiService.setToastHandlers(setToastMessage, setToastType);
        const fetchData = async () => {
            try {
                const user = await ApiService.fetchDataWithToken('/user/byToken', {})
                setItem(user);

            } catch (error) {
            }
        };
        fetchData();
        document.title = 'Личная страница';
    }, [])
    useEffect(() => {
        if (id) { setSelectedRow(id); openUpdate(id); setIsViewOpen(true); }
    }, [id]);

    const prevIsViewOpenRef = useRef(isViewOpen);

    useEffect(() => {
        if (prevIsViewOpenRef.current && !isViewOpen) {
            setSelectedRow(null);
            navigate('/user'); // Укажите нужный путь
        }
        // Сохранение текущего состояния для следующего рендера
        prevIsViewOpenRef.current = isViewOpen;
    }, [isViewOpen, navigate]);

    const setSelectRow = (id, deadline) => {
        setSelectedRow(id);
        let parsedDeadline = null;
        if (deadline) {
            parsedDeadline = new Date(deadline); // Преобразование строки даты в объект Date
        }
        setDeadline(parsedDeadline);
        openUpdate(id)
        navigate(`/user/${id}`);
        setIsViewOpen(true);
    };

    const openUpdate = async (selectedRow) => {
        try {
            const response = await ApiService.fetchDataWithToken('/application', { taskId: selectedRow });
            const users = await ApiService.fetchDataWithToken('/user/byProject', { projectId: response?.projectId || '' });
            setUserItems(users);
            setProjectIdInput(response?.projectId || '');
        } catch { }
    }

    const handleFinishOpen = async () => {
        try {
            const task = await ApiService.fetchDataWithToken('/application', { taskId: selectedRow });
            setCompletion_time(task.completion_time || 0)
            setIsFinishModalOpen(true)
        } catch (e) {

        }
    };

    const handleFinishSubmit = async () => {
        try {
            setLoading(true)
            const user = await ApiService.fetchDataWithToken('/user/byToken', {})
            const body = {
                id: selectedRow,
                comment: commentInput,
                completion_time: completion_time,
                userId: user.id
            }
            const response = await ApiService.postDataWithToken('/application/commentAndFinish', body)
            setToastType('success');
            setToastMessage(response.message);
            refreshTaskTable()
            setIsFinishModalOpen(false)
        } catch (e) {
        } finally {
            setLoading(false); // Скрыть спиннер после завершения
        }
    };
    const openUpdateDeadline = () => {
        setCommentInput('')
        setOpenModelDeadline(true)
        setNewDeadline(deadline);
    };

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        const dateObject = new Date(selectedDate);
        setNewDeadline(dateObject);
    };

    const formatDate = (date) => {
        if (!date) return '';

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    const handleUpdateDeadline = async () => {
        setLoading(true); // Показать спиннер
        try {
            const bodyDeadline = {
                id: selectedRow,
                deadline: newDeadline,
                comment: commentInput,
            };

            await ApiService.postDataWithToken('/application/setDeadline', bodyDeadline);
            setToastType('success');
            setToastMessage('Крайний срок изменен');
            setOpenModelDeadline(false);
            refreshTaskTable();
        } catch (e) {
            // Обработка ошибки
        } finally {
            setLoading(false); // Скрыть спиннер после завершения
        }
    };
    const [tipIdName, setTipName] = useState('');
    const [projectName, setProjectName] = useState('');
    const [tipIdInput, setTipIdInput] = useState('');
    const [userId, setUserId] = useState(null);

    const [updateEmployerModal, setUpdateEmployerModal] = useState(false)
    const openUpdateEmployer = async () => {
        const response = await ApiService.fetchDataWithToken('/application', { taskId: selectedRow });
        const project = response?.project || {};
        const category = response?.category || {};
        setProjectIdInput(response?.projectId || null);
        setUserId(response?.createByUserId);
        setTipIdInput(response?.categoryId || null);
        setProjectName(project.name || '');
        setTipName(category.name || '');
        setUpdateEmployerModal(true);
    }
    const handleUpdateEmployee = async () => {
        setLoading(true)
        const body = {
            taskId: selectedRow,
            projectId: projectIdInput,
            categoryId: tipIdInput,
            comment: commentInput,
            userId: userId,
            time: completion_time,
        }
        try {
            await ApiService.postDataWithToken('/application/updateEmpoyer', body);
            setToastType('success');
            setToastMessage('Заявка успешно изменена');
            refreshTaskTable()
            setUpdateEmployerModal(false)

        } catch (error) {
        } finally {
            setLoading(false)
        }
    }
    const handlePutOffOpen = async () => {
        setCommentInput('')
        setCompletion_time(0)
        setIsPutOffModalOpen(true)
    }

    const openENOTs = async () => {
        setCommentInput('')
        setEnotModalOpen(true)
    }

    const handleEnotSubmit = async () => {
        const body = {
            description: commentInput,
            taskId: selectedRow,
        }
        try {
            await ApiService.postDataWithToken('/enot/add', body);
            setToastType('success');
            setToastMessage('ЕНОТы запрошенны');
            refreshTaskTable()
            setEnotModalOpen(false)

        } catch (error) {
        }
    }

    const handlePutOffSubmit = async () => {
        const body = {
            id: selectedRow,
            comment: commentInput,
            categoryId: tipIdInput,
            completion_time: completion_time,
        }
        try {
            await ApiService.postDataWithToken('/application/putoff', body);
            setToastType('success');
            setToastMessage('Заявка успешно отложенна');
            refreshTaskTable()
            setIsPutOffModalOpen(false)

        } catch (error) {
        }
    }
    return (
        <>
            <UserComponent user={item} />
            <div className="grid-view-container">
                <TaskTable
                    apiUrl={'/application/tableUser'}
                    columns={columns}
                    selectedRow={selectedRow}
                    listener={listener}
                    setSelectedRow={setSelectRow}
                />
            </div>
            <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
            <Modal active={isViewOpen} setActive={setIsViewOpen}>
                <View
                    listener={listener}
                    apiUrl={"/application/table"}
                    selectedId={selectedRow}
                    detailsConfig={detailsConfig}
                    refreshTaskTable={refreshTaskTable}
                    pause={true}
                >
                    <button style={{ margin: '5px' }} onClick={() => { openUpdateDeadline() }}>Крайний срок</button>
                    <button style={{ margin: '5px' }} onClick={() => { handlePutOffOpen() }}>Отложить</button>
                    <button style={{ margin: '5px' }} onClick={() => { openUpdateEmployer() }}>Редактировать</button>
                    <button style={{ margin: '5px' }} onClick={() => { openENOTs() }}>Запросить ЕНОТов</button>
                    <button style={{ marginLeft: '10px' }} onClick={() => { setCheckListOpenModal(true) }}>ЧекЛист</button>
                    <button style={{ margin: '5px' }} onClick={() => { handleFinishOpen() }}>Завершить</button>
                    {selectedRow && projectIdInput && <Chat taskId={selectedRow} listener={listener} params={{ projectId: projectIdInput }}></Chat>}
                </View>
            </Modal>
            <Modal active={updateEmployerModal} setActive={setUpdateEmployerModal} loading={loading}>
                <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Редактор заявки {selectedRow}</p>

                <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Отдел</p>
                    <AutocompleteInput
                        apiUrl={`/depart`}
                        name='name'
                        placeholder="*Выбрать"
                        setId={setProjectIdInput}
                        setName={setProjectName}
                        chekValue={projectIdInput}
                        validation={true}
                    />
                </div>
                {projectIdInput && <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Тэг/Тип</p>
                    <AutocompleteInput
                        key={projectIdInput}
                        apiUrl={`/tip`}
                        params={{ projectId: projectIdInput }}
                        name='name'
                        placeholder="*Выбрать"
                        setId={setTipIdInput}
                        setName={setTipName}
                        chekValue={tipIdInput}
                        validation={true}
                    />
                </div>}
                {projectIdInput && <div style={{ marginBottom: "10px" }}>
                    <p style={{ fontWeight: 'bold' }}>Ответственный</p>
                    <AutocompleteInput
                        key={projectIdInput}
                        apiUrl={`/user/byProject`}
                        params={{ projectId: projectIdInput }}
                        name='firstName'
                        placeholder="*Выбрать"
                        setId={setUserId}
                        chekValue={userId}
                        validation={true}
                    />
                </div>}
                <div>
                    <p style={{ fontWeight: 'bold' }}>Коментарий</p>
                    <textarea
                        ref={textareaRef2}
                        type="text"
                        value={commentInput}
                        onChange={(e) => setCommentInput(e.target.value)}
                        placeholder="Текст"
                        className="input-auto-height"
                    />
                </div>
                <p style={{ fontWeight: 'bold' }}>Введите время выполнения заявки в минутах, если вы не работали над заявкой, поставьте 0</p>
                <input
                    style={{ marginTop: "10px" }}
                    type="number"
                    min='0'
                    placeholder="0"
                    value={completion_time}
                    onChange={(e) => setCompletion_time(e.target.value)}
                />
                <div style={{ marginTop: "10px" }}>
                    <button onClick={handleUpdateEmployee}>Отправить</button>
                </div>
            </Modal>
            <Modal active={openModelDeadline} setActive={setOpenModelDeadline} loading={loading}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Изменить крайний срок {selectedRow}</p>
                <p style={{ fontWeight: 'bold' }}>Введите комментарий</p>
                <div>
                    <textarea
                        ref={textareaRef1}
                        type="text"
                        value={commentInput}
                        onChange={(e) => setCommentInput(e.target.value)}
                        placeholder="Введите комментарий"
                        className="input-auto-height"
                    />
                </div>
                <p style={{ fontWeight: 'bold' }}>Дата</p>
                <input
                    style={{ marginBottom: '10px' }}
                    type='datetime-local'
                    name='newDeadline'
                    value={newDeadline ? formatDate(newDeadline) : ''}
                    onChange={handleDateChange}
                />
                <button onClick={handleUpdateDeadline}>Отправить</button>
            </Modal>
            <Modal active={isPutOffModalOpen} setActive={setIsPutOffModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Отложить заявку {selectedRow}</p>
                <div style={{
                    margin: "20px"
                }}>
                    <p>Вы уверены, что хотите отложить заявку?</p>
                    <div>
                        <textarea
                            ref={textareaRef2}
                            type="text"
                            value={commentInput}
                            onChange={(e) => setCommentInput(e.target.value)}
                            placeholder="Введите комментарий"
                            className="input-auto-height"
                        />
                    </div>
                    <input
                        style={{ marginTop: "10px" }}
                        type="number"
                        min='0'
                        placeholder="Введите время выполнения заявки в минутах"
                        value={completion_time}
                        onChange={(e) => setCompletion_time(e.target.value)}
                    />
                    <div style={{
                        marginTop: "10px"
                    }}>
                        <button onClick={handlePutOffSubmit}>Отложить</button>
                        <button style={{ marginLeft: '10px' }} onClick={() => { setIsPutOffModalOpen(false) }}>Отмена</button>
                    </div>
                </div>
            </Modal>
            <Modal active={enotModalOpen} setActive={setEnotModalOpen}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Запросить ЕНОТов во заявке {selectedRow}</p>
                <p style={{ fontWeight: 'bold' }}>Введите комментарий</p>
                <div>
                    <textarea
                        ref={textareaRef2}
                        type="text"
                        value={commentInput}
                        onChange={(e) => setCommentInput(e.target.value)}
                        placeholder="Текст"
                        maxLength={2000}
                        className="input-auto-height"
                    />
                </div>
                <div style={{
                    marginTop: "10px"
                }}>
                    <button onClick={handleEnotSubmit}>Запросить</button>
                    <button style={{ marginLeft: '10px' }} onClick={() => { setIsFinishModalOpen(false) }}>Отмена</button>
                </div>
            </Modal>
            <Modal active={isFinishModalOpen} setActive={setIsFinishModalOpen} loading={loading}>
                <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '1em' }}>Завершить заявку {selectedRow}</p>
                <p style={{ fontWeight: 'bold' }}>Введите комментарий</p>
                <div>
                    <textarea
                        ref={textareaRef2}
                        type="text"
                        value={commentInput}
                        onChange={(e) => setCommentInput(e.target.value)}
                        placeholder="Введите комментарий"
                        className="input-auto-height"
                    />
                </div>
                <p style={{ fontWeight: 'bold' }}>Введите время выполнения заявки в минутах</p>
                <input
                    style={{ marginBottom: '10px' }}
                    type="number"
                    min='0'
                    placeholder="15"
                    value={completion_time}
                    onChange={(e) => setCompletion_time(e.target.value)}
                />
                <button onClick={handleFinishSubmit}>Завершить</button>
            </Modal>
            <SideModal active={checkListOpenModal} setActive={setCheckListOpenModal}>
                <CheckList apiUrl={'/cheklist'} taskId={selectedRow} />
            </SideModal>
        </>
    );
};

export default User;