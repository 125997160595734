import React, { useEffect, useMemo, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import YearMonthSelector from '../YearMonthSelector';
import AutocompleteInput from '../autoCompliteInputs/FithoutCreate';
import ApiService from '../ApiService';

const AveragrTimeTaskCategory = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const [tipIdInput, setTipIdInput] = useState(null);
    const [tipIdName, setTipName] = useState('');
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [data, setData] = useState(null);

    const MonthStart = useMemo(() => {
        const monthStart = new Date(selectedYear, selectedMonth, 1);
        monthStart.setHours(0, 0, 0, 0);
        return monthStart;
    }, [selectedYear, selectedMonth]);

    const MonthEnd = useMemo(() => {
        const monthEnd = new Date(selectedYear, selectedMonth + 1, 0);
        monthEnd.setHours(23, 59, 59, 999);
        return monthEnd;
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.postDataWithToken('/kpi/averageTimeCategory', { startDate: MonthStart, endDate: MonthEnd, categoryId: tipIdInput });
                setData(response);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [MonthStart, MonthEnd, tipIdInput]);

    const extractMinutes = (timeString) => {
        if (timeString) {
            const match = timeString.match(/(\d+\.?\d*)\s*минут/);
            return match ? parseFloat(match[1]) : 0; // Извлекаем минуты из второго элемента массива match
        }
        return 0; // Вернуть 0, если timeString пустой
    };

    const pieData = [
        { value: parseFloat(data?.statistics.under30Minutes) || 0, name: `Менее 30:${String.fromCharCode(160).repeat(5)}${extractMinutes(data?.statistics.under30Minutes)} минут`, style: { fontFamily: 'Roboto Mono, monospace' } },
        { value: parseFloat(data?.statistics.over30Minutes) || 0, name: `От 30 до 90:${String.fromCharCode(160).repeat(2)}${extractMinutes(data?.statistics.over30Minutes)} минут`, style: { fontFamily: 'Roboto Mono, monospace' } },
        { value: parseFloat(data?.statistics.over90Minutes) || 0, name: `От 90 до 120:${String.fromCharCode(160).repeat(1)}${extractMinutes(data?.statistics.over90Minutes)} минут`, style: { fontFamily: 'Roboto Mono, monospace' } },
        { value: parseFloat(data?.statistics.over120Minutes) || 0, name: `Более 120:${String.fromCharCode(160).repeat(4)}${extractMinutes(data?.statistics.over120Minutes)} минут`, style: { fontFamily: 'Roboto Mono, monospace' } },
    ];



    const COLORS = ['#4CAF50', '#FF9800', '#2196F3', '#F44336'];

    const totalValues = pieData.reduce((acc, entry) => acc + entry.value, 0);

    return (
        <div className="average-time-task">
            <div className="selector-container" style={{ display: "flex" }}>
                <YearMonthSelector
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    onYearChange={setSelectedYear}
                    onMonthChange={setSelectedMonth}
                />
                <div style={{ marginLeft: '10px' }}>
                    <AutocompleteInput
                        apiUrl={`/tip/all`}
                        params={{ page: 1, itemsPerPage: 5000 }}
                        name='name'
                        placeholder="Тип заявки"
                        setId={setTipIdInput}
                        setName={setTipName}
                        chekValue={tipIdInput}
                        validation={true}
                    />
                </div>
            </div>
            {data && (
                <div className="statistics-card">
                    <p className="stats-row">
                        <strong>Общее количество заявок:</strong> {data.totalTasks}
                        <span className="align-right"><strong>Среднее время заявки:</strong> {data.averageTime}</span>
                    </p>
                    {totalValues > 0 ? (
                        <ResponsiveContainer width="100%" height={200}>
                            <PieChart>
                                <Pie
                                    data={pieData}
                                    dataKey="value" // Используйте dataKey вместо valueKey
                                    cx="40%"
                                    cy="50%"
                                    outerRadius={60}
                                    label
                                    animationDuration={100}
                                    animationEasing="ease-in-out"
                                    isAnimationActive={true}
                                >
                                    {pieData.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                            stroke="#fff"
                                            strokeWidth={1}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value, minutes, name) => [`${minutes} (${value}%)`]} />
                                <Legend
                                    layout="vertical"
                                    align="right"
                                    verticalAlign="middle"
                                    formatter={(value) => <span style={{ fontFamily: 'Roboto Mono, monospace' }}>{value}</span>}
                                />
                            </PieChart>
                        </ResponsiveContainer>

                    ) : (
                        <div className="empty-state">
                            <p>Нет данных для отображения.</p>
                        </div>
                    )}

                </div>
            )}
        </div>
    );
};

export default AveragrTimeTaskCategory;
