import React, { useEffect, useMemo, useState } from 'react';
import ApiService from '../ApiService';
import YearMonthSelector from '../YearMonthSelector';

const CompanyCategory = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [data, setData] = useState([]);

    const MonthStart = useMemo(() => {
        const monthStart = new Date(selectedYear, selectedMonth, 1);
        monthStart.setHours(0, 0, 0, 0);
        return monthStart;
    }, [selectedYear, selectedMonth]);

    const MonthEnd = useMemo(() => {
        const monthEnd = new Date(selectedYear, selectedMonth + 1, 0);
        monthEnd.setHours(23, 59, 59, 999);
        return monthEnd;
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.fetchDataWithToken('/kpi/companyCategoryStat', { startDate: MonthStart, endDate: MonthEnd });
                setData(response.companies); // Предполагая, что данные приходят в этом формате
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [MonthStart, MonthEnd]);

    const calculateDaysRemaining = (endDate) => {
        const today = new Date();
        const end = new Date(endDate);
        const timeDiff = end.getTime() - today.getTime();
        return Math.ceil(timeDiff / (1000 * 3600 * 24)); // Преобразование в дни
    };

    return (
        <div className="average-time-task" >
            <div className="selector-container">
                <YearMonthSelector
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    onYearChange={setSelectedYear}
                    onMonthChange={setSelectedMonth}
                />
            </div>
            <h2>Кол-во заявок клиента</h2>
            <div className="table-wrapper" >
                {data.length > 0 ? (
                    <table className="table" style={{ boxShadow: '0 0px 8px rgba(0, 0, 0, 0.3)', borderRadius: '8px' }}>
                        <thead>
                            <tr style={{ borderRadius: '8px' }}>
                                <th>Название Компании</th>
                                <th>Кол-во заявок</th>
                                <th>Стоимость</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(item => (
                                <tr key={item.id}>
                                    <td>{item.companyname}</td>
                                    <td>{item.totaltasks}</td>
                                    <td>{item.TotalTimeHours}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Нет данных для отображения.</p>
                )}
            </div>
        </div>
    );
};

export default CompanyCategory;
