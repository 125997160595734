import React, { useState } from 'react';

const CustomSelect = ({ options, onChange, placeholder, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || '');

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    onChange(value);
  };

  return (
    <div className="select-wrapper">
      <div className="custom-select-container" onClick={() => setIsOpen(!isOpen)}>
        {selectedValue ? options.find(option => option.value === selectedValue)?.label : placeholder}
        <div className={`custom-select-menu ${isOpen ? 'open' : ''}`}>
          {options.map(option => (
            <div
              key={option.value}
              className="custom-option"
              onClick={() => handleSelectChange(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
      <select onChange={(e) => handleSelectChange(e.target.value)} value={selectedValue || ''} style={{display: 'none'}}>
        <option value="" disabled>{placeholder}</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelect;
