import React, { useEffect, useState, useRef, useCallback } from 'react';
import ApiService from '../ApiService';

const AutocompleteInput = ({ apiUrl, name, placeholder, setId, setName, chekValue, validation, id, params }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [error, setError] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const optionsRef = useRef([]);

  const fetchData = async (queryParams = {}) => {
    try {
      const data = await ApiService.fetchDataWithToken(apiUrl, { ...params, ...queryParams });
      const newOptions = data.items ?? data ?? [];
      setOptions(newOptions);
      optionsRef.current = newOptions;
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    const fetchAndSetOption = async () => {
      await fetchData();
      if (chekValue === '' || chekValue === null) {
        setInputValue('');
        setSelectedOption(null);
      } else {
        const foundOption = optionsRef.current.find(option => parseInt(option.id) === parseInt(chekValue) || option[name] === chekValue);
        if (foundOption) {
          setSelectedOption(foundOption[name]);
          setInputValue(foundOption[name]);
        } else {
          setSelectedOption(null);
          setInputValue('');
        }
      }
    };
    fetchAndSetOption();
  }, [chekValue, id]);

  const handleInputChange = useCallback((e) => {
    const value = e.target.value;
    setInputValue(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setId(null)
    const newTimeout = setTimeout(async () => {
      await fetchData({ search: value, column: name });
      const filtered = optionsRef.current.filter((option) =>
        typeof option[name] === 'string' ? option[name].toLowerCase().includes(value.toLowerCase()) : option[name]
      );
      setFilteredOptions(filtered);

    }, 300); // задержка 300 мс

    setDebounceTimeout(newTimeout);
  }, [name, debounceTimeout]);

  const handleOptionClick = (option) => {
    setSelectedOption(option[name]);
    setId(option.id);
    if (setName) {
      setName(option[name]);
    }
    setInputValue(option[name]);
    setFilteredOptions([]);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
    setFilteredOptions(options);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      if (!document.querySelector('.options-list:hover')) {
        setIsInputFocused(false);
        setFilteredOptions([]);
      }
    }, 200);
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => handleInputChange(e)}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder={placeholder}
        style={{
          borderColor: validation !== false && selectedOption === null ? 'red' : '',
          boxShadow: isInputFocused && validation !== false && selectedOption === null ? '0 0 5px red' : '',
        }}
      />
      <div style={{ position: 'relative' }}>
        <ul className={`options-list ${filteredOptions.length > 0 ? 'has-border' : ''}`}>
          {filteredOptions.map((option, index) => (
            <li key={index} className="options-list-item" onClick={() => handleOptionClick(option)}>
              {option[name]}
            </li>
          ))}
        </ul>
      </div>
      <div style={{ position: 'relative' }}>
        <p
          style={{
            color: 'rgb(211, 0, 0)',
            fontSize: '12px',
            height: '15px',
            margin: '0px',
            visibility: (selectedOption !== null || inputValue !== '') || validation === false ? 'hidden' : 'visible',
          }}
        >
          Обязательное поле
        </p>
      </div>
      {error && <p style={{ color: 'red' }}>Ошибка при загрузке данных</p>}
    </div>
  );
};

export default AutocompleteInput;
