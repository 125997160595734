import React, { useState, useEffect } from 'react';

const ForBookeeping = ({ item, columns, setSelectedRow, handleOpenView }) => {
    const [items, setItems] = useState([]);
    const [activeSearchColumn, setActiveSearchColumn] = useState(null);
    const [columnSums, setColumnSums] = useState({});

    useEffect(() => {
        setItems(item)
    }, [item]);

    // Calculate column sums
    useEffect(() => {
        const sums = {};
        columns.forEach(column => {
            if (column.type === 'number') {
                const sum = items.reduce((total, item) => total + (parseFloat(item[column.key]) || 0), 0);
                sums[column.key] = sum;
            }
        });
        setColumnSums(sums);
    }, [items, columns]);

    const handleRowClick = (id) => {
        setSelectedRow(id);
        handleOpenView(id);
    };

    const handleColumnHeaderClick = (columnKey) => {
        setActiveSearchColumn(columnKey === activeSearchColumn ? null : columnKey);
    };

    const formatDateTime = (value) => {
        const date = new Date(value);
        if (date.getTime() === 0) {
            return 'не указано';
        } else {
            const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        }
    };

    return (
        <div className='grid-container'>
            <div className="table-wrapper">
                <table className='table'>
                    <thead>
                        <tr>
                            {columns.map(column => (
                                <th key={column.key}
                                    className={`${activeSearchColumn === column.key ? 'selected-column' : ''}`}
                                    onClick={() => handleColumnHeaderClick(column.key)}>
                                    {column.title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => (
                            <tr key={item.id} onClick={() => handleRowClick(item.id)}>
                                {columns.map(column => (
                                    <td key={`${item.id}-${column.key}`}>
                                        {column.date ? formatDateTime(item[column.key]) : item[column.key] || 'Не указано'}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr>
                            {columns.map(column => (
                                <td key={`total-${column.key}`}>
                                    {column.type === 'number' ? columnSums[column.key] : ''}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ForBookeeping;
